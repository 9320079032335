import { render, staticRenderFns } from "./GoodsTest.vue?vue&type=template&id=f9b91baa&scoped=true&"
import script from "./GoodsTest.vue?vue&type=script&lang=js&"
export * from "./GoodsTest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b91baa",
  null
  
)

export default component.exports
<template>
    <div>
        <div v-if="this.wldata.length > 0">
            <van-steps direction="vertical" :active="0">
                <van-step>
                    <h3>快件已发货</h3>
                    <p>物流公司：{{this.wlcompany}}</p>
                    <p>物流单号：{{this.wlorder}}</p>
                    <p>发货时间：{{this.sendtime}}</p>
                </van-step>
            </van-steps>
        </div>

        <div v-else>
            <van-steps direction="vertical" :active="0">
            <van-step>
                <h3>正在准备发货...</h3>
            </van-step>
            </van-steps>
        </div>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { Step, Steps } from 'vant';

    Vue.use(Step);
    Vue.use(Steps);
    var that
    export default {
        name: "Logistics",
        data () {
            return {
                wldata: '',
                wlorder: '',
                sendtime: '',
                wlcompany: ''
            }
        },
        mounted() {
            that = this
            var qstr = "orderno ='" + localStorage.getItem('wlorder') + "'"
            that.$http({
                url: 'https://api.sqnetsoft.cn/order/getbol',
                method: 'post',
                data: {
                    whereinfo: {String: qstr, Valid: true}
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        window.console.log(JSON.parse(res.data.data))
                        if (res.data.data === '[]') {
                            that.wldata = []
                        } else {
                            var tmpdata = JSON.parse(res.data.data)
                            that.wldata = JSON.parse(res.data.data)
                            that.wlorder = tmpdata[0].logisticsno.String
                            that.sendtime = tmpdata[0].sendtime.String
                            that.wlcompany = tmpdata[0].threename.String
                        }
                    }
                })
                .catch(function (error) {
                    window.console.log('error' + error)
                })
        },
        methods: {}
    }
</script>

<style scoped>

</style>
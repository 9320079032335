/**
 * Created by yangxiaoshuo on 2019/10/15.
 */
import Vue from 'vue'
import Router from 'vue-router'
import MainPage from './components/MainPage.vue'
import Information from './components/Information.vue'
import Mana from './components/Mana.vue'
import My from './components/my/My.vue'
import CardMana from './components/my/CardMana.vue'
import TCardMana from './components/my/TCardMana.vue'
import TCardInfo from './components/my/TCardInfo.vue'
import Type from './components/Type.vue'
import CourseList from './components/CourseList.vue'
import CourseInfo from './components/CourseInfo.vue'
import SharePage from './components/SharePage.vue'
import Example from './components/example/Example.vue'
import ServiceMana from './components/servicemana/ServiceList.vue'
import ContactUs from './components/ContactUs.vue'
import MPList from './components/minprogram/MPList.vue'
import BCList from './components/blockchain/BCList.vue'
import BDList from './components/bigdata/BDList.vue'
import AIList from './components/ai/AIList.vue'
import GoodsShow from './components/goods/GoodsShow.vue'
import RegOneStep from './components/reg/RegOneStep.vue'
import RegTwoStep from './components/reg/RegTwoStep.vue'
import GetInfo from './components/lawyer/GetInfo.vue'
import MakeCode from './components/example/MakeCode.vue'
import GPS from './components/example/GPS.vue'
import FileUpload from './components/example/FileUpload.vue'
import FileDownload from './components/example/FileDownload.vue'
import Pay from './components/example/Pay.vue'
import Refund from './components/example/Refund.vue'
import Share from './components/example/Share.vue'
import VoiceRead from './components/ai/VoiceRead.vue'
import TransTxt from './components/ai/TransTxt.vue'
import VoiceCompose from './components/ai/VoiceCompose.vue'
import SFZ from './components/ai/SFZ.vue'
import YHK from './components/ai/YHK.vue'
import XSZ from './components/ai/XSZ.vue'
import JSZ from './components/ai/JSZ.vue'
import YYZZ from './components/ai/YYZZ.vue'
import Comm from './components/ai/Comm.vue'
import LocalStore from './components/workspace/LocalStore.vue'
import WorkSpace from './components/workspace/WorkSpace.vue'
import ScanInput from './components/workspace/ScanInput.vue'
import ImportExcel from './components/workspace/ImportExcel.vue'
import GoodsType from './components/goods/GoodsType.vue'
import RegMember from './components/reg/RegMember.vue'
import GoodsInfo from './components/goods/GoodsInfo.vue'
import GoodsTest from './components/goods/GoodsTest.vue'
import GoodsQuery from './components/goods/GoodsQuery.vue'
import AllGoods from './components/goods/AllGoods.vue'
import ZTGoods from './components/goods/ZTGoods.vue'
import DList from './components/deliver/DList.vue'
import NAddress from './components/deliver/NAddress.vue'
import EAddress from './components/deliver/EAddress.vue'
import CartJS from './components/pay/CartJS.vue'
import AddTicket from './components/ticket/AddTicket.vue'
import TicketList from './components/ticket/TicketList.vue'
import CartInfo from './components/pay/CartInfo.vue'
import DFK from './components/orders/DFK.vue'
import DPJ from './components/orders/DPJ.vue'
import DQR from './components/orders/DQR.vue'
import YWC from './components/orders/YWC.vue'
import Logistics from './components/pay/Logistics'
import GComment from './components/comment/GComment'
import TxClient from './components/example/TxClient'
import TxServer from './components/example/TxServer'
import ChatGPT from './components/ai/ChatGPT'
import ChatGPTCode from './components/ai/ChatGPTCode'
import ChatGPTImg from './components/ai/ChatGPTImg'
import DataTj from './components/tj/DataTj'
import Data from './components/hxx/Data'
import AdminData from './components/hxx/AdminData'
import FtIndex from './components/ftpro/Index'
Vue.use(Router)

export default new Router ({
    routes: [
        {path:'/',name:'MainPage',meta:{title: '硕祺网络科技',footshow: true},component:MainPage},
        {path:'/TxClient',name:'TxClient',meta:{title: '客户端'},component:TxClient},
        {path:'/TxServer',name:'TxServer',meta:{title: '服务端'},component:TxServer},
        {path:'/FtIndex',name:'FtIndex',meta:{title: '数据呈现'},component:FtIndex},
        {path:'/Data',name:'Data',meta:{title: '数据统计'},component:Data},
        {path:'/AdminData',name:'AdminData',meta:{title: '数据统计'},component:AdminData},
        {path:'/DFK',name:'DFK',meta:{title: '待付款'},component:DFK},
        {path:'/DPJ',name:'DPJ',meta:{title: '待评价'},component:DPJ},
        {path:'/DQR',name:'DQR',meta:{title: '待确认'},component:DQR},
        {path:'/YWC',name:'YWC',meta:{title: '已完成'},component:YWC},
        {path:'/ChatGPTCode',name:'ChatGPTCode',meta:{title: 'ChatGPTCode'},component:ChatGPTCode},
        {path:'/ChatGPTImg',name:'ChatGPTImg',meta:{title: 'ChatGPTImg'},component:ChatGPTImg},
        {path:'/ChatGPT',name:'ChatGPT',meta:{title: 'ChatGPT'},component:ChatGPT},
        {path:'/GComment',name:'GComment',meta:{title: '立即评价'},component:GComment},
        {path:'/Logistics',name:'Logistics',meta:{title: '物流信息'},component:Logistics},
        {path:'/Pay',name:'Pay',meta:{title: '产品支付'},component:Pay},
        {path:'/LocalStore',name:'LocalStore',meta:{title: '立体库位'},component:LocalStore},
        {path:'/SFZ',name:'SFZ',meta:{title: '身份证识别'},component:SFZ},
        {path:'/YHK',name:'YHK',meta:{title: '银行卡识别'},component:YHK},
        {path:'/XSZ',name:'XSZ',meta:{title: '行驶证识别'},component:XSZ},
        {path:'/JSZ',name:'JSZ',meta:{title: '驾驶证识别'},component:JSZ},
        {path:'/YYZZ',name:'YYZZ',meta:{title: '营业执照识别'},component:YYZZ},
        {path:'/Comm',name:'Comm',meta:{title: '通用识别'},component:Comm},
        {path:'/ServiceMana',name:'ServiceMana',component:ServiceMana},
        {path:'/VoiceRead',name:'VoiceRead',meta:{title: '语音识别'},component:VoiceRead},
        {path:'/TransTxt',name:'TransTxt',meta:{title: '文字翻译'},component:TransTxt},
        {path:'/VoiceCompose',name:'VoiceCompose',meta:{title: '语音合成'},component:VoiceCompose},
        {path:'/Refund',name:'Refund',meta:{title: '退款'},component:Refund},
        {path:'/Share',name:'Share',meta:{title: '分享'},component:Share},
        {path:'/Information',name:'Information',meta:{title: '行业资讯'},component:Information},
        {path:'/Mana',name:'Mana',component:Mana},
        {path:'/GoodsShow',meta:{footshow: false},name:'GoodsShow',component:GoodsShow},
        {path:'/My',name:'My',meta:{title: '我的',footshow: true},component:My},
        {path:'/CardMana',name:'CardMana',component:CardMana},
        {path:'/TCardMana',name:'TCardMana',component:TCardMana},
        {path:'/TCardInfo',name:'TCardInfo',component:TCardInfo},
        {path:'/Type',name:'Type',component:Type},
        {path:'/GoodsQuery',name:'GoodsQuery',component:GoodsQuery},
        {path:'/GoodsInfo',name:'GoodsInfo',component:GoodsInfo},
        {path:'/GoodsType',name:'GoodsType',meta:{title: '产品分类',footshow: true},component:GoodsType},
        {path:'/AllGoods',name:'AllGoods',component:AllGoods},
        {path:'/ZTGoods',name:'ZTGoods',component:ZTGoods},
        {path:'/CourseInfo',name:'CourseInfo',component:CourseInfo},
        {path:'/CourseList',name:'CourseList',component:CourseList},
        {path:'/SharePage',name:'SharePage',component:SharePage},
        {path:'/RegMember',name:'RegMember',component:RegMember},
        {path:'/RegOneStep',name:'RegOneStep',component:RegOneStep},
        {path:'/RegTwoStep',name:'RegTwoStep',component:RegTwoStep},
        {path:'/AddTicket',name:'AddTicket',component:AddTicket},
        {path:'/TicketList',name:'TicketList',component:TicketList},
        {path:'/CartInfo',name:'CartInfo',meta:{title: '购物车',footshow: true},component:CartInfo},
        {path:'/GoodsTest',name:'GoodsTest',component:GoodsTest},
        {path:'/CartJS',name:'CartJS',component:CartJS},
        {path:'/DList',name:'DList',component:DList},
        {path:'/NAddress',name:'NAddress',component:NAddress},
        {path:'/EAddress',name:'EAddress',component:EAddress},
        {path:'/Example',name:'Example',meta:{title: '功能展示'},component:Example},
        {path:'/DataTj',name:'DataTj',meta:{title: '数据统计'},component:DataTj},
        {path:'/GetInfo',name:'GetInfo',component:GetInfo},
        {path:'/MPList',name:'MPList',meta:{title: '小程序案例'},component:MPList},
        {path:'/BDList',name:'BDList',meta:{title: '大数据/BI案例'},component:BDList},
        {path:'/BCList',name:'BCList',meta:{title: '区块链示例'},component:BCList},
        {path:'/AIList',name:'AIList',meta:{title: '人工智能示例'},component:AIList},
        {path:'/MakeCode',name:'MakeCode',meta:{title: '生成二维码'},component:MakeCode},
        {path:'/GPS',name:'GPS',meta:{title: 'GPS定位'},component:GPS},
        {path:'/FileUpload',name:'FileUpload',meta:{title: '文件上传'},component:FileUpload},
        {path:'/FileDownload',name:'FileDownload',meta:{title: '文件缓存'},component:FileDownload},
        {path:'/ContactUs',name:'ContactUs',meta:{title: '联系我们'},component:ContactUs},
        {path:'/WorkSpace',name:'WorkSpace',meta:{title: '仓配管理'},component:WorkSpace},
        {path:'/ScanInput',name:'ScanInput',meta:{title: '扫码入库'},component:ScanInput},
        {path:'/ImportExcel',name:'ImportExcel',meta:{title: '数据处理'},component:ImportExcel}
    ]
})

<template>
    <div style="padding: 10px;">
        <div style="height: 140px;">
             <van-swipe :autoplay="3000">
                 <van-swipe-item  v-for="(image, index) in branddata" :key="index" style="display: flex;align-items: center;justify-content: center;">
                     <van-image style="height:140px;" radius="5" fit="full" lazy-load :src="image.adurl" />
                 </van-swipe-item>
             </van-swipe>
        </div>
        <div style=" border-radius: 6px;margin: 10px 0 10px 0;padding: 10px; background-color: #fff;">
            <van-grid column-num="4" :border="false">
                <van-grid-item
                        v-for="(kjrk) in kjrkdata"
                        :key="kjrk.id"
                        :icon="kjrk.scurl"
                        @click="showkjrk(kjrk.scname,kjrk.link)"
                        :text="kjrk.scname"
                />
            </van-grid>
        </div>

        <div style="margin: 0 -6px 50px -6px;">
            <van-grid column-num="2" gutter="6">
                <van-grid-item
                        style="border-radius: 5px;"
                        v-for="(tjgoods) in tjgoodsdata"
                        :key="tjgoods.id"
                        :text="tjgoods.name"
                        @touchstart="showgoods(tjgoods.goodsid)" @click="showgoods(tjgoods.goodsid)"
                >
                    <div style="display: flex; border-bottom: 1px solid #f1f1f1; justify-content: center;align-items: center;border-radius: 6px 6px 0 0; width: 100%; padding: 0px; margin: 0px;">
                        <van-image radius="10" style="width:100%;height: 128px;" fit="cover" :src="tjgoods.img_url" />
                    </div>
                    <div style="width:100%; font-size:12px; margin:6px;height: 65px;flex-direction: column; display: flex; ">
                        <div style="margin: 0 10px 0 10px; justify-content: flex-start;align-items: flex-start;display: flex;">
                            {{tjgoods.name}}
                        </div>
                        <div style="margin: 5px 10px 0 10px;">
                            <span>价格：</span>
                            <span style="font-size:14px;color: #b10000;">{{tjgoods.baseprice}}</span>
                        </div>
                    </div>
                </van-grid-item>
            </van-grid>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import {Decrypt} from '../../static/js/cryptoinfo.js'
    import axios from 'axios'
    import { Notify } from 'vant';
    import { Lazyload } from 'vant'
    import { Swipe, SwipeItem } from 'vant'
    import { DropdownMenu, DropdownItem } from 'vant';
    import { Grid, GridItem } from 'vant';
    /*
    import Vconsole from 'vconsole'
    let vConsole = new Vconsole()
    Vue.use(vConsole)
     */
    Vue.use(Notify);
    Vue.use(Grid);
    Vue.use(GridItem);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Swipe).use(SwipeItem)
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'MainPage',
        data () {
            return {
                active: 0,
                branddata: [],
                tjgoodsdata: [],
                kjrkdata: [],
                recommtypes: [],
                ztdata: [],
                goodsrec: [],
                option1: [
                    { text: '全部商品', value: 0 },
                    { text: '新款商品', value: 1 },
                    { text: '活动商品', value: 2 }
                ],
                option2: [
                    { text: '默认排序', value: 'a' },
                    { text: '好评排序', value: 'b' },
                    { text: '销量排序', value: 'c' },
                ],
                allscreenWidth: (document.body.clientWidth / 5) - 5,
                screenWidth: (document.body.clientWidth / 2)
            }
        },
        mounted: function () {
            that = this
            // 获取OpenID

            if (this.$route.query.uphone !== undefined && this.$route.query.uphone !=='' && this.$route.query.uphone !== null) {
                var uphone = Decrypt(this.$route.query.uphone)
                localStorage.setItem('username',uphone)
            } else {
                window.console.log('存在ID')
                localStorage.setItem('username','13911419225')
            }
            document.title = '祺聚优品'
            that.allscreenWidth = (document.body.clientWidth / 5) - 5
            that.screenWidth = (document.body.clientWidth / 2) - 2
            window.onresize = () => {
                return (() =>{
                    that.allscreenWidth = (document.body.clientWidth / 5) - 5
                    that.screenWidth = (document.body.clientWidth / 2) - 1
                })()
            }
            const URL = window.location.href.replace('#/','')
            var params = parse_url(URL)
            if(localStorage.getItem('openid') !== '' && localStorage.getItem('nickname') !== '' && localStorage.getItem('openid') !== undefined && localStorage.getItem('openid') !== null) {
                window.console.log('openid 存在')
            } else {
                if (params['code'] !== '' && params['code'] !== undefined) {
                    var qdata = {
                        code: params['code']
                    }
                    that.$http({
                        url: 'https://wx.sqnetsoft.cn/wx/getopenid',
                        method: 'post',
                        data: qdata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.data.state === 'success') {
                                localStorage.setItem('openid',res.data.openid)
                                localStorage.setItem('nickname',res.data.nickname)
                                localStorage.setItem('headimgurl',res.data.headimgurl)
                                localStorage.setItem('sex',res.data.nickname)
                                that.checkuser()
                            } else {
                                Notify({ type: 'danger', message: '授权失败' });
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    const URL1 = window.location.href.replace('#/','')
                    var params1 = encodeURIComponent(URL1)
                    window.console.log(params1)
                    localStorage.setItem('authway',1)
                    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2c25ac6febc290d2&redirect_uri=' + params1 + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
                    /*
                    window.console.log(localStorage.getItem('authway'))
                    if (localStorage.getItem('authway') !== undefined && localStorage.getItem('authway') !== null && localStorage.getItem('authway') !== '') {
                        window.console.log('已发起授权')
                    } else {
                    }
                     */
                }
            }
            // 轮播图
            that.querybrand()
            // 快捷入口
            that.querykjrk()
            // 商品推荐
            that.querygtj()
            // 检测版本号
            if (localStorage.getItem('version') !== null && localStorage.getItem('version') !== undefined && localStorage.getItem('version') !== '') {
                window.console.log('版本号检测')
                var version = localStorage.getItem('version')
                that.checkversion(version)
            } else {
                that.checkdefaultversion()
            }
        },
        methods: {
            checkdefaultversion () {
                that = this
                var qstr = {
                    whereinfo: " version > 0",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryversion',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpversion = res.data[0].version
                            localStorage.setItem('version',tmpversion)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            checkversion (version) {
                that = this
                var qstr = {
                    whereinfo: " version > 0",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryversion',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpversion = res.data[0].version
                            if (parseInt(version) !== parseInt(tmpversion)) {
                                window.console.log('强制刷新')
                                window.location.reload();
                                localStorage.setItem('version',tmpversion)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querykjrk () {
                that = this
                var qstr = {
                    whereinfo: " ownphone ='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querykjrk',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.kjrkdata = res.data
                        } else {
                            that.kjrkdata = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            checkuser () {
                var qstr = {
                    whereinfo: " openid='" + localStorage.getItem('openid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querywxgzhuser',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0 && res.data[0].nickname !== '') {
                            var tmpdata = res.data[0]
                            that.userimg = tmpdata.imgurl
                            that.usernick = tmpdata.nickname
                            if (tmpdata.phone !== null && tmpdata.phone !== '') {
                                localStorage.setItem('userphone',tmpdata.phone)
                            }
                        } else {
                            that.addnewuser()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            addnewuser () {
                var adata = {
                    nickname: localStorage.getItem('nickname'),
                    userphone: '',
                    country: '',
                    province: '',
                    city: '',
                    userimg: localStorage.getItem('headimgurl'),
                    openid: localStorage.getItem('openid')
                }
                window.console.log(adata)
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/addwxgzhuser',
                    method: 'post',
                    data: adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result !== 'success') {
                            Notify({ type: 'danger', message: '授权失败' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            querybrand () {
                that = this
                var qstr = {
                    whereinfo: " ownphone ='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querylbi',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.branddata = res.data
                        } else {
                            that.branddata = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querygtj () {
                that = this
                var qstr = {
                    whereinfo: " ownphone ='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ' order by recindex'
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygrecommend',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.tjgoodsdata = res.data
                        } else {
                            that.tjgoodsdata = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            showtgoods (gtypeid,zttitle) {
                localStorage.setItem('curztgtype', gtypeid)
                localStorage.setItem('zttitle', zttitle)
                that.$router.push({ path: 'ZTGoods' })
            },
            seeall: function () {
                this.$router.push('/Type')
            },
            showgoods: function(goodsid) {
                localStorage.setItem('curgoodsid', goodsid)
                this.$router.push('/GoodsInfo')
            },
            showkjrk(kjrkname,link) {
                window.console.log(kjrkname)
                if (kjrkname === '全部产品') {
                    that.$router.push({ path: 'AllGoods' })
                } else if (kjrkname === '产品分类') {
                    that.$router.push({ path: 'GoodsType' })
                } else if (kjrkname === '促销商品') {
                    that.$router.push({ path: 'SaleGoods' })
                } else {
                    localStorage.setItem('curztgtype', link)
                    localStorage.setItem('zttitle', kjrkname)
                    that.$router.push({ path: 'ZTGoods' })
                }

            }
        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
</script>
<style>
    .van-grid-item__content {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        padding: 0px;
        background-color: #fff;
    }
</style>

<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                服务列表
            </div>
            <van-cell-group>
                <van-cell v-for="(item, index) in ServiceList" :key="index" is-link >
                    <template #title>
                        <div v-if="item.status === 'success'" style="color: green;display: flex;flex-flow: row;align-items: center;">
                            <van-icon name="circle"/>
                            <span style="margin-left: 10px;" class="custom-title">{{item.data}}</span>
                        </div>
                        <div v-else style="color: red;display: flex;flex-flow: row;align-items: center;">
                            <van-icon name="circle"/>
                            <span style="margin-left: 10px;" class="custom-title">{{item.data}}</span>
                        </div>
                    </template>
                    <template #right-icon>
                        <div v-if="item.status === 'success'" style="color: green;display: flex;flex-flow: row;align-items: center;">
                            <van-button v-if="index < 5" hairline type="danger" @click="stopservice(index)" size="small">
                                <span>停止服务</span>
                            </van-button>
                            <van-button v-else hairline type="info" @click="stopservice(index)" size="small">
                                <span>重启服务</span>
                            </van-button>
                        </div>
                        <div v-else style="color: red;display: flex;flex-flow: row;align-items: center;">
                            <van-button v-if="index < 5" hairline type="primary" @click="startservice(index)" size="small">
                                <span>启动服务</span>
                            </van-button>
                            <van-button v-else hairline type="info" @click="startservice(index)" size="small">
                                <span>重启服务</span>
                            </van-button>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
            <van-overlay :show="loadshow">
                <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
                    <van-loading size="24px" style="color:#fff">{{showtxt}}</van-loading>
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { NavBar } from 'vant';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { Panel } from 'vant';
    import { Cell, CellGroup } from 'vant';
    import { Image } from 'vant';
    import { Notify } from 'vant';
    import { Loading } from 'vant';
    import { Overlay } from 'vant';

    Vue.use(Overlay);
    Vue.use(Loading);
    Vue.use(Notify);
    Vue.prototype.$http = axios
    Vue.use(Image);
    Vue.use(Cell);
    Vue.use(CellGroup);
    Vue.use(Panel);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    Vue.use(NavBar);
    var that
    export default {
        name: "ServiceList",
        data () {
            return {
               ServiceList: [],
                loadshow:false,
                showtxt: ''
            }
        },
        mounted () {
            that = this
            window.console.log(that)
            document.title = '服务管理'
            that.getservicelist()
        },
        methods: {
            startservice (index) {
                window.console.log(index)
                that.loadshow = true
                if (index === 0) {
                    that.showtxt = 'OD服务启动中'
                    that.$http({
                        url: 'https://service.sqnetsoft.cn/startodservice',
                        method: 'post'
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                that.getservicelist()
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                }
                if (index === 1) {
                    that.showtxt = '知小茉基础服务启动中'
                    that.$http({
                        url: 'https://service.sqnetsoft.cn/startzxmservice',
                        method: 'post'
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                that.getservicelist()
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                }
                if (index === 2) {
                    that.showtxt = '知小茉连接服务启动中'
                    that.$http({
                        url: 'https://service.sqnetsoft.cn/startzxmcontactservice',
                        method: 'post'
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                that.getservicelist()
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                }
                if (index === 3) {
                    that.showtxt = '弹弓服务启动中'
                    that.$http({
                        url: 'https://service.sqnetsoft.cn/startdgservice',
                        method: 'post'
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                that.getservicelist()
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                }
                if (index === 4) {
                  that.$http({
                      url: 'https://service.sqnetsoft.cn/startservicemana',
                      method: 'post'
                  })
                      .then(function (res) {
                          if (res.data.result === 'success') {
                              that.getservicelist()
                          }
                      })
                      .catch(function (error) {
                          window.console.log(error)
                      })
              }
                if (index === 5) {
                    that.restartmysql()
                }
                if (index === 6) {
                    that.restartnginx()
                }
            },
            stopservice (index) {
                that.loadshow = true
              switch (index) {
                  case 0:
                      that.killport(5309)
                      break;
                  case 1:
                      that.killport(5509)
                      break;
                  case 2:
                      that.killport(5509)
                      break;
                  case 3:
                      that.killport(5809)
                      break;
                  case 4:
                      that.killport(5909)
                      break;
                  case 5:
                      that.restartmysql()
                      break;
                  case 6:
                      that.restartnginx()
                      break;
              }
            },
            restartnginx () {
                that.$http({
                    url: 'https://service.sqnetsoft.cn/restartnginx',
                    method: 'post'
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.getservicelist()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            restartmysql () {
                that.$http({
                    url: 'https://service.sqnetsoft.cn/restartmysql',
                    method: 'post'
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.getservicelist()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            killport (port) {
                that.showtxt = '服务关闭中'
                var killport = {
                    port: port.toString()
                }
                that.$http({
                    url: 'https://service.sqnetsoft.cn/killport',
                    method: 'post',
                    data: killport
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.getservicelist()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getservicelist () {
                that.showtxt = '服务启动中'
                that.$http({
                    url: 'https://service.sqnetsoft.cn/checkzxmservice',
                    method: 'post'
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.ServiceList = res.data
                        that.loadshow = false
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

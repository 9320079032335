<template>
    <div style="background-color: #f7f8fa;">
        <van-nav-bar
                left-text="返回"
                left-arrow
                title="次卡激活"
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                认证信息
            </div>
            <van-form>
                <van-field
                        v-model="tcardno"
                        name="卡号"
                        label="卡号"
                        placeholder="请在此填写卡号"
                        :rules="[{ required: true, message: '请填写卡号' }]"
                />
                <van-field
                        v-model="tcardpwd"
                        type="password"
                        name="卡密"
                        label="卡密"
                        @blur="checkcardpwd()"
                        placeholder="请在此填写卡密"
                        :rules="[{ required: true, message: '请填写卡密' }]"
                />
            </van-form>
            <div class="van-doc-demo-block__title">
                配送信息
            </div>
            <van-form>
                <van-contact-card type="edit" :name="shrname" :tel="shrcontact"  @click="showDeliver"/>
                <van-field
                        clickable
                        name="picker"
                        left-icon="clock-o"
                        :value="pstime"
                        label="配送时间"
                        placeholder="选择配送时间"
                        @click="showPicker = true"
                />
            </van-form>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="pstimelist"
                        @confirm="psqr"
                        @cancel="showPicker = false"
                />
            </van-popup>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="jhtcard">
                立即激活
            </van-button>
            <van-button style="margin-top: 10px;" round block type="info" native-type="submit" @click="uptcard">
                更新信息
            </van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Toast } from 'vant';
    import Utils from '../../util/utils'
    Vue.use(Toast);
    var that
    export default {
        name: "TCardInfo",
        data () {
            return {
                shrname: '',
                shrcontact: '',
                showPicker: false,
                shrid: '',
                tcardno: '',
                tcardpwd: '',
                pstime: '',
                pstimes: 0,
                pstimelist: ['5号','20号'],
                checkcardok: false,
                checkjhok: false,
            }
        },
        mounted() {
            that = this
            if (localStorage.getItem('shrname') && localStorage.getItem('shrcontact') && localStorage.getItem('shrid')) {
                this.shrname = localStorage.getItem('shrname')
                this.shrcontact = localStorage.getItem('shrcontact')
                this.shrid = localStorage.getItem('shrid')
            } else {
                that.$options.methods.loadAreaList()
            }
            window.console.log(this.$route)
            if (this.$route.query.way === '1') {
                window.console.log('再次激活，需要赋值')
                var obj = JSON.parse(localStorage.getItem('jhtcard'))
                window.console.log(obj)
                this.tcardno = obj[0].cardno
                this.tcardpwd = obj[0].cardpwd
                this.pstime =obj[0].pstime.String
                that.$options.methods.checkifjh()
                that.$options.methods.checkifgq()
            }
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/My'})
            },
            showDeliver () {
                this.$router.push({path: '/DList'})
            },
            psqr (value,index) {
                this.pstime = value
                this.showPicker = false;
                window.console.log(index)
            },
            loadAreaList () {
                var qstr = 'userid = ' + localStorage.getItem('userid')
                window.console.log(qstr)
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/getbua',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('获取收货地址')
                                window.console.log(tmpdata)
                                for (var i = 0; i < tmpdata.length; i++) {
                                    if (tmpdata[i].defaultad === 1) {
                                        that.shrname = tmpdata[i].username.String
                                        that.shrcontact = tmpdata[i].contact.String
                                        that.shrid = tmpdata[i].id.Int64
                                    }
                                }
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkcardpwd () {
                that.$options.methods.checkifjh()
                that.$options.methods.checkifgq()
                //还需要判断一下这个卡是否过期
            },
            checkifjh () {
                // 检测是否已经激活
                var qstr = "cardno = '" + that.tcardno + "' and cardpwd = '" + that.tcardpwd + "'"
                window.console.log(qstr)
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/getbtci',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        limit: {String: 'LIMIT 1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                that.checkjhok = false
                            } else {
                                that.checkjhok = true
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkifgq () {
                // 检测卡号是否有效并且是否过期
                var qstr = "tcardno = '" + that.tcardno + "' and tcardpwd = '" + that.tcardpwd + "'"
                window.console.log(qstr)
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/getbotc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        limit: {String: 'LIMIT 1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log(tmpdata)
                                that.pstimes = tmpdata[0].pstimes
                                var gmdata = tmpdata[0].optime
                                var pre = new Date(gmdata)
                                pre.setFullYear(pre.getFullYear()+3);
                                var curdata = new Date()
                                if (curdata < pre) {
                                    // 卡未失效
                                    that.checkcardok = true
                                } else {
                                    // 卡失效
                                    that.checkcardok = false
                                }
                            } else {
                                Toast('无效卡');
                                that.checkcardok = false
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            jhtcard () {
                window.console.log('是否有效并过期 ' + that.checkcardok)
                window.console.log('是否已激活 ' + that.checkjhok)
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                if (this.checkcardok && this.checkjhok)
                {
                    var adata = {
                        cardno: this.tcardno,
                        cardpwd: this.tcardpwd,
                        userid: parseInt(localStorage.getItem('userid')),
                        jhstate: parseInt(1),
                        psaddressid: parseInt(this.shrid),
                        pstime: {String: this.pstime, Valid: true},
                        pstimes: parseInt(this.pstimes),
                        sytimes: parseInt(0),
                        optime: nowStr,
                        cusid: parseInt(localStorage.getItem('cusid'))
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/order/aaubtci',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                that.$router.push({path: '/TCardMana'})
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                } else {
                    Toast('无效卡无法激活');
                }

            },
            uptcard () {
                if (this.checkcardok) {
                    var adata = {
                        cardno: this.tcardno,
                        cardpwd: this.tcardpwd,
                        userid: parseInt(localStorage.getItem('userid')),
                        jhstate: parseInt(1),
                        psaddressid: parseInt(this.shrid),
                        pstime: {String: this.pstime, Valid: true},
                        pstimes: parseInt(this.pstimes),
                        cusid: parseInt(localStorage.getItem('cusid'))
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/order/aaubtci',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                that.$router.push({path: '/TCardMana'})
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div style="width:100%;margin-top: 10px; background-color: #fff;">
        <div style="display: flex; width:100%; flex-flow: column;" v-for="(item, index) in dpjdata" :key="index">
            <van-cell style="font-size:12px" :title="'订单号：' + item.order_no" />
            <van-card
                    style="flex:1;margin-left: 10px;margin-right: 10px;"
                    v-for="(subitem, index) in item.suborder" :key="index"
                    :num="subitem.count"
                    :price="subitem.price"
                    desc="描述信息"
                    :title="subitem.name"
                    :thumb="subitem.imgurl"
            >
                <template #tags>
                    <van-tag plain type="danger">{{subitem.specinfo}}</van-tag>
                </template>
                <template #footer>
                    <van-button size="small" @touchstart="llpj(item.order_no,subitem.goodsid,index)">立即评价</van-button>
                </template>
            </van-card>
        </div>
    </div>
</template>

<script>
    var that
    export default {
        name: "DPJ",
        data () {
            return {
                dpjdata: [],
                dpjcount: 0
            }
        },
        mounted() {
            that = this
            document.title = '待评价订单'
            if (localStorage.getItem('dpjorder')) {
                this.dpjdata =  JSON.parse(localStorage.getItem('dpjorder') || '[]')
                this.dpjcount = this.dpjdata.length
            } else {
                that.$options.methods.getdpjorder()
            }
        },
        methods: {
            llpj (orderno,goodsid,index) {
                window.console.log(this.dpjdata[index].suborder)
                localStorage.setItem('plgid',goodsid)
                localStorage.setItem('plorderno',orderno)
                var paydata = []
                for (var i = 0;i < this.dpjdata[index].suborder.length; i++) {
                    var tmpdata = this.dpjdata[index].suborder[i]
                    var paydatatmp = {
                        orderno: orderno,
                        buynum: tmpdata.count.toString(),
                        goodsbarcode: tmpdata.barcode,
                        goodsbaseprice: tmpdata.price,
                        goodschecked: true,
                        goodsid: tmpdata.goods_id.toString(),
                        goodsimg: tmpdata.imgurl,
                        goodsname: tmpdata.name,
                        goodstype: tmpdata.goodstypeid,
                        specinfo: tmpdata.specinfo
                    }
                    paydata.push(paydatatmp)
                }
                localStorage.setItem('pjorder',JSON.stringify(paydata))
                this.$router.push({path: '/GComment'})
            },
            getdpjorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id = 4",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            if (res.data.data !== '[]') {
                                var tmpdata = res.data
                                window.console.log('订单信息')
                                that.dpjdata = tmpdata
                                that.dpjcount = tmpdata.length
                                window.console.log(that.dpjdata)
                                var orderarr = []
                                for (var i = 0; i < tmpdata.length; i++) {
                                    var objtmp = tmpdata[i]
                                    orderarr.push('"' + objtmp.order_no + '"')
                                }
                                var qstr3 = {
                                    whereinfo: 'order_no in (' + orderarr + ')',
                                    limit: '',
                                    sortinfo: ''
                                }
                                that.$http({
                                    url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                    method: 'post',
                                    data: qstr3,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.length > 0) {
                                            var obj1 = res.data
                                            var dpjtmp = that.dpjdata
                                            for (var j = 0; j < dpjtmp.length; j++) {
                                                var dpjobj = dpjtmp[j]
                                                var subordertmp = []
                                                for (var k = 0; k < obj1.length; k++) {
                                                    if (dpjobj.order_no === obj1[k].order_no) {
                                                        window.console.log(obj1[k].order_no)
                                                        subordertmp.push(obj1[k])
                                                    }
                                                }
                                                dpjtmp[j].suborder = subordertmp
                                            }
                                            window.console.log(dpjtmp)
                                            localStorage.setItem('dpjorder',JSON.stringify(dpjtmp))
                                            that.dpjdata = JSON.parse(localStorage.getItem('dpjorder'))
                                        } else {
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            that.dpjdata = []
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        that.dpjdata = []
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

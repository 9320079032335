<template>
    <div>
        <div style="background-color: #fff;">
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(item, index) in goodsimglist" :key="index" style="display: flex;align-items: center;justify-content: center;">
                    <div v-if="item.urltype === 1">
                        <van-image
                                :src="item.img_url"
                                width = "100%"
                                height="15rem"
                                fit="contain"/>
                    </div>
                    <div v-if="item.urltype === 2">
                        <video :src="item.img_url" class="goodsimg" controls></video>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div>
            <van-cell :title="goodsname">
                <van-icon class-prefix="iconfont icon-fenxiang redfontbc" name="extra" > 分享 </van-icon>
            </van-cell>
        </div>
        <div v-if="goodspricearea === ''" class='goodsprice'>
            <van-cell style="color: firebrick" :title="'￥' + goodsbaseprice">
                <div class='memberpricetxt' style="color: firebrick"> 会员价格：{{hyprice}} </div>
            </van-cell>
        </div>
        <div v-else class='goodsprice'>
            <van-cell style="color: firebrick" :title="goodspricearea">
            </van-cell>
        </div>
        <div>
            <van-cell title="购买数量">
                <van-stepper v-model="gcount" />
            </van-cell>
        </div>
        <div v-for="(item, index) in goodstattrdata" :key="index">
            <van-cell-group>
                <van-cell :title="item.attrname" />
                <div style="margin: 10px;">
                    <van-button  size="small" @touchstart="specchange(ggitem.attrgroupindex,ggitem.id)" class="specbtn" :type="ggitem.ifcheck ? 'info' : 'default'" v-for="(ggitem, index) in item.items" :key="index">{{ggitem.attrvalue}}</van-button>
                </div>
            </van-cell-group>
        </div>
        <!--
        <van-collapse v-model="activeNames" >
            <van-collapse-item :border="ifborder" title="规格信息" name="1">
                <div>
                    <van-button @touchstart="basespecchage(specitem.id)" class="specbtn" :type="specitem.ifcheck ? 'info' : 'default'" v-for="(specitem, index) in specarr" :key="index">{{specitem.gspecname}}</van-button>
                </div>
            </van-collapse-item>
        </van-collapse>
        -->
        <div>
            <van-cell-group>
                <van-cell title="属性信息" />
                <div style="margin-left:10px; margin-right:10px;" v-for="(item, index) in goodsattrdata" :key="index">
                    <van-cell :title="item.attrname" :value="item.attrvalue" title-style="font-size:small;" >
                    </van-cell>
                </div>
            </van-cell-group>
        </div>
        <van-tabs v-model="active" style="margin-bottom: 100px; background-color: #fff;">
            <van-tab title="商品详情">
                <div style="margin: 10px 10px 10px 10px;">
                    <div class="desinfo" v-html='goodsdes'></div>
                </div>
            </van-tab>
            <van-tab title="商品评价">
                <div v-for="(item, index) in goods_evaluate" :key="index" style="margin-top:5px;">
                    <div class="pjpanel">
                        <img class="userimg"
                             :src="item.userimg.String ? item.userimg.String : inactiveIcon"
                        >
                        <span style="display: flex; flex:1; justify-content: flex-end;">评论时间： {{item.evtime.String}}</span>
                    </div>
                    <div class='pjcontext'>{{item.context.String}}</div>
                    <div v-if="item.ifupimg">
                        <div class='upyyzzimg' >
                            <div class='upimgbtn1' v-for="(imgitem, index) in item.imgs" :key="index">
                                <img class="upimginfo" :src="imgitem" />
                            </div>
                        </div>
                    </div>
                    <div v-if="item.ifupvideo">
                        <div class='upyyzzimg' >
                            <div class='upimgbtn1' v-for="(videoitem, index) in item.videos" :key="index">
                                <video  class="upimginfo" :src="videoitem"> </video>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <div>
            <van-goods-action>
                <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
                <van-goods-action-icon icon="cart-o" text="购物车" @click="onLoadCart" :badge="gwccount"/>
                <van-goods-action-button type="warning" text="加入购物车" @click="onCart" />
                <van-goods-action-button type="danger" text="立即购买" @click="onBuy" />
            </van-goods-action>
        </div>
        <van-sku
                v-model="skushow"
                :sku="sku"
                :goods="goods"
                :quota="quota"
                :quota-used="quotaUsed"
                :message-config="messageConfig"
                :goods-id="goodsId"
                @buy-clicked="onBuyClicked"
                @add-cart="onAddCartClicked"
                @sku-selected="skuSelect"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { Icon } from 'vant';
    import { Stepper } from 'vant';
    import { Collapse, CollapseItem } from 'vant';
    import { Tab, Tabs } from 'vant';
    import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
    import { Sku } from 'vant';
    Vue.use(Tab);
    Vue.use(Tabs)
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Stepper);
    Vue.use(Icon);
    Vue.use(Sku);
    Vue.use(GoodsAction);
    Vue.use(GoodsActionButton);
    Vue.use(GoodsActionIcon);
    Vue.prototype.$http = axios
    var that
    export default {
        name: "GoodsShow",
        data () {
            return {
                activeNames: [],
                attractiveNames: [],
                specarr: [],
                active: 0,
                subactivenames: [],
                goodsimglist: [],
                goodsattrdata: [],
                goodstattrdata: [],
                gwccount: 0,
                inactiveIcon: 'https://sqpro.oss-cn-beijing.aliyuncs.com/wk/v2-af12f7b6f7eb2f2f5f7e2f3b7880cf01_hd.jpg',
                ifborder:false,
                checked: true,
                userinfo: '',
                cardtimes: 1,
                cardtype: 0,
                userimg: '',
                usernick: '',
                goodsdes: '',
                //goodsid: '276',
                goodsid: '',
                goodsId: '',
                barcode: '',
                goodsname: '',
                goodstype: '',
                selectspecinfo: [],
                selectspectxt: '',
                quota: 0,
                quotaUsed: 0,
                defaultselectids: '',
                messageinfo: '',
                gcount: 1,
                goodsbaseprice: '',
                goodspricearea: '',
                memberpercent: 100,
                goods_evaluate: [],
                pricelist: [],
                hyprice: '',
                skushow: false,
                sku: {
                },
                goods: {
                    // 数据结构见下方文档
                },
                messageConfig: {}
            }
        },
        mounted: function () {
            that = this
            // 获取OpenID
            document.title = '商品信息'
            /*
            if (this.$route.query.cusid !== undefined) {
                localStorage.setItem('cusid',this.$route.query.cusid)
            }
            */
            /*
            that.allscreenWidth = (document.body.clientWidth / 5) - 5
            that.screenWidth = (document.body.clientWidth / 2) - 2
            window.onresize = () => {
                return (() =>{
                    that.allscreenWidth = (document.body.clientWidth / 5) - 5
                    that.screenWidth = (document.body.clientWidth / 2) - 1
                })()
            }*/
            that.getgoodsimglist()
            // 优先获取会员数据
            // 获取商品基础数据
            that.getgoodsinfo()
            // 获取商品规格数据
            that.getattrgroup()
            // 获取商品属性数据
            that.getbaseattr()
            // 获取价格数据
            that.getpricelist()
            // 获取商品描述
            that.getgoodsdes()
            // 获取商品描述
            // that.$options.methods.getgoodspl()
            // 获取商品图片
            var carttmp
            if (localStorage.getItem('cartinfo')) {
                carttmp =  JSON.parse(localStorage.getItem('cartinfo') || '[]')
            } else {
                carttmp = []
            }
            this.gwccount = carttmp.length
            // that.$options.methods.getspecgroup()
            // stopTouchendPropagationAfterScroll()
        },
        methods:{
            returnyw () {
                this.$router.go(-1)
            },
            /* 获取产品图片 */
            getgoodsimglist () {
                // var qstr = 'goods_id = ' + that.goodsid
                var qstr = {
                    whereinfo: 'pos=0 and goods_id = ' + localStorage.getItem('curgoodsid') + " and ownphone='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygimgs',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.goodsimglist = res.data
                        } else {
                            that.goodsimglist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取产品信息 */
            getgoodsinfo () {
                var qstr = {
                    whereinfo: 'id =' + localStorage.getItem('curgoodsid'),
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryginfo',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            that.barcode = tmpdata[0].barcode
                            that.goodsname = tmpdata[0].name
                            that.goodstype = tmpdata[0].goods_type_id
                            that.goodsbaseprice = tmpdata[0].baseprice
                            that.goodspricearea = tmpdata[0].pricetxt
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取产品描述 */
            getgoodsdes () {
                var qstr = {
                    whereinfo: 'goods_id = ' + localStorage.getItem('curgoodsid'),
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygdes',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('产品描述')
                            that.goodsdes = tmpdata[0].des
                            window.console.log(that.goodsdes)
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取评论数据 */
            getgoodspl () {
                // var qstr5 = 'cusid=1 and ctype= 3 or ctype =4 and gid=' + that.goodsid
                var qstr5 = 'cusid=' + localStorage.getItem('cusid') + ' and ctype= 3 or ctype =4 and gid=' + localStorage.getItem('curgoodsid')
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/goods/qgo',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr5, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                var qstr4 = 'contextstate = 1 and contextid=' + localStorage.getItem('curgoodsid')
                                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                                that.$http({
                                    url: 'https://ecommapi.sqnetsoft.cn/evaluate/getbce',
                                    method: 'post',
                                    data: {
                                        whereinfo: {String: qstr4, Valid: true},
                                        limit: { String: 'LIMIT 10', Valid: true }
                                    },
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.success) {
                                            if (res.data.data !== '[]') {
                                                var tmpdata1 = JSON.parse(res.data.data)
                                                var objtmps = []
                                                var pltmp = tmpdata
                                                for (var j = 0; j < tmpdata1.length; j++) {
                                                    var objtmp = tmpdata1[j]
                                                    var imgstmp = []
                                                    var videostmp = []
                                                    for (var i = 0; i < pltmp.length; i++) {
                                                        window.console.log(pltmp[i])
                                                        if (objtmp.id.Int64 === pltmp[i].pid) {
                                                            if (pltmp[i].ctype === 3) {
                                                                imgstmp.push(pltmp[i].curl.String)
                                                            } else if (pltmp[i].ctype === 4) {
                                                                videostmp.push(pltmp[i].curl.String)
                                                            }
                                                        }
                                                    }
                                                    if (imgstmp.length > 0) {
                                                        objtmp.ifupimg = true
                                                        objtmp.imgs = imgstmp
                                                    } else {
                                                        objtmp.ifupimg = false
                                                    }
                                                    if (videostmp.length > 0) {
                                                        objtmp.ifupvideo = true
                                                        objtmp.videos = videostmp
                                                    } else {
                                                        objtmp.ifupvideo = false
                                                    }
                                                    objtmps.push(objtmp)
                                                }
                                                that.goods_evaluate = objtmps
                                                window.console.log('商品评论')
                                                window.console.log(that.goods_evaluate)
                                            }
                                            // 把相关数据缓存到本地
                                        } else {
                                            window.console.log('无商品评论')
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取规格数据 */
            getattrgroup () {
                var qstr = {
                    whereinfo: 'goods_id = ' + localStorage.getItem('curgoodsid') + ' and attrtype = 1',
                    limit: '',
                    sortinfo: ' group by attrid'
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querbgav',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('分组规格',res)
                        if (res.data.length > 0) {
                            var zobj1 = res.data
                            var qstr1 = {
                                whereinfo: 'goods_id = ' + localStorage.getItem('curgoodsid') + ' and attrtype = 1',
                                limit: '',
                                sortinfo: ''
                            }
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/querbgav',
                                method: 'post',
                                data: qstr1,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.length > 0) {
                                        var obj = res.data
                                        window.console.log(obj)
                                        var atmp = []
                                        var selectids = []
                                        that.subactivenames = []
                                        for (var j = 0; j < zobj1.length; j++) {
                                            var zobjtmp = zobj1[j]
                                            var zotmp = []
                                            that.subactivenames.push(zobjtmp.attrid)
                                            for (var i = 0; i < obj.length; i++) {
                                                var objtmp = obj[i]
                                                if (zobjtmp.attrid === objtmp.attrid) {
                                                    var obj3 = {
                                                        attrgroupindex: j,
                                                        attrvalue: objtmp.attrvalue,
                                                        attrid: objtmp.attrid,
                                                        id: objtmp.id
                                                    }
                                                    zotmp.push(obj3)
                                                }
                                            }
                                            for (var k = 0; k< zotmp.length; k++) {
                                                if (k===0) {
                                                    zotmp[k].ifcheck = true
                                                    that.$set(that.selectspecinfo,zotmp[k].attrid.trim(),zotmp[k].attrvalue.trim())
                                                    selectids.push(zotmp[k].id)
                                                } else {
                                                    zotmp[k].ifcheck = false
                                                }
                                            }
                                            zobjtmp.items = zotmp
                                            atmp.push(zobjtmp)
                                        }
                                        var props = "";
                                        // 开始遍历
                                        for(var p in that.selectspecinfo){
                                            // 方法
                                            if(typeof(that.selectspecinfo[p])=="function"){
                                                this.selectspecinfo[p]();
                                            }else{
                                                // p 为属性名称，obj[p]为对应属性的值
                                                props += that.selectspecinfo[p] + ",";
                                            }
                                        }
                                        var basic = props.substr(0, props.length - 1);
                                        window.console.log(basic)
                                        that.selectspectxt = basic
                                        that.goodstattrdata = atmp
                                        window.console.log('最后数组')
                                        window.console.log(atmp)
                                        window.console.log(selectids)
                                        that.defaultselectids = selectids
                                        var qstr2 = {
                                            whereinfo: "specgroupid='" + selectids + "'",
                                            limit: '',
                                            sortinfo: ''
                                        }
                                        that.$http({
                                            url: 'https://ecommapi.sqnetsoft.cn/querygprice',
                                            method: 'post',
                                            data: qstr2,
                                            headers: {
                                                'Content-Type': 'application/json'
                                            }
                                        })
                                            .then(function (res) {
                                                if (res.data.length > 0) {
                                                    var tmpdata = res.data
                                                    window.console.log('规格价格')
                                                    window.console.log(tmpdata)
                                                    that.goodsbaseprice = tmpdata[0].baseprice
                                                    that.goodspricearea = tmpdata[0].baseprice
                                                    // that.cardtimes = tmpdata[0].times.Int64
                                                    // that.cardtype = tmpdata[0].tcardtype.Int64
                                                    that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                                    window.console.log(res)
                                                } else {
                                                    window.console.log('获取商品失败')
                                                }
                                            })
                                            .catch(function (error) {
                                                window.console.log(error)
                                            })
                                    } else {
                                        window.console.log('获取商品属性失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                        } else {
                            window.console.log('获取商品属性失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取属性数据 */
            getbaseattr () {
                var qstr1 = {
                    whereinfo: "goods_id = '" + localStorage.getItem('curgoodsid') + "' and attrtype=0 and  attrvalue !=''",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querbgav',
                    method: 'post',
                    data: qstr1,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            that.goodsattrdata = tmpdata
                            window.console.log('基础属性')
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            /* 获取产品价格 */
            getpricelist () {
                var qstr = {
                    whereinfo: " goodsid='" + localStorage.getItem('curgoodsid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygprice',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            that.pricelist = tmpdata
                            window.console.log('所有价格')
                            window.console.log(tmpdata)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            specchange(groupindex,specid) {
                window.console.log('规格变更')
                var tmpdata = that.goodstattrdata
                window.console.log(tmpdata)
                var attrids = []
                for (var i = 0; i < tmpdata.length; i++) {
                    if (i === groupindex) {
                        var subtmpdata = tmpdata[i].items
                        for (var j = 0; j < subtmpdata.length; j++) {
                            if (subtmpdata[j].id === specid) {
                                subtmpdata[j].ifcheck = true
                            } else {
                                subtmpdata[j].ifcheck = false
                            }
                        }
                    }
                }
                for (var k = 0; k < tmpdata.length; k++) {
                    var subtmpdata1 = tmpdata[k].items
                    for (var l = 0; l < subtmpdata1.length; l++) {
                        if (subtmpdata1[l].ifcheck) {
                            this.$set(this.selectspecinfo,subtmpdata1[l].attrid.trim(),subtmpdata1[l].attrvalue.trim())
                            attrids.push(subtmpdata1[l].id)
                        }
                    }
                }
                window.console.log('属性所属值',attrids)
                var props = "";
                // 开始遍历
                for(var p in this.selectspecinfo){
                    // 方法
                    if(typeof(this.selectspecinfo[p])=="function"){
                        this.selectspecinfo[p]();
                    }else{
                        // p 为属性名称，obj[p]为对应属性的值
                        props += this.selectspecinfo[p] + ",";
                    }
                }
                var basic = props.substr(0, props.length - 1);
                window.console.log(basic)
                this.selectspectxt = basic
                that.goodstattrdata = tmpdata
                var qstr2 = {
                    whereinfo: "specgroupid='" + attrids + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygprice',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('规格价格')
                            window.console.log(tmpdata)
                            that.goodsbaseprice = tmpdata[0].baseprice
                            that.goodspricearea = tmpdata[0].baseprice
                            // that.cardtimes = tmpdata[0].times.Int64
                            // that.cardtype = tmpdata[0].tcardtype.Int64
                            that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            basespecchage(specid) {
                window.console.log(specid)
                window.console.log(that.specarr)
                var tmpdata = that.specarr
                for (var i = 0; i < tmpdata.length; i++) {
                    if (tmpdata[i].id === specid) {
                        tmpdata[i].ifcheck = true
                    } else {
                        tmpdata[i].ifcheck = false
                    }
                }
                that.specarr = tmpdata
                var qstr = "cuserid = " + localStorage.getItem('cusid') + " and  specgroupid='" + specid + "'"

                window.console.log(qstr)
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/control/querysp',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('规格价格')
                                window.console.log(tmpdata)
                                that.goodsbaseprice = tmpdata[0].baseprice.String
                                that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                that.cardtimes = tmpdata[0].times.Int64
                                that.cardtype = tmpdata[0].tcardtype.Int64
                                window.console.log(res)
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onBuy () {
                window.console.log('获取参数')
                window.console.log(localStorage.getItem('curgoodsid'))
                window.console.log(this.goodsname)
                window.console.log(this.goodsbaseprice)
                window.console.log(this.goodsimglist)
                window.console.log(this.goodsattrdata)
                window.console.log(this.memberpercent)
                window.console.log(this.hyprice)
                window.console.log(this.goodstattrdata)
                this.goodsId = localStorage.getItem('curgoodsid')
                this.goods = {
                    picture: this.goodsimglist[0].img_url
                }
                var gdata = [
                ]
                var listitem = []
                var attrlistvalues = []
                var attrlistids = []
                for (var i = 0; i < this.goodstattrdata.length; i++) {
                    var gattgrouptmp = this.goodstattrdata[i]
                    var icontexts = []
                    var additemvalues = []
                    var additemids = []
                    for (var j = 0; j < gattgrouptmp.items.length; j++) {
                        var gattvaluetmp = gattgrouptmp.items[j]
                        var subitem = {
                            id: gattvaluetmp.id,
                            name: gattvaluetmp.attrvalue,
                            imgUrl: '', // 规格类目图片，只有第一个规格类目可以定义图片
                            previewImgUrl: '', // 用于预览显示的规格类目图片
                        }
                        icontexts.push(subitem)
                        window.console.log(gattvaluetmp.id)
                        additemvalues.push(gattvaluetmp.id)
                    }
                    additemids.push(this.goodstattrdata[i].attrid)
                    attrlistvalues.push(additemvalues)
                    attrlistids.push(additemids)
                    var item = {
                        k: gattgrouptmp.attrname,
                        v: icontexts
                    }
                    this.$set(item,'k_s', this.goodstattrdata[i].attrid)
                    gdata.push(item)
                    window.console.log(item)
                }
                window.console.log('规格数据')
                var results = []
                var result = []
                window.console.log(attrlistids)
                window.console.log(attrlistvalues)
                if (attrlistvalues.length > 0) {
                    doExchange(attrlistvalues, 0,result,results);
                }
                window.console.log(results)

                for (var x=0;x<results.length;x++) {
                    var listitemtmp = {
                        id: localStorage.getItem('curgoodsid'), // skuId，下单时后端需要
                        stock_num: 999
                    }
                    for (var z=0;z<that.pricelist.length;z++) {
                        if (results[x] === that.pricelist[z].specgroupid) {
                            listitemtmp['price'] = parseInt(that.pricelist[z].buyprice) * 100
                        }
                    }
                    var xstrs=results[x].split(",");
                    for (var y=0;y<xstrs.length;y++) {
                        listitemtmp[attrlistids[y]] = xstrs[y]
                    }
                    listitem.push(listitemtmp)
                }
                // this.goodstattrdata[0].attrid.String

                window.console.log('skutree数据',gdata)
                window.console.log('skutree列表数据',listitem)
               this.sku = {
                    tree: gdata,
                    list: listitem,
                    price:that.goodsbaseprice, // 默认价格（单位元）
                    stock_num: 999, // 商品总 库存
                    collection_id: this.barcode, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                    none_sku: false, // 是否无规格商品
                    hide_stock: false // 是否隐藏剩余库存
                }
                window.console.log(this.sku)
                this.skushow = true
            },
            onCart () {
                var cartinfo = []
                window.console.log(localStorage.getItem('cartinfo'))
                if (localStorage.getItem('cartinfo')) {
                    cartinfo =  JSON.parse(localStorage.getItem('cartinfo') || '[]')
                } else {
                    cartinfo = []
                }
                var goodsinfo = {
                    goodsid: localStorage.getItem('curgoodsid'),
                    goodsname: this.goodsname,
                    goodstype: this.goodstype,
                    goodsbarcode: this.barcode,
                    goodsbaseprice: this.goodsbaseprice,
                    specinfo: this.selectspectxt,
                    cardtimes: this.cardtimes,
                    cardtype: this.cardtype,
                    buynum: this.gcount,
                    goodsimg: this.goodsimglist[0].img_url,
                    goodschecked: true
                }
                cartinfo.push(goodsinfo)
                localStorage.setItem('cartinfo', JSON.stringify(cartinfo))
                this.gwccount = cartinfo.length
                if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                    // this.$router.push({path: '/RegMember'})
                } else {
                    this.skushow = true
                }
            },
            onBuyClicked (data) {
                window.console.log(data)
                var payinfo = []
                var goodsinfo = {
                    goodsid: localStorage.getItem('curgoodsid'),
                    goodsname: this.goodsname,
                    goodstype: this.goodstype,
                    goodsbarcode: this.barcode,
                    goodsbaseprice: this.goodsbaseprice,
                    specinfo: this.selectspectxt,
                    buynum: data.selectedNum,
                    cardtimes: this.cardtimes,
                    cardtype: this.cardtype,
                    goodsimg: this.goodsimglist[0].img_url,
                    goodschecked: true
                }
                payinfo.push(goodsinfo)
                localStorage.setItem('paydata', JSON.stringify(payinfo))
                this.$router.replace({path: '/CartJS'})
            },
            onAddCartClicked (data) {
                window.console.log('购物车数据')
                window.console.log(data)
                var cartinfo = []
                window.console.log(localStorage.getItem('cartinfo'))
                if (localStorage.getItem('cartinfo')) {
                    cartinfo =  JSON.parse(localStorage.getItem('cartinfo') || '[]')
                } else {
                    cartinfo = []
                }
                var goodsinfo = {
                    goodsid: localStorage.getItem('curgoodsid'),
                    goodsname: this.goodsname,
                    goodstype: this.goodstype,
                    goodsbarcode: this.barcode,
                    goodsbaseprice: this.goodsbaseprice,
                    specinfo: this.selectspectxt,
                    cardtimes: this.cardtimes,
                    cardtype: this.cardtype,
                    buynum: data.selectedNum,
                    goodsimg: this.goodsimglist[0].img_url,
                    goodschecked: true
                }
                cartinfo.push(goodsinfo)
                localStorage.setItem('cartinfo', JSON.stringify(cartinfo))
                this.gwccount = cartinfo.length
            },
            skuSelect(value) {
                window.console.log(value)
                this.$set(this.selectspecinfo,value.skuValue.skuKeyStr,value.skuValue.name)
                window.console.log(this.selectspecinfo)
                var props = "";
                // 开始遍历
                for(var p in this.selectspecinfo){
                    // 方法
                    if(typeof(this.selectspecinfo[p])=="function"){
                        this.selectspecinfo[p]();
                    }else{
                        // p 为属性名称，obj[p]为对应属性的值
                        props += this.selectspecinfo[p] + ",";
                    }
                }
                var basic = props.substr(0, props.length - 1);
                window.console.log(basic)
                this.selectspectxt = basic
                var ids = []
                for(var i in value.selectedSku){
                    // 方法
                    if(typeof(value.selectedSku[i])=="function"){
                        value.selectedSku[i]();
                    }else{
                        // p 为属性名称，obj[p]为对应属性的值
                        ids.push(value.selectedSku[i]);
                    }
                }
                var qstr2 = {
                    whereinfo: "specgroupid='" + ids + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querygprice',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = JSON.parse(res.data.data)
                            window.console.log('规格价格')
                            window.console.log(tmpdata)
                            that.goodsbaseprice = tmpdata[0].baseprice
                            that.goodspricearea = tmpdata[0].baseprice
                            that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onLoadCart () {
                this.$router.replace({path: '/CartInfo'})
            }
        }
    }
    function doExchange(arr, index,result,results){
        for (var i = 0; i<arr[index].length; i++) {
            result[index] = arr[index][i];
            if (index != arr.length - 1) {
                doExchange(arr, index + 1,result,results)
            } else {
                results.push(result.join(','))
            }
        }
    }
</script>

<style scoped>

    .goodsinfo {
        font-size:14px;
        margin:10px;
    }

    .specbtn {
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .goodsimg {
        width: 220px;
        height: 220px;
    }
    .goodsnameview {
        align-items: center;
        margin-bottom: 8px;
        display: flex;
        flex-flow: row;
    }
    .goodsname {
        flex:1;
    }
    .goodsprice {
        font-size: 14px;
        color: rgb(180, 11, 11);
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .pltime{
        width:200px;
    }
    .userimg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
    .shareview {
        width:60px;
        border: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        flex-flow: row;
        background-color: transparent;
        justify-content: center;
        box-sizing:border-box;
        font-size:14px;
    }
    .defalutpricetxt {
        font-size: 16px;
        text-decoration:line-through;
        flex: 1;
    }

    .defalutpricetxt1 {
        font-size: 16px;
        flex: 1;
    }
    .memberpricetxt {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
    }
    .redfontbc {
        font-family: "iconfont" !important;
        margin-right: 5px;
        font-size: 14px;
        font-style: normal;
        color: #b10000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .desinfo {
        font-size: 12px;
        width:100%;
    }
    .desinfo >>> img {
        width:100%;
    }
    .pjpanel {
        display: flex;
        align-items: center;
        height: 32px;
        margin-left:20px;
        margin-right:20px;
        font-size: 12px;
    }
    .pjcontext {
        padding-top: 10px;
        padding-left: 20px;
        min-height: 80px;
        font-size: 12px;
        margin: 10px;
        border: 1px  solid #f2f3f5;
        color: rgb(161, 161, 161);
    }
    .upyyzzimg {
        display: flex;
        padding-bottom: 10px;
        height: 100px;
        margin-left: 20px;
        margin-right: 20px;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
    }
    .upimgbtn1 {
        width: 90px;
        height: 90px;
        display: flex;
        font-size: 13px;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 5px;
        border: 1px #ccc solid;
    }
    .upimginfo {
        width: 90px;
        height: 90px;
    }
    .imgpanel {
        display: flex;
        align-items: center;
        height: 40px;
    }
</style>

<template>
    <div style="background-color: #fff;">
        <van-address-list
                v-model="chosenAddressId"
                :list="dzlist"
                :disabled-list="disabledList"
                disabled-text="以下地址超出配送范围"
                default-tag-text="默认"
                @select="onSelect"
                @add="onAdd"
                @edit="onEdit"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { AddressList } from 'vant';
    Vue.use(AddressList);
    var that
    export default {
        name: "dlist",
        data() {
            return {
                chosenAddressId: '1',
                dzlist: [],
                olddzlist: []
            };
        },
        mounted () {
            that = this
            that.$options.methods.loadAreaList()
        },
        methods: {
            loadAreaList () {
                var qstr2 = {
                    whereinfo: " openid='" + localStorage.getItem('openid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querybuaddress',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('获取收货地址')
                            window.console.log(tmpdata)
                            that.olddzlist = tmpdata
                            that.dzlist = []
                            for (var i=0;i<tmpdata.length;i++) {
                                var isdefault = false
                                if (tmpdata[i].defaultad === 1) {
                                    isdefault = true
                                    that.chosenAddressId = tmpdata[i].id
                                }
                                var dztmp = {
                                    id: tmpdata[i].id,
                                    name: tmpdata[i].username,
                                    tel: tmpdata[i].contact,
                                    address: tmpdata[i].address + tmpdata[i].ssaddress,
                                    isDefault: isdefault
                                }
                                that.dzlist.push(dztmp)
                            }
                        } else {
                            that.dzlist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onAdd() {
                this.$router.push({path: '/NAddress'})
            },
            onSelect (item, index) {
                window.console.log(item)
                window.console.log(index)
                localStorage.setItem('shrid',this.olddzlist[index].id)
                localStorage.setItem('shrname',item.name)
                localStorage.setItem('shrcontact',item.tel)
                this.$router.push({path: '/CartJS'})
            },
            onEdit(item, index) {
                window.console.log(item)
                window.console.log(index)
                window.console.log(this.olddzlist)
                localStorage.setItem('editaddress',JSON.stringify(this.olddzlist[index]))
                this.$router.push({path: '/EAddress'})
            },
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: "BDList",
        data () {
            return {
                imgurl: ''
            }},
        mounted () {
            document.title = '大数据/BI'
        },
        methods: {
        }

    }
</script>

<style scoped>

</style>
<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                ChatGPT(openai)整合
            </div>
            <van-cell-group>
                <van-cell is-link @click="chatgptgn" icon="chat-o">文字模块</van-cell>
            </van-cell-group>
            <van-cell-group>
                <van-cell is-link @click="chatgptcode" icon="label-o">代码模块</van-cell>
            </van-cell-group>
            <van-cell-group>
                <van-cell is-link @click="chatgptimg" icon="photo-o">图片模块</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                语音处理
            </div>
            <van-cell-group>
                <van-cell is-link @click="voiceread" icon="volume-o">语音识别</van-cell>
                <van-cell is-link @click="voicecompose" icon="volume-o">语音合成</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                翻译功能
            </div>
            <van-cell-group>
                <van-cell is-link @click="transtxt" icon="exchange">文字翻译</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                OCR识别
            </div>
            <van-cell-group>
                <van-cell is-link @click="sfz_scan" icon="photo-o">身份证识别</van-cell>
                <van-cell is-link @click="yhk_scan" icon="photo-o">银行卡识别</van-cell>
                <van-cell is-link @click="xsz_scan" icon="photo-o">行驶证识别</van-cell>
                <van-cell is-link @click="jsz_scan" icon="photo-o">驾驶证识别</van-cell>
                <van-cell is-link @click="yyzz_scan" icon="photo-o">营业执照识别</van-cell>
                <van-cell is-link @click="yst_scan" icon="photo-o">通用印刷体</van-cell>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    Vue.prototype.$http = axios
    import wx from 'weixin-js-sdk'
    var wxinfo = wx
    var that
    export default {
        name: "AIList",
        data () {
            return {
                imgurl: ''
            }},
        mounted () {
            document.title = '人工智能'
            that = this
            that.getaccesstoken()
            localStorage.removeItem('sid')
        },
        methods: {
            configwx: function () {
                // 获取微信签名
                var url = window.location.href;
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/getwxticket',
                    method: 'post',
                    data: {
                        url: url,
                        accesstoken: localStorage.getItem('accesstoken')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sign
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.noncestr
                            wxinfo.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    "startRecord",
                                    "stopRecord",
                                    "onVoiceRecordEnd",
                                    "playVoice",
                                    "pauseVoice",
                                    "stopVoice",
                                    "onVoicePlayEnd",
                                    "uploadVoice",
                                    "downloadVoice",
                                    "translateVoice",
                                    'chooseImage',
                                    'uploadImage',
                                    'getLocalImgData',
                                    'downloadImage'
                                ]
                            })
                            window.console.log(wxinfo)
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getaccesstoken: function () {
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            localStorage.setItem('accesstoken',res.data)
                            that.configwx()
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            chatgptgn () {
                this.$router.push({path: '/ChatGPT'})
            },
            chatgptcode () {
                this.$router.push({path: '/ChatGPTCode'})
            },
            chatgptimg () {
                this.$router.push({path: '/ChatGPTImg'})
            },
            returnyw() {
                this.$router.push({path: '/'})
            },
            voiceread () {
                this.$router.push({path: '/VoiceRead'})
            },
            voicecompose () {
                this.$router.push({path: '/VoiceCompose'})
            },
            transtxt () {
                this.$router.push({path: '/TransTxt'})
            },
            sfz_scan () {
                this.$router.push({path: '/SFZ'})
            },
            yhk_scan () {
                this.$router.push({path: '/YHK'})
            },
            xsz_scan () {
                this.$router.push({path: '/XSZ'})
            },
            jsz_scan () {
                this.$router.push({path: '/JSZ'})
            },
            yyzz_scan () {
                this.$router.push({path: '/YYZZ'})
            },
            yst_scan () {
                this.$router.push({path: '/Comm'})
            }
        }
    }
</script>

<style scoped>

</style>

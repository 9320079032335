<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                配置二维码
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            v-model="linkcontext"
                            name="内容"
                            label="内容"
                            clearable
                            placeholder="通常是链接地址或任意字符"
                    />
                    <van-field
                            v-model="shareid"
                            name="人员参数"
                            label="人员参数"
                            clearable
                            placeholder="通常是销售人员或推广人员ID（非必填）"
                    />
                    <van-field
                            v-model="cusid"
                            name="客户参数"
                            label="客户参数"
                            clearable
                            placeholder="请输入客户的ID，常用分享某一个具体客户的店铺（非必填）"
                    />
                    <van-field
                            v-model="goodsid"
                            name="产品参数"
                            label="产品参数"
                            clearable
                            placeholder="请输入某一个产品ID或者是虚拟课程ID，常用分享(非必填)"
                    />
                    <div style="display: flex;margin-top: 10px;">
                        <van-button  @click="defaultval" icon="star-o" ></van-button>
                        <van-button style="flex: 1; border-right: white 1px solid" @click="makecode" icon="qr" type="primary">动态二维码</van-button>
                        <van-button style="flex: 1;" @click="makelinecode" icon="qr" type="primary">动态条码</van-button>
                    </div>
                </van-form>
            </van-cell-group>
            <div style="display: flex; background-color: #fff; justify-content: center;align-items: center;">
                <div style="margin-top: 30px;" id="qrcode" ref="qrcode"></div>
                <img :hidden="imgshow" style="margin-top: 30px; width:100%" id="barcode"/>
            </div>
        </div>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    import JsBarcode from 'jsbarcode'
    export default {
        name: "MakeCode",
        data () {
            return {
                linkcontext: '',
                shareid: '',
                imgshow: false,
                cusid: '',
                goodsid: ''
            }
        },
        mounted () {
          document.title = '动态生成二维码'
        },
        methods: {
            makecode () {
                this.imgshow = true
                document.getElementById('qrcode').innerHTML = ''
                var urltmp = this.linkcontext + '?'
                if (this.shareid !== '') {
                    urltmp = urltmp + 'shareid=' + this.shareid + '&'
                }
                if (this.cusid !== '') {
                    urltmp = urltmp + 'cusid=' + this.cusid + '&'
                }
                if (this.goodsid !== '') {
                    urltmp = urltmp + 'goodsid=' + this.goodsid + '&'
                }
                var qrcode = new QRCode('qrcode', {
                    width: 128,
                    height: 128,
                    colorDark : '#000000',
                    colorLight : '#ffffff',
                    correctLevel : QRCode.CorrectLevel.H,
                    text: urltmp // 生成二维码的链接
                })
                window.console.log(qrcode)
            },
            makelinecode () {
                this.imgshow = false
                document.getElementById('qrcode').innerHTML = ''
                var qrcode = new JsBarcode("#barcode", this.linkcontext, {
                    format: "CODE39",  //条形码的格式
                    lineColor: "#000",  //线条颜色
                    width:1, //线宽
                    height:20,  //条码高度
                    displayValue: false //是否显示文字信息
                });
                window.console.log(qrcode)
            },
            defaultval () {
                this.linkcontext = 'http://www.sqnetsoft.cn/testcode.html'
                this.shareid = '1'
                this.cusid = '2'
                this.goodsid = '3'
            },
            returnyw () {
                this.$router.push({path: '/Example'})
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                分享
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            v-model="linkurl"
                            name="链接地址"
                            label="链接地址"
                            clearable
                            placeholder="通常是链接地址或任意字符"
                    />
                    <van-field
                            v-model="linkimg"
                            name="标题"
                            label="标题"
                            clearable
                            placeholder="分享图片"
                    />
                    <van-field
                            v-model="title"
                            name="标题"
                            label="标题"
                            clearable
                            placeholder="分享标题"
                    />
                    <van-field
                            v-model="des"
                            name="描述"
                            label="描述"
                            clearable
                            placeholder="分享内容描述"
                    />
                </van-form>
                <div style="display: flex;margin-top: 10px;">
                    <van-button  @click="defaultval" icon="star-o" ></van-button>
                    <van-button style="flex: 1;"  @click="sharepyq"  type="primary">保存</van-button>
                </div>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Notify } from 'vant';
    import wx from 'weixin-js-sdk'
    Vue.use(Notify);
    var that
    export default {
        name: "Share",
        data () {
            return {
                linkurl: '',
                linkimg: '',
                title: '',
                des: ''
            }
        },
        mounted() {
            that = this
        },
        methods: {
            defaultval () {
                that.linkurl = 'http://www.sqnetsoft.cn'
                that.linkimg = 'https://sqtech.oss-cn-beijing.aliyuncs.com/hlogo.png'
                that.title = '祺聚优品'
                that.des = '专业的人做专业的事'
            },
            sharepyq () {
                wx.onMenuShareTimeline({
                    title : that.title, // 分享标题
                    link : that.linkurl, // 分享链接
                    imgUrl : that.linkimg, // 分享图标
                    success : function() {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel : function() {
                        // 用户取消分享后执行的回调函数
                    },
                    fail (e) {
                        window.console.log(e)
                        Notify({ type: 'warning', message: '' + e.errMsg});
                    }
                });
                wx.onMenuShareAppMessage({
                    title: that.title,
                    desc: that.des,
                    link: that.linkurl,
                    imgUrl: that.linkimg,
                    trigger: function (res) {
                        window.console.log(res)
                        // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
                    },
                    success: function (res) {
                        window.console.log(res);
                        Notify({ type: 'success', message: '分享完毕'});
                    },
                    cancel: function (res) {
                        window.console.log(res);
                    },
                    fail: function (res) {
                        window.console.log(JSON.stringify(res));
                    }
                })
            },
            returnyw () {
                that.$router.push({path: '/Example'})
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div>
        <div style="margin: 10px">
            <div ref="pdfContent" id="htmlOutput"></div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    Vue.prototype.$http = axios
    export default {
        name: "Index",
        mounted() {
            this.getchaptercontext()
        },
        methods: {
            getchaptercontext () {
                var qstr = {
                    whereinfo: ' chapterid = 2',
                    limit: ' limit 1',
                    sortinfo: ''
                }
                this.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querychaptercontext',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0].chaptercontext
                            const contentDiv = document.getElementById('htmlOutput');
                            if (contentDiv) {
                                contentDiv.innerHTML = tmpdata;
                            }
                        } else {
                            window.console.log('无数据')
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style>
    .titlecss {
        font-size: 24px;
    }
    /* 角标 */
    .badge {
        position: relative;
        display: inline-block;
        color: #000;
        font-size: 10px;
    }

    .badge::after {
        top: 0;
        right: 0;
        transform: translateX(-50%);
        position: absolute;
    }

    .split-content {
        min-height: 800px;
        margin-top:20px;
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2;    /* Firefox */
        column-count: 2;
        -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
        -moz-column-gap: 20px;    /* Firefox */
        column-gap: 20px;
        -webkit-column-break-inside: avoid; /* 避免在元素内部进行列中断（针对webkit内核浏览器，如Chrome、Safari等） */
        page-break-inside: avoid; /* 避免在元素内部进行分页（通用属性，提高兼容性） */
        break-inside: avoid; /* 新的标准属性，用于避免在元素内部打断，增强兼容性 */
        margin-bottom: 10px; /* 每个拆分后的div之间添加一点间距，美观性考虑 */
    }
    .chaptercontext {
        word-wrap:break-word;
        word-break: break-all !important;
    }
    .attention_para_p {
        word-wrap:break-word;
        word-break: break-all !important;
        line-height: 1.5
    }
    .titlegroupbody,.footnote,.warningdiv {
        margin-bottom: 10px; /* 适当增加各部分之间的间距，让展示更清晰美观 */
    }
    .titlegrouptitle {
        background-color: #656565;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        padding: 6px;
    }
    .halfdiv {
        width: 28%;
    }
    .Topic_title .titlegrouptitle {
        background-color: #d1d1d1;
        color: #000;
        width: 90%;
        font-size: 18px;
        font-weight: 600;
        padding: 6px;
        border-left: 10px solid #656565;
    }
    .Topic_title .titlegroupbody {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .overview {
        margin-top: 20px;
        padding: 6px;
        background-color: #e6e6e6;
        font-size: 18px;
        font-weight: 600;
    }
    .titlegrouprow {
        width: 100%;
    }
    .subsection_attention {
        width: 100%;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
    }
    .SubSection_title {
        min-height: 60px;
        color: #000;
        font-size:18px;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
    }
    .Topic_title {
        width: 100%;
        color: #000;
        font-size:18px;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
    }
    .page-break {
        background-color: #d58512;
        page-break-before: always; /* 在分页标记前强制分页 */
        height: 20px; /* 高度设为0，不占据实际空间 */
        clear: both; /* 清除浮动等，保证分页位置准确 */
    }
    .warningdiv {
        border: 1px solid #ff00ff;
        display: flex;
        flex-direction: column;
    }
    .warning {
        padding: 8px;
    }
    .warningtitle {
        width: 98%;
        background-color: #ffccff;
        border-bottom: 1px solid #ff00ff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 4px;
    }
    .attention_para_titleleft {
        width: 16px;
        height: 16px;
        background-color: #ff00ff;
        margin-right: 5px;
    }
    .attention_para_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .om_body {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .footnote {
        font-size: 16px;
    }
    .overview {
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
    }
    .overview:hover {
        animation: blink 1s infinite;
    }
    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>
<template>
    <div style="width:100%;margin-top: 10px;">
        <div style="display: flex; width:100%; flex-flow: column;" v-for="(item, index) in ckdata" :key="index">
            <van-cell style="font-size:12px" :title="'订单号：' + item.orderno" />
            <van-card
                    style="flex:1;margin-left: 10px;margin-right: 10px;"
                    v-for="(subitem, index) in item.suborder" :key="index"
                    :num="subitem.count.Int64"
                    :price="subitem.price.String"
                    desc="描述信息"
                    :title="subitem.name.String"
                    :thumb="subitem.imgurl.String"
            >
                <template #tags>
                    <van-tag plain type="danger">{{subitem.specinfo.String}}</van-tag>
                </template>
            </van-card>
            <van-cell title="">
                <template #right-icon>
                    <van-button size="small" @touchstart="jhck(item.orderno)" style="margin-left: 5px;">激活次卡</van-button>
                    <van-button size="small" @touchstart="lookck(item.orderno)" style="margin-left: 5px;">查看信息</van-button>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
    var that
    export default {
        name: "CardMana",
        data () {
            return {
                ckdata: [],
                ckcount: 0
            }
        },
        mounted () {
            that = this
            that.$options.methods.getckdata()
        },
        methods: {
            getckdata () {
                var qstr = 'buy_cus_id = ' + localStorage.getItem('userid') + ' and state_id = 2 and type_id=9'
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/goi',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        sorinfo: {String: " order by op_time desc ", Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('订单信息')
                                that.ckdata = tmpdata
                                that.ckcount = tmpdata.length
                                window.console.log(that.ckdata)
                                var orderarr = []
                                for (var i = 0; i < tmpdata.length; i++) {
                                    var objtmp = tmpdata[i]
                                    orderarr.push('"' + objtmp.orderno + '"')
                                }
                                var qfkddstr1 = 'order_no in (' + orderarr + ')'
                                that.$http({
                                    url: 'https://api.sqnetsoft.cn/order/god',
                                    method: 'post',
                                    data: {
                                        whereinfo: {String: qfkddstr1, Valid: true}
                                    },
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.success) {
                                            if (res.data.data !== '[]') {
                                                var obj1 = JSON.parse(res.data.data)
                                                var dfktmp = that.ckdata
                                                for (var j = 0; j < dfktmp.length; j++) {
                                                    var dfkobj = dfktmp[j]
                                                    var subordertmp = []
                                                    for (var k = 0; k < obj1.length; k++) {
                                                        if (dfkobj.orderno === obj1[k].orderno.String) {
                                                            window.console.log(obj1[k].orderno.String)
                                                            subordertmp.push(obj1[k])
                                                        }
                                                    }
                                                    dfktmp[j].suborder = subordertmp
                                                }
                                                window.console.log(dfktmp)
                                                localStorage.setItem('ckdata',JSON.stringify(dfktmp))
                                                that.ckdata = JSON.parse(localStorage.getItem('ckdata'))
                                            }
                                            // 把相关数据缓存到本地
                                        } else {
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            hxttcard (cardtype) {
                //选择卡并核销一张卡，同时更新订单数据
                var qstr = 'cardtype = ' + cardtype + ' and state = 0'
                window.console.log(qstr)
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/getbtc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        limit: {String: 'LIMIT 1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('获取可用卡号')
                                window.console.log(tmpdata)
                                that.$options.methods.uptcardstate(tmpdata[0].tcardno)
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                /*
                tcardinfo = {
                    orderno: ordertmp,
                    pstime: that.pstime,
                    pstimes: that.cartdata.cardtimes,
                    tcardno:
                    jhstate:
                    userid:
                    cusid:
                        }
                 */
            },
            uptcardstate (tcardno) {
                var qstr = "tcardno ='" + tcardno + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/upbtc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        fieldparams: {String: ' state=1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('卡号状态更新完毕')
                            // 执行写入订单操作
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            jhck (orderno) {
                window.console.log(orderno)
            },
            lookck(orderno) {
                window.console.log(orderno)
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div>
        <van-sku
                v-model="skushow"
                :sku="sku"
                :goods="goods"
                :quota="quota"
                :quota-used="quotaUsed"
                :hide-stock="sku.hide_stock"
                :message-config="messageConfig"
                :goods-id="goodsId"
                @buy-clicked="onBuyClicked"
                @add-cart="onAddCartClicked"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Sku } from 'vant';
    Vue.use(Sku);
    export default {
        name: "GoodsTest",
        data () {
            return {
                skushow: false,
                quotaUsed: 0,
                quota: 0,
                sku: [],
                goods: {},
                goodsId: '273',
                messageConfig: {}
            }
        },
        mounted() {
            this.skushow = true
            var gdata = [
                {
                    k: '颜色', // skuKeyName：规格类目名称
                    v: [
                        {
                            id: '30349', // skuValueId：规格值 id
                            name: '红色', // skuValueName：规格值名称
                            imgUrl: 'https://img.yzcdn.cn/1.jpg', // 规格类目图片，只有第一个规格类目可以定义图片
                            previewImgUrl: 'https://img.yzcdn.cn/1p.jpg', // 用于预览显示的规格类目图片
                        },
                        {
                            id: '1215',
                            name: '蓝色',
                            imgUrl: 'https://img.yzcdn.cn/2.jpg',
                            previewImgUrl: 'https://img.yzcdn.cn/2p.jpg',
                        }
                    ],
                    k_s: 's1' // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
                }
            ]
            var treedata = {
                tree:gdata
            }
            this.sku.push(treedata)
            this.sku.price = '1.00'
            this.sku.stock_num = 227
            this.sku.none_sku = false
            this.goods = {
                picture: 'https://img.yzcdn.cn/2.jpg'
            }
        },
        methods: {
            onBuyClicked () {},
            onAddCartClicked () {}
        }
    }
</script>

<style scoped>

</style>
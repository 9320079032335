<template>
    <div class="full">
        <!--
        <van-panel title="标题" desc="描述信息" status="状态">
            <div class="van-panel__content"><div>内容</div></div>
        </van-panel>
        -->
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                选择小程序
            </div>
            <van-cell-group>
                <van-cell is-link @touchend="showds" @click="showds" icon="location-o">电商小程序</van-cell>
                <van-cell is-link @touchend="showpx" @click="showpx" icon="location-o">培训小程序</van-cell>
                <van-cell is-link @touchend="openzxm" @click="openzxm" icon="location-o">知小茉小程序</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                {{bartitle}}
            </div>
            <van-cell-group>
                <div class="barcodeshow">
                    <van-image
                            width="160"
                            height="160"
                            :src="imgurl"
                    />
                </div>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { NavBar } from 'vant';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { Panel } from 'vant';
    import { Cell, CellGroup } from 'vant';
    import { Image } from 'vant';
    Vue.use(Image);
    Vue.use(Cell);
    Vue.use(CellGroup);
    Vue.use(Panel);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    Vue.use(NavBar);
    export default {
        name: "MPList",
        data () {
        return {
            imgurl: '',
            bartitle: '演示二维码'
        }},
        mounted () {
            document.title = '小程序案例'
        },
        methods: {
            openzxm () {
                window.location.href = 'weixin://dl/business/?t=V9f7aq6MxKu'
            },
            returnyw () {
                this.$router.push({path: '/'})
            },
            showds () {
                this.imgurl = "https://sqpro.oss-cn-beijing.aliyuncs.com/wk/images/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200224164402.jpg"
                this.bartitle = "演示二维码(电商)，长按识别二维码访问"
            },
            showpx () {
                this.imgurl = "https://sqpro.oss-cn-beijing.aliyuncs.com/wk/images/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200224164415.jpg"
                this.bartitle = "演示二维码(培训)，长按识别二维码访问"
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .barcodeshow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }
</style>

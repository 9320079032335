<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                用户信息
            </div>
            <van-form @submit="onSubmit">
                <van-field
                        v-model="jsname"
                        name="姓名"
                        label="姓名"
                        placeholder="姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                        v-model="jsname"
                        name="职位"
                        label="职位"
                        placeholder="职位"
                        :rules="[{ required: true, message: '请填写职位' }]"
                />
                <van-field name="uploader" label="身份证正面"  placeholder="上传身份证正面">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                    </template>
                </van-field>
                <van-field name="uploader" label="身份证反面"  placeholder="上传身份证反面">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                    </template>
                </van-field>
            </van-form>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                企业信息
            </div>
            <van-form @submit="onSubmit">
                <van-field
                        v-model="jsname"
                        name="公司名称"
                        label="公司名称"
                        placeholder="公司名称"
                        :rules="[{ required: true, message: '请填写公司名称' }]"
                />
                <van-field
                        v-model="jsname"
                        name="营业执照号"
                        label="营业执照号"
                        placeholder="营业执照号"
                        :rules="[{ required: true, message: '请填写营业执照' }]"
                />
                <van-field name="uploader" label="营业执照"  placeholder="上传营业执照">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                    </template>
                </van-field>

                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">
                        立即注册
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Uploader } from 'vant';

    Vue.use(Uploader);
    export default {
        name: "RegTwoStep"
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
    </div>
</template>

<script>
    export default {
        name: "VoiceCompose",
        methods: {
            returnyw () {
                this.$router.push({path: '/AIList'})
            }
        }
    }
</script>

<style scoped>

</style>
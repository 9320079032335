<template>
    <div>
        <div style=" margin-bottom: 40px; background-color: #fff;">
            <div style="display: flex;background-color: #fff; margin: 0 10px 0 10px;" v-for="(item, index) in cartdata" :key="index">
                <van-checkbox v-model="item.goodschecked" @change="changegc()"></van-checkbox>
                <van-card
                        style="flex:1"
                        :num="item.buynum"
                        :price="item.goodsbaseprice"
                        desc="描述信息"
                        :title="item.goodsname"
                        :thumb="item.goodsimg"
                >
                    <template #tags>
                        <van-tag plain type="danger">{{item.specinfo}}</van-tag>
                    </template>
                    <template #footer>
                        <div style="display: flex; justify-content: flex-end; align-items: center">
                            <van-icon size="16px" style="margin-right: 10px;" @click="delinfo(index)" name="delete" />
                            <van-stepper  min="1" @change="changestep(item.buynum,index)" v-model="item.buynum" />
                        </div>
                    </template>
                </van-card>
            </div>
        </div>
        <van-submit-bar style="bottom:40px;" :price="jscount" button-text="提交订单" @submit="onSubmit" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Checkbox, CheckboxGroup } from 'vant';
    import { Notify } from 'vant';
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    export default {
        name: "CartInfo",
        data () {
            return {
                cartdata: [],
                jscount: 0,
                ifallchecked: false
            }
        },
        mounted() {
            document.title = '购物车'
            window.console.log(localStorage.getItem('cartinfo'))
            if (localStorage.getItem('cartinfo')) {
                this.cartdata =  JSON.parse(localStorage.getItem('cartinfo') || '[]')
            } else {
                this.cartdata = []
            }
           var jstmp = 0

           for (var i = 0; i < this.cartdata.length; i++) {
               var datatmp = this.cartdata[i]
               if (!datatmp.goodschecked) {
                   this.ifallchecked = false
               } else {
                   jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
               }
           }
           this.jscount = jstmp
        },
        methods: {
            returnyw() {
                this.$router.push({path: '/'})
            },
            changegc () {
                var jstmp = 0
                localStorage.setItem('cartinfo',JSON.stringify(this.cartdata))
                for (var i = 0; i < this.cartdata.length; i++) {
                    var datatmp = this.cartdata[i]
                    if (!datatmp.goodschecked) {
                        this.ifallchecked = false
                    } else {
                        jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
                    }
                }
                this.jscount = jstmp
            },
            delinfo (index) {
                this.cartdata.splice(index,1)
                localStorage.setItem('cartinfo',JSON.stringify(this.cartdata))
                var jstmp1 = 0
                for (var j = 0; j < this.cartdata.length; j++) {
                    var datatmp1 = this.cartdata[j]
                    if (!datatmp1.goodschecked) {
                        this.ifallchecked = false
                    } else {
                        jstmp1 = jstmp1 + parseFloat(datatmp1.goodsbaseprice).toFixed(2) * parseInt(datatmp1.buynum)* 100
                    }
                }
                this.jscount = jstmp1
            },
            changestep (value,index) {
                window.console.log(value,index)
                var jstmp = 0
                this.cartdata[index].buynum = value
                localStorage.setItem('cartinfo',JSON.stringify(this.cartdata))
                for (var i = 0; i < this.cartdata.length; i++) {
                    var datatmp = this.cartdata[i]
                    if (!datatmp.goodschecked) {
                        this.ifallchecked = false
                    } else {
                        jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
                    }
                }
                this.jscount = jstmp


            },
            onSubmit () {
                var paydata = []
                var tstype = false
                for (var i = 0; i < this.cartdata.length; i++) {
                    var datatmp = this.cartdata[i]
                    if (datatmp.goodschecked) {
                        paydata.push(datatmp)
                    }
                    if (datatmp.cardtype && datatmp.goodschecked && datatmp.cardtype > 0) {
                        tstype = true
                    }
                }
                if (tstype && paydata.length > 1) {
                    Notify('特殊卡只能单独购买');
                } else if (tstype && paydata.length === 1) {
                    localStorage.setItem('tsorder',1)
                    localStorage.setItem('neworder',1)
                    localStorage.setItem('paydata',JSON.stringify(paydata))
                    this.$router.push({path: '/CartJS'})
                } else {
                    localStorage.setItem('tsorder',0)
                    localStorage.setItem('neworder',1)
                    localStorage.setItem('paydata',JSON.stringify(paydata))
                    this.$router.push({path: '/CartJS'})
                }
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div style="font-size: 13px; padding: 20px; background-color: #fff;">
        <div>订单信息</div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 50%;height: 80px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 28px;">{{dayordercount}}</div>
                <div>当日支付订单数</div>
            </div>
            <div style="width: 50%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 28px;">{{day3addcount}}</div>
                <div >近三天支付订单数</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import '../../../static/css/my.css'
    import Utils from '../../util/utils'
    import { Dialog } from 'vant';
    // 全局注册
    Vue.use(Dialog);
    Vue.prototype.$http = axios
    var that
    export default {
        name: "Data",
        data () {
            return {
                dayordercount: 0,
                day3addcount: 0
            }
        },
        mounted() {
            that = this
            this.queryorderbyday()
            this.queryorderbyday3()
        },
        methods: {
            queryorderbyday () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " op_time > '" + curdata + " 00:00:00' and op_time < '" + curdata + " 23:59:59' and state_id > 1 and state_id !=9"
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by op_time desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.dayordercount = res.data[0].count
                        } else {
                            that.dayordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryorderbyday3 () {
                var qstr = ' op_time > DATE_SUB(CURDATE(), INTERVAL 3 DAY) and state_id >=2 and state_id !=9'
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by op_time desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.day3addcount = res.data[0].count
                        } else {
                            that.day3addcount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
        }
    }
</script>

<style scoped>

</style>
<template>
    <div style="width:100%;margin-top: 10px;background-color: #fff;">
        <div style="display: flex; width:100%; flex-flow: column;" v-for="(item, index) in ywcdata" :key="index">
            <van-cell style="font-size:12px" :title="'订单号：' + item.order_no" />
            <van-card
                    style="flex:1;margin-left: 10px;margin-right: 10px;"
                    v-for="(subitem, index) in item.suborder" :key="index"
                    :num="subitem.count"
                    :price="subitem.price"
                    desc="描述信息"
                    :title="subitem.name"
                    :thumb="subitem.imgurl"
            >
                <template #tags>
                    <van-tag plain type="danger">{{subitem.specinfo}}</van-tag>
                </template>
            </van-card>
        </div>
    </div>
</template>

<script>
    var that
    export default {
        name: "YWC",
        data () {
            return {
                ywcdata: [],
                ywccount: 0
            }
        },
        mounted() {
            that = this
            document.title = '已完成订单'
            if (localStorage.getItem('ywcorder')) {
                this.ywcdata =  JSON.parse(localStorage.getItem('ywcorder') || '[]')
                this.ywccount = this.ywcdata.length
            } else {
                that.$options.methods.getywcorder()
            }
        },
        methods: {
            getywcorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id = 5",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            if (res.data.data !== '[]') {
                                var tmpdata = res.data
                                window.console.log('订单信息')
                                that.ywcdata = tmpdata
                                that.ywccount = tmpdata.length
                                window.console.log(that.ywcdata)
                                var orderarr = []
                                for (var i = 0; i < tmpdata.length; i++) {
                                    var objtmp = tmpdata[i]
                                    orderarr.push('"' + objtmp.order_no + '"')
                                }
                                var qstr3 = {
                                    whereinfo: 'order_no in (' + orderarr + ')',
                                    limit: '',
                                    sortinfo: ''
                                }
                                that.$http({
                                    url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                    method: 'post',
                                    data: qstr3,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.length > 0) {
                                            var obj1 = res.data
                                            var dpjtmp = that.ywcdata
                                            for (var j = 0; j < dpjtmp.length; j++) {
                                                var dpjobj = dpjtmp[j]
                                                var subordertmp = []
                                                for (var k = 0; k < obj1.length; k++) {
                                                    if (dpjobj.order_no === obj1[k].order_no) {
                                                        window.console.log(obj1[k].order_no)
                                                        subordertmp.push(obj1[k])
                                                    }
                                                }
                                                dpjtmp[j].suborder = subordertmp
                                            }
                                            window.console.log(dpjtmp)
                                            localStorage.setItem('ywcorder',JSON.stringify(dpjtmp))
                                            that.ywcdata = JSON.parse(localStorage.getItem('ywcorder'))
                                        } else {
                                            that.ywcdata = []
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            that.ywcdata = []
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

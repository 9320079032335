<template>
    <div style="font-size: 13px; padding: 20px; background-color: #fff;">
        <div>订单信息</div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 25%;height: 80px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 20px;">{{dayorderprice}}</div>
                <div>日销售额</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 20px;">{{day7orderprice}}</div>
                <div >周销售额</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 20px;">{{day30orderprice}}</div>
                <div >月销售额</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 20px;">{{allorderprice}}</div>
                <div >售额总额</div>
            </div>
        </div>
        <div style="margin-top: 20px;">会员信息</div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 25%;height: 80px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{daymemberaddcount}}</div>
                <div >日新增</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{day7memberaddcount}}</div>
                <div >周新增</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{day30memberaddcount}}</div>
                <div >月新增</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{membercount}}</div>
                <div>会员总数</div>
            </div>
        </div>
        <div style="margin-top: 20px;">品鉴酒领取记录</div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 50%;height: 90px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{winecount}}</div>
                        <div>苏帮袁</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{winecount1}}</div>
                        <div>一级领取</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{winecount2}}</div>
                        <div>分享领取</div>
                    </div>
                </div>
                <div style="margin-top: 5px; padding-top: 5px; border-top: 1px solid #fff;width: 100%; text-align: center;">日领取数</div>
            </div>
            <div style="width: 50%;height: 90px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine7count}}</div>
                        <div>苏帮袁</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine7count1}}</div>
                        <div>一级领取</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine7count2}}</div>
                        <div>分享领取</div>
                    </div>
                </div>
                <div style="margin-top: 5px; padding-top: 5px; border-top: 1px solid #fff;width: 100%; text-align: center;">周领取数</div>
            </div>
        </div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 50%;height: 90px;  display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine30count}}</div>
                        <div>苏帮袁</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine30count1}}</div>
                        <div>一级领取</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wine30count2}}</div>
                        <div>分享领取</div>
                    </div>
                </div>
                <div style="margin-top: 5px; padding-top: 5px; border-top: 1px solid #fff;width: 100%; text-align: center;">月领取数</div>
            </div>
            <div style="width: 50%;height: 90px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wineallcount}}</div>
                        <div>苏帮袁</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wineallcount1}}</div>
                        <div>一级领取</div>
                    </div>
                    <div style="display: flex; margin-left: 10px; flex-direction: column;justify-content: center; align-items: center;">
                        <div style="font-size: 22px;">{{wineallcount2}}</div>
                        <div>分享领取</div>
                    </div>
                </div>
                <div style="margin-top: 5px; padding-top: 5px; border-top: 1px solid #fff;width: 100%; text-align: center;">累计取数</div>
            </div>
        </div>
        <div style="margin-top: 20px;">品鉴酒核销</div>
        <div style="width: 100%;display: flex; flex-direction: row; margin-top: 10px;">
            <div style="width: 25%;height: 80px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{wineordercount}}</div>
                <div >日核销</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{wine7ordercount}}</div>
                <div >周核销</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{wine30ordercount}}</div>
                <div >月核销</div>
            </div>
            <div style="width: 25%;height: 80px; margin-left: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: darkgreen;color: #fff; border-radius: 10px;">
                <div style="font-size: 24px;">{{allwineordercount}}</div>
                <div>累计核销</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import '../../../static/css/my.css'
    import Utils from '../../util/utils'
    import { Dialog } from 'vant';
    // 全局注册
    Vue.use(Dialog);
    Vue.prototype.$http = axios
    var that
    export default {
        name: "AdminData",
        data () {
            return {
                dayorderprice: 0,
                day7orderprice: 0,
                day30orderprice: 0,
                allorderprice: 0,
                membercount: 0, // 会员总数
                daymemberaddcount: 0, // 当日新增
                day7memberaddcount: 0,
                day30memberaddcount: 0,
                wineordercount: 0,
                wine7ordercount: 0,
                wine30ordercount: 0,
                allwineordercount: 0,
                daylqaddcount: 0,
                day7lqaddcount: 0,
                day30lqaddcount: 0,
                winecount: 0,
                wine7count: 0,
                wine30count: 0,
                wineallcount: 0,
                winecount1: 0,
                wine7count1: 0,
                wine30count1: 0,
                wineallcount1: 0,
                winecount2: 0,
                wine7count2: 0,
                wine30count2: 0,
                wineallcount2: 0,
                userlqcount: 0
            }
        },
        mounted() {
            that = this
            that.queryorderbyday()
            that.queryorderbyday7()
            that.queryorderbyday30()
            that.queryallorder()
            that.querymemberbycount()
            that.querymemberbyday()
            that.querymemberbyday7()
            that.querymemberbyday30()
            that.querywinewsdaytj()
            that.querywinewstj7()
            that.querywinewstj30()
            that.querywinewstj()
            that.querywinewsdaytj1()
            that.querywinewstj71()
            that.querywinewstj301()
            that.querywinewstj1()
            that.querywinewsdaytj2()
            that.querywinewstj72()
            that.querywinewstj302()
            that.querywinewstj2()
            that.querywineorderdaytj()
            that.querywineordertj7()
            that.querywineordertj30()
            that.querywineordertj()
        },
        methods: {
            queryorderbyday () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " op_time > '" + curdata + " 00:00:00' and op_time < '" + curdata + " 23:59:59' and state_id > 1 and state_id !=9"
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' CAST(SUM(count_price) AS SIGNED) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log('日销售',res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].count !== null) {
                                that.dayorderprice = res.data[0].count
                            } else {
                                that.dayorderprice = 0
                            }
                        } else {
                            that.dayordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryorderbyday7 () {
                var qstr = ' op_time > DATE_SUB(CURDATE(), INTERVAL 7 DAY) and state_id >=2 and state_id !=9'
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' CAST(SUM(count_price) AS SIGNED) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' '
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].count !== null) {
                                that.day7orderprice = res.data[0].count
                            } else {
                                that.day7orderprice = 0
                            }
                        } else {
                            that.day7orderprice = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryorderbyday30 () {
                var qstr = ' op_time > DATE_SUB(CURDATE(), INTERVAL 7 DAY) and state_id >=2 and state_id !=9'
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' CAST(SUM(count_price) AS SIGNED) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' '
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].count !== null) {
                                that.day30orderprice = res.data[0].count
                            } else {
                                that.day30orderprice = 0
                            }
                        } else {
                            that.day30orderprice = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryallorder () {
                var qstr = ' state_id >=2 and state_id !=9'
                that.$http({
                    url: 'https://api.ihxx.com.cn/queryorderextj',
                    method: 'post',
                    data: {
                        fields: ' CAST(SUM(count_price) AS SIGNED) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' '
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].count !== null) {
                                that.allorderprice = res.data[0].count
                            } else {
                                that.allorderprice = 0
                            }
                        } else {
                            that.allorderprice = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywineorderdaytj () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " adddate > '" + curdata + " 00:00:00' and adddate < '" + curdata + " 23:59:59'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywineordertj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].count !== null) {
                                that.wineordercount = res.data[0].count
                            } else {
                                that.wineordercount = 0
                            }
                        } else {
                            that.wineordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywineordertj7 () {
                var qstr = " adddate > DATE_SUB(CURDATE(), INTERVAL 7 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywineordertj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine7ordercount = res.data[0].count
                        } else {
                            that.wine7ordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywineordertj30 () {
                var qstr = " adddate > DATE_SUB(CURDATE(), INTERVAL 30 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywineordertj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine30ordercount = res.data[0].count
                        } else {
                            that.wine30ordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywineordertj () {
                var qstr = " shopid > 0"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywineordertj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.allwineordercount = res.data[0].count
                        } else {
                            that.allwineordercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querymemberbycount () {
                var qstr = ' LENGTH(openid) = 28'
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywxcount',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by adddate desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.membercount = res.data[0].count
                        } else {
                            that.membercount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querymemberbyday () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " LENGTH(openid) = 28 and adddate > '" + curdata + " 00:00:00' and adddate < '" + curdata + " 23:59:59'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywxcount',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by adddate desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.daymemberaddcount = res.data[0].count
                        } else {
                            that.daymemberaddcount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querymemberbyday7 () {
                var qstr = ' LENGTH(openid) = 28 and adddate > DATE_SUB(CURDATE(), INTERVAL 7 DAY)'
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywxcount',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by adddate desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.day7memberaddcount = res.data[0].count
                        } else {
                            that.day7memberaddcount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querymemberbyday30 () {
                var qstr = ' LENGTH(openid) = 28 and adddate > DATE_SUB(CURDATE(), INTERVAL 30 DAY)'
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywxcount',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as count',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by adddate desc'
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.day30memberaddcount = res.data[0].count
                        } else {
                            that.day30memberaddcount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewsdaytj () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " fromopenid = 'taskshare' and adddate > '" + curdata + " 00:00:00' and adddate < '" + curdata + " 23:59:59'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].winecount !== null) {
                                that.winecount = res.data[0].winecount
                            } else {
                                that.winecount = 0
                            }
                        } else {
                            that.winecount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj7 () {
                var qstr = " fromopenid = 'taskshare' and adddate > DATE_SUB(CURDATE(), INTERVAL 7 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine7count = res.data[0].winecount
                        } else {
                            that.wine7count = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj30 () {
                var qstr = " fromopenid = 'taskshare' and adddate > DATE_SUB(CURDATE(), INTERVAL 30 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine30count = res.data[0].winecount
                        } else {
                            that.wine30count = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj () {
                var qstr = " fromopenid = 'taskshare'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wineallcount = res.data[0].winecount
                        } else {
                            that.wineallcount = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewsdaytj1 () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " fromopenid = 'platform' and adddate > '" + curdata + " 00:00:00' and adddate < '" + curdata + " 23:59:59'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].winecount !== null) {
                                that.winecount1 = res.data[0].winecount
                            } else {
                                that.winecount1 = 0
                            }
                        } else {
                            that.winecount1 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj71 () {
                var qstr = " fromopenid = 'platform' and adddate > DATE_SUB(CURDATE(), INTERVAL 7 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine7count1 = res.data[0].winecount
                        } else {
                            that.wine7count1 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj301 () {
                var qstr = " fromopenid = 'platform' and adddate > DATE_SUB(CURDATE(), INTERVAL 30 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine30count1 = res.data[0].winecount
                        } else {
                            that.wine30count1 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj1 () {
                var qstr = " fromopenid = 'platform'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wineallcount1 = res.data[0].winecount
                        } else {
                            that.wineallcount1 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewsdaytj2 () {
                var curdata = Utils.dateFormat(new Date(), 'yyyy-MM-dd')
                var qstr = " fromopenid != 'taskshare' and fromopenid != 'platform' and adddate > '" + curdata + " 00:00:00' and adddate < '" + curdata + " 23:59:59'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            if (res.data[0].winecount !== null) {
                                that.winecount2 = res.data[0].winecount
                            } else {
                                that.winecount2 = 0
                            }
                        } else {
                            that.winecount2 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj72 () {
                var qstr = " fromopenid != 'taskshare' and fromopenid != 'platform' and adddate > DATE_SUB(CURDATE(), INTERVAL 7 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine7count2 = res.data[0].winecount
                        } else {
                            that.wine7count2 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj302 () {
                var qstr = " fromopenid != 'taskshare' and fromopenid != 'platform' and adddate > DATE_SUB(CURDATE(), INTERVAL 30 DAY)"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wine30count2 = res.data[0].winecount
                        } else {
                            that.wine30count2 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querywinewstj2 () {
                var qstr = " fromopenid != 'taskshare' and fromopenid != 'platform'"
                that.$http({
                    url: 'https://api.ihxx.com.cn/querywinewstj',
                    method: 'post',
                    data: {
                        fromparms: ' count(*) as winecount',
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.wineallcount2 = res.data[0].winecount
                        } else {
                            that.wineallcount2 = 0
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>
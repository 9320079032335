<template>
    <div>
        <!--pages/course/course.wxml-->
        <import src="../wxParse/wxParse.wxml"/>
        <div class="container">
            <van-button plain type="primary" @click="pay" @touchstart="paystart">朴素按钮</van-button>
            <!--
            <div class="slipinfo"></div>
                <div class="swipercontainer" >
                    <div v-if="ifshowzj">
                        <div v-if="cur_charpter.ctype.Int64 === 2">
                            <video class="slide-video" :id='myVideo' :src="cur_charpter.curl.String" :autoplay="autoplay" controls event-model="bubble">
                            </video>
                        </div>
                        <div v-else>
                            <div class="audio">
                                <img class="bg" :src="cur_charpter.zimgurl.String" />
                                <img  class="poster" :src="cur_charpter.zimgurl.String" />
                                <div class="control-process">
                                    <text class="current-process">{{current_process}}</text>
                                    <slider class="slider"
                                            bindchange="hanle_slider_change"
                                            bindtouchstart="handle_slider_move_start"
                                            bindtouchend="handle_slider_move_end"
                                            min="0"
                                            div-size="16"
                                            :max="slider_max"
                                            activeColor="#ccc"
                                            backgroundColor="#ccc"
                                            :value="slider_value"
                                    />
                                    <text class="total-process">{{total_process}}</text>
                                </div>
                                <div class="icon-list ">
                                    <img bindtap="prev" mode="widthFix" :src="privateimg" class="audio-icon" />
                                    <img bindtap="back" mode="widthFix" :src="backimg" class="audio-icon img1" />
                                    <div v-if="!isplaying">
                                        <img mode="widthFix" :src="playimg" class="audio-icon audio-play" bindtap="audio_play" />
                                    </div>
                                    <div v-else>
                                        <img mode="widthFix" :src="pauseimg" class="audio-icon audio-play" bindtap="audio_pause" />
                                    </div>
                                    <img bindtap="forword" mode="widthFix" :src="forwordimg" class="audio-icon img1" />
                                    <img bindtap="next" mode="widthFix" :src="nextimg" class="audio-icon" />
                                    <img hidden mode="widthFix" class="pattern" :src="pauseimg" bindtap="play_loop" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <img :src="course_basedata[0].cimgurl.String" class="slide-img"/>
                    </div>
                </div>
                <div class='splitdiv'></div>
                <div class='basediv'>
                    <div class='coursenamediv' >
                        <div class='coursename'>  {{course_basedata[0].ctitle.String}} </div>
                    </div>
                    <div class='courseprice' >
                        <div class='defalutpricetxt'> 当前章节：  {{cur_charpter.ztitle.String}} </div>
                        <div class='defalutpricetxt1' bindtap="showteacher"> 讲师：  {{teachername}}  </div>
                    </div>
                </div>

                <div class="slipinfo2"></div>

                <div>

                </div>
               -->
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import { Button } from 'vant'
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Button)
    var that
    export default {
        name: "CourseInfo",
        data () {
            return {
                showsharemodel: true, /// 默认不显示分享窗口
                navbar: ['课程详情', '课程章节', '课程评价'], /// 选项卡
                currentTab: 0, /// 默认商品详情
                course_basedata: [], /// 课程基本信息
                course_des: [], ///课程描述
                course_charpter: [], ///课程章节
                course_evaluate: [], ///课程评论
                ifupimg: false,
                ifupvideo: false,
                ev_imgs: [],
                ev_movies: [],
                ifshowzj: false,
                cur_charpter: '',
                autoplay: false,
                videoCtx: '',
                controls: true,
                isplaying: false,
                teachername: '',
                playimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/%E6%92%AD%E6%94%BE.png',
                nextimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/%E4%B8%8B%E4%B8%80%E9%A6%96.png',
                privateimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/%E4%B8%8A%E4%B8%80%E9%A6%96.png',
                pauseimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/%E6%9A%82%E5%81%9C.png',
                backimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/pre.png',
                forwordimg: 'https://sqdfsd.oss-cn-beijing.aliyuncs.com/dfsd/course/next.png',
                starttime: '00:00', //正在播放时长
                current_process: '',
                total_process: '',
                is_moving_slider: false,
                charpterindex: 0,
                cimgurl:''
            }
        },
        mounted: function () {
            window.console.log(that)
            window.console.log(localStorage.getItem('curcourseid'))
        },
        methods: {
            paystart: function () {
                
            },
            pay: function () {
            },
            getcourseinfo () {

            }
        }
    }
</script>

<style scoped>

</style>
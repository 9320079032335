<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        label="手机号"
                        placeholder="手机号"
                        @blur="checkuser()"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
                <van-field
                        v-model="password"
                        type="password"
                        name="密码"
                        label="密码"
                        placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
                <van-field
                        v-model="repassword"
                        type="password"
                        name="重复密码"
                        label="重复密码"
                        placeholder="重复密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
            </van-form>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="regmember">
                立即注册
            </van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import wx from 'weixin-js-sdk'
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(CountDown);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    var wxinfo = wx
    export default {
        name: "RegMember",
        data () {
            return {
                phone: '',
                sms: '',
                password: '',
                repassword: '',
                userimg: '',
                usernick: '',
                ifreg: '',
                btntxt: '发送验证码',
                yzmabled: false
            }
        },
        mounted() {
            document.title = '个人注册'
            that = this
            window.console.log(that)

            if (getCookie('accesstoken') === null || getCookie('accesstoken') === '') {
                that.$options.methods.getaccesstoken()
            } else {
                if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                    window.console.log('openID为空')
                    that.$options.methods.getaccesstoken()
                } else {
                    if (localStorage.getItem('userinfo') === null || localStorage.getItem('userinfo') === '') {
                        window.console.log('用户信息为空')
                        that.$options.methods.getuserinfo()
                    } else {
                        window.console.log('用户信息存在')
                        var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                        that.userimg = tmpdata.headimgurl
                        that.usernick = tmpdata.nickname
                    }
                }
                that.$options.methods.configwx()
            }
        },
        methods: {
            configwx: function () {
                // 获取微信签名
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getwxjsparams',
                    method: 'post',
                    data: {
                        shareurl: 'https://ecomm.sqnetsoft.cn/ecomm/',
                        accesstoken: getCookie('accesstoken')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sh1signature
                            // var timestamp = Date.parse(new Date()).toString()
                            // timestamp = timestamp.substr(0,10)
                            var noncestr1 = randomWord(false, 30)
                            window.console.log(noncestr1)
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.nonce
                            window.console.log(wxinfo)
                            wxinfo.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    "onMenuShareTimeline",//分享朋友圈接口
                                    "onMenuShareAppMessage",
                                    'chooseWXPay'//分享给朋友接口
                                ]
                            })
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getuserinfo: function () {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getuserinfo',
                    method: 'post',
                    data: {
                        accesstoken: getCookie('accesstoken'),
                        openid: localStorage.getItem('openid')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata) {
                                localStorage.setItem('userinfo',res.data.data)
                                that.userimg = tmpdata.headimgurl
                                that.usernick = tmpdata.nickname
                            } else {
                                localStorage.setItem('userinfo','')
                            }
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getaccesstoken: function () {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            setCookie('accesstoken',res.data.data,7200)
                            const URL = window.location.href.replace('#/','')
                            var params = parse_url(URL)
                            if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                                if (params['code'] !== '' && params['code'] !== undefined) {
                                    // 如果有code参数，才会执行获取，如果没有则不需要处理
                                    that.$options.methods.getopenid(params)
                                } else {
                                    if (getCookie('refreshtoken') === '' || getCookie('refreshtoken') === null) {
                                        // 本地调试 暂时注销
                                        window.location.href = 'https://api.sqnetsoft.cn/wx/getopcode?url=myecommreg'
                                        // that.$options.methods.getbrand()
                                        // that.$options.methods.gettjtypes()
                                        // that.$options.methods.getkjrk()
                                        // that.$options.methods.gettjgoods()

                                    } else {
                                        that.$options.methods.reloadaccesstoken(getCookie('refreshtoken'))
                                        // that.$options.methods.getbrand()
                                        // that.$options.methods.gettjtypes()
                                        // that.$options.methods.getkjrk()
                                        // that.$options.methods.gettjgoods()
                                    }
                                }
                            } else {
                                // that.$options.methods.getbrand()
                                // that.$options.methods.gettjtypes()
                                // that.$options.methods.getkjrk()
                                // that.$options.methods.gettjgoods()
                                that.$options.methods.getuserinfo()
                                that.$options.methods.configwx()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            reloadaccesstoken: function (refreshtoken) {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/reloadaccesstoken',
                    method: 'post',
                    data: {
                        refreshtoken: refreshtoken
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                /*
                                that.$options.methods.getbrand()
                                that.$options.methods.gettjtypes()
                                that.$options.methods.getkjrk()
                                that.$options.methods.gettjgoods()
                                 */
                                that.$options.methods.getuserinfo()
                                that.$options.methods.configwx()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getopenid: function (params) {
                window.console.log(params['code'])
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getgzhopenidex',
                    method: 'post',
                    data: {
                        code: params['code']
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                /*
                                that.$options.methods.getbrand()
                                that.$options.methods.gettjtypes()
                                that.$options.methods.getkjrk()
                                that.$options.methods.gettjgoods()
                                 */
                                that.$options.methods.getuserinfo()
                                that.$options.methods.configwx()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            checkuser () {
                var qstr = 'username = ' + this.phone
                that.$http({
                    url: 'https://api.sqnetsoft.cn/account/getaccountlist',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log(res)
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.id.Int64 === 0) {
                                window.console.log('获取用户失败')
                                Notify({ type: 'success', message: '用户名可注册' });
                                localStorage.setItem('accountid',0)
                                localStorage.setItem('username','')
                            } else {
                                Notify({ type: 'warning', message: '用户名已存在，请重新填写' });
                                that.ifreg = true
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取用户失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                this.ifreg = false
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        vphone: this.phone,
                        issend: parseInt(0)
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/getsms',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.data
                                that.sms = res.data.data
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            regmember () {
                // 获取注册信息，进行第一步注册，保存对应的账号ID，如果有的情况
                if (!this.ifreg) {
                    var adata = {
                        vcusid: parseInt(localStorage.getItem('cusid')),
                        vname: that.usernick,
                        vimg: that.userimg,
                        vphone: that.phone,
                        openid: localStorage.getItem('openid')
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/regedit',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                localStorage.setItem('accountid',res.data.data)
                                localStorage.setItem('username',that.phone)
                                that.$options.methods.addtomember(res.data.data)
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                }
            },
            addtomember (userid) {
                var rmdStr = that.RondomPass(10)
                var adddata = {
                    userid: parseInt(userid),
                    buycount: parseInt(0),
                    levelid: parseInt(1),
                    cardid: parseInt(1),
                    cardno: { String: rmdStr, Valid: true },
                    cusid: parseInt(localStorage.getItem('cusid'))
                }
                that.$http({
                    url: 'https://api.sqnetsoft.cn/cardticket/aaubmc',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (localStorage.getItem('inpath') === 'My') {
                                that.$router.push({ path: '/My' })
                            } else {
                                that.$router.push({ path: 'GoodsInfo' })
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            RondomPass: function (number) {
                var arr = []
                var arr1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

                for(var i = 0; i<number; i++) {
                    var n = Math.floor(Math.random() * 10)
                    arr[i] = arr1[n]
                }
                var valinfo = arr.join('')
                return valinfo
            }
        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
    function setCookie (name, value, seconds) {
        var d = new Date()
        d.setTime(d.getTime() + 1000 * seconds)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function randomWord (randomFlag, min, max) {
        var str = ''
        var range = min
        var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        // 随机产生
        if (randomFlag) {
            range = Math.round(Math.random() * (max - min)) + min
        }
        for (var i = 0; i < range; i++) {
            var pos = Math.round(Math.random() * (arr.length - 1))
            str += arr[pos]
        }
        return str
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
    router
}).$mount('#app')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '祺聚优品'
  }
  next()
})

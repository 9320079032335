<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                常用功能
            </div>
            <van-cell-group>
                <van-cell is-link @touchend="showscan" @click="showscan" icon="scan">扫码入库</van-cell>
                <van-cell is-link @touchend="showkw" @click="showkw" icon="location-o">立体库位</van-cell>
                <van-cell is-link @touchend="makecode" @click="makecode" icon="qr">动态二维码</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                ERP互动
            </div>
            <van-cell-group>
                <van-cell is-link @touchend="importexcel" @click="importexcel" icon="upgrade">数据导入</van-cell>
                <van-cell is-link @touchend="filedownload" @click="filedownload" icon="down">模板配置</van-cell>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                物流对接
            </div>
            <van-cell-group>
                <van-cell is-link @touchend="pay" @click="pay" icon="after-sale">物流查询</van-cell>
                <van-cell is-link @touchend="pay" @click="pay" icon="after-sale">物流配置</van-cell>
                <!--
                <van-cell is-link @touchend="refund" @click="refund" icon="cash-back-record">退款</van-cell>
                -->
            </van-cell-group>
        </div>
        <van-dialog v-model="showrk" title="扫码入库" show-cancel-button>
            <van-form>
                <van-field
                        v-model="rkno"
                        label="入库单号"
                        right-icon="scan"
                        @click-right-icon="scanrkno"
                        placeholder="请扫描或手填入库单号"
                />
                <van-field
                        v-model="gcodetmp"
                        name="产品编码"
                        label="产品编码"
                        placeholder="产品编码"
                        :rules="[{ required: true, message: '请手动填入' }]"
                />
                <van-field
                        v-model="gnametmp"
                        name="品名"
                        label="品名"
                        placeholder="产品的名称"
                />
                <van-field
                        v-model="kwcode"
                        label="库位码"
                        right-icon="scan"
                        @click-right-icon="showscancode"
                        placeholder="请扫描或手填库位码"
                />

                <van-field name="switch" label="是否拆分">
                    <template #input>
                        <van-switch v-model="ifcf" size="20" />
                    </template>
                </van-field>
                <van-field
                        v-model="storecount"
                        name="存放数量"
                        label="存放数量"
                        placeholder="请填入存放数量"
                        :rules="[{ required: true, message: '数量必须填写' }]"
                />
            </van-form>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { NavBar } from 'vant';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { Panel } from 'vant';
    import { Cell, CellGroup } from 'vant';
    import { Image } from 'vant';
    import { Notify } from 'vant';
    import { Switch } from 'vant';

    import wx from 'weixin-js-sdk'
    Vue.use(Switch);
    Vue.use(Notify);
    Vue.prototype.$http = axios
    Vue.use(Image);
    Vue.use(Cell);
    Vue.use(CellGroup);
    Vue.use(Panel);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    Vue.use(NavBar);
    var that
    var wxinfo = wx

    export default {
        name: "Example",
        data () {
            return {
                imgurl: '',
                bartitle: '功能演示',
                showrk: false,
                ifcf: false,
                rkno: '',
                gcodetmp: '',
                kwcode: '',
                showcontext: false,
                gnametmp: '',
                storecount: 0,
            }},
        mounted () {
            that = this
            document.title = '物流运输'
            if (getCookie('accesstoken') === null || getCookie('accesstoken') === '') {
                that.$options.methods.getaccesstoken()
            } else {
                that.$options.methods.configwx()
            }
        },
        methods: {
            getcode (str) {
                that.gcodetmp = str
                var qstr = {
                    lsbn: str
                }
                that.$http({
                    url: 'https://ws.sqnetsoft.cn/apis/getgoodsbar',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        that.gnametmp = res.data.gname
                        that.showrk = true
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            configwx: function () {
                // 获取微信签名
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getwxjsparams',
                    method: 'post',
                    data: {
                        shareurl: 'https://ecomm.sqnetsoft.cn/ecomm/',
                        accesstoken: getCookie('accesstoken')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sh1signature
                            // var timestamp = Date.parse(new Date()).toString()
                            // timestamp = timestamp.substr(0,10)
                            var noncestr1 = randomWord(false, 30)
                            window.console.log(noncestr1)
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.nonce
                            window.console.log(wxinfo)
                            wxinfo.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    "scanQRCode",
                                    'chooseImage',
                                    'uploadImage',
                                    'getLocalImgData',
                                    'downloadImage',
                                    'onMenuShareAppMessage',
                                    'onMenuShareTimeline'
                                ]
                            })
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getaccesstoken: function () {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            setCookie('accesstoken',res.data.data,7200)
                            that.$options.methods.configwx()
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            returnyw () {
                this.$router.push({path: '/'})
            },
            showscancode () {
                wx.scanQRCode({
                    needResult : 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType : [ "qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success : function(res) {
                        var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        var strs= new Array(); // 定义一数组
                        strs = result.split(",");
                        if (strs.length > 1) {
                            that.kwcode = strs[1]
                        } else {
                            that.kwcode = result
                        }
                    }
                });
            },
            showscan () {
                wx.scanQRCode({
                    needResult : 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType : [ "qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success : function(res) {
                        var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        var strs= new Array(); // 定义一数组
                        strs = result.split(",");
                        if (strs.length > 1) {
                            that.$options.methods.getcode(strs[1])
                        } else {
                            that.$options.methods.getcode(result)
                        }
                    }
                });
            },
            scanrkno () {
                wx.scanQRCode({
                    needResult : 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType : [ "qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success : function(res) {
                        var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        var strs= new Array(); // 定义一数组
                        strs = result.split(",");
                        if (strs.length > 1) {
                            that.rkno = strs[1]
                        } else {
                            that.rkno = result
                        }
                    }
                });
            },
            makecode () {
                this.$router.push({path: '/MakeCode'})
            },
            showkw () {
                this.$router.push({path: '/LocalStore'})
            },
            importexcel () {
                this.$router.push({path: '/ImportExcel'})
            },
            filedownload () {
                this.$router.push({path: '/FileDownload'})
            },
            pay () {
                this.$router.push({path: '/Pay'})
            },
            refund () {
                this.$router.push({path: '/Refund'})
            },
            sharepyq () {
                this.$router.push({path: '/Share'})
            },
            showvod () {
                this.$router.push({path: '/Vod'})
            }
        }
    }
    function setCookie (name, value, seconds) {
        var d = new Date()
        d.setTime(d.getTime() + 1000 * seconds)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function randomWord (randomFlag, min, max) {
        var str = ''
        var range = min
        var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        // 随机产生
        if (randomFlag) {
            range = Math.round(Math.random() * (max - min)) + min
        }
        for (var i = 0; i < range; i++) {
            var pos = Math.round(Math.random() * (arr.length - 1))
            str += arr[pos]
        }
        return str
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .barcodeshow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }
</style>

<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                title="N1库"
                @click-left="returnyw">
            <template #right>
                <van-icon name="search" size="18" />
            </template>
        </van-nav-bar>
        <div class="van-doc-demo-block__title" >
            N11(区域)
        </div>
        <div class="van-doc-demo-block" style="padding-bottom: 5px; background-color: #fff;">
            <van-cell-group>
                <van-grid :column-num="15" >
                    <van-grid-item  v-for="(value,index) in n1data" :badge="value.Count" @click="showkw(value.Code,value.Count)"  :key="index"  icon="newspaper-o" :text="value.Code" >
                    </van-grid-item>
                </van-grid>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block__title" >
            N12(区域)
        </div>
        <div class="van-doc-demo-block" style="padding-bottom: 5px; background-color: #fff;">
            <van-cell-group>
                <van-grid :column-num="20">
                    <van-grid-item v-for="value in 20" :key="value" icon="newspaper-o" :text="'N12' + value " />
                </van-grid>
            </van-cell-group>
        </div>
        <van-dialog v-model="subkwshow" :title="kwname" show-cancel-button>
            <van-tabs v-model="active">
                <van-tab title="第一层">
                    <div class="van-doc-demo-block__title" >
                        商品清单
                    </div>
                    <div class="van-doc-demo-block" style="padding-bottom: 5px; background-color: #fff;">
                        <van-cell-group>
                            <van-grid :column-num="1">
                                <van-grid-item  text="音乐 一年级下册（简谱）外省循环皖价（2020春）
" />
                            </van-grid>
                        </van-cell-group>
                        <van-cell-group>
                            <van-grid :column-num="3">
                                <van-grid-item  text="数量" />
                                <van-grid-item  text="入库日期" />
                                <van-grid-item  text="报废日期" />
                            </van-grid>
                        </van-cell-group>
                        <van-cell-group>
                            <van-grid :column-num="3">
                                <van-grid-item  text="1200" />
                                <van-grid-item  text="2020-02-19" />
                                <van-grid-item  text="2023-02-19" />
                            </van-grid>
                        </van-cell-group>
                    </div>
                    <div class="van-doc-demo-block" style="padding-bottom: 5px; background-color: #fff;">
                        <van-cell-group>
                            <van-grid :column-num="1">
                                <van-grid-item  text="义务教育教科书 音乐 一年级下册 学生用CD（2020春）" />
                            </van-grid>
                        </van-cell-group>
                        <van-cell-group>
                            <van-grid :column-num="3">
                                <van-grid-item  text="数量" />
                                <van-grid-item  text="入库日期" />
                                <van-grid-item  text="报废日期" />
                            </van-grid>
                        </van-cell-group>
                        <van-cell-group>
                            <van-grid :column-num="3">
                                <van-grid-item  text="3000" />
                                <van-grid-item  text="2020-03-12" />
                                <van-grid-item  text="2023-03-12" />
                            </van-grid>
                        </van-cell-group>
                    </div>
                </van-tab>
                <van-tab title="第二层"></van-tab>
                <van-tab title="第三层"></van-tab>
                <van-tab title="第三层"></van-tab>
            </van-tabs>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Progress } from 'vant'
    import { Notify } from 'vant';
    import { Grid, GridItem } from 'vant';
    import { Dialog } from 'vant';
    Vue.use(Dialog);
    Vue.use(Grid);
    Vue.use(GridItem);
    Vue.use(Progress);
    Vue.use(Notify);
    var that
    export default {
        name: "LocalStore",
        data () {
            return {
                subkwshow: false,
                kwname: '',
                n1data: [
                    {
                        Code:'N11291',
                        Count: '满'
                    },
                    {
                        Code:'N11292',
                        Count: 1021
                    },
                    {
                        Code:'N11293',
                        Count: 399
                    }
                ]
            }
        },
        mounted () {
            that = this
            window.console.log(that)
        },
        methods: {
            returnyw() {
                this.$router.push({path: '/WorkSpace'})
            },
            showkw(code,count){
                window.console.log(count)
                this.subkwshow = true
                this.kwname = code
            }
        }
    }
</script>

<style scoped>

</style>
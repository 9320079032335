<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                您的当前位置
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            v-model="longitude"
                            name="经度"
                            label="经度"
                            clearable
                            placeholder="通常是链接地址或任意字符"
                    />
                    <van-field
                            v-model="latitude"
                            name="纬度"
                            label="纬度"
                            clearable
                            placeholder="通常是链接地址或任意字符"
                    />
                </van-form>
            </van-cell-group>
        </div>
        <el-amap ref="map" :zoom="zoom" vid="amapDemo" :center="center"  class="amap-demo">
            <el-amap-marker :position="center"></el-amap-marker>
        </el-amap>
    </div>
</template>

<script>
    import Vue from 'vue'
    import wx from 'weixin-js-sdk'
    import AMap from 'vue-amap';
    Vue.use(AMap);
    AMap.initAMapApiLoader({
        // 高德的key
        key: '8db2a8edf656d8b2a6b5496fd437502d',
        // 插件集合 （插件按需引入）
        plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor']
    });
    var that
    export default {
        name: "GPS",
        data: function() {
            return {
                center: [121.5273285, 31.21515044],
                zoom: 12,
                latitude: '',
                longitude: ''
            }
        },
        mounted() {
            that = this
            wx.getLocation({
                success: function(res) {
                    window.console.log(res)
                    if (res.errMsg === 'getLocation:ok') {
                        that.latitude = res.latitude
                        that.longitude = res.longitude
                        that.center = [that.longitude,that.latitude]
                    }
                },
                cancel: function(res) {
                    window.console(res);
                }
            });
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/Example'})
            }
        }
    }
</script>

<style scoped>
    .amap-demo {
        margin: 10px;
        height: 300px;
    }
</style>
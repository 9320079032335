<template>
    <div style="margin-bottom: 60px;">
        <van-nav-bar
                :title="zttitle"
                left-text="返回"
                left-arrow
                @click-left="returnyw">
        </van-nav-bar>
        <van-grid column-num="2">
            <van-grid-item
                    v-for="(curgoods) in allgoodsdata"
                    :key="curgoods.id.Int64"
                    :text="curgoods.name.String"
                    @touchstart="showgoods(curgoods.id.Int64)" @click="showgoods(curgoods.id.Int64)"
            >
                <van-image style="margin:10px; width: 128px;height: 128px;" :src="curgoods.burl.String" />
                <div style="font-size:14px; margin:10px;">{{curgoods.name.String}}</div>
                <div style="font-size:14px; margin:10px;">{{curgoods.baseprice.String}}</div>
            </van-grid-item>
        </van-grid>
    </div>
</template>

<script>
    var that
    export default {
        name: "AllGoods",
        data () {
            return {
                allgoodsdata: [],
                zttitle: ''
            }
        },
        mounted() {
            document.title = "祺聚优品"
            that = this
            that.$options.methods.showallgoods()
            that.zttitle = localStorage.getItem('zttitle')
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/'})
            },
            showallgoods() {
                var qstr = 'cusid = ' + localStorage.getItem('cusid') + ' and find_in_set(' + localStorage.getItem('curztgtype') + ', goods_type_id)'
                 that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qginfo',
                    method: 'post',
                    data: {
                        whereinfo: { String: qstr, Valid: true }
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.allgoodsdata = JSON.parse(res.data.data)
                            window.console.log(that.allgoodsdata)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showgoods: function(goodsid) {
                localStorage.setItem('curgoodsid', goodsid)
                localStorage.setItem('curztname', goodsid)
                this.$router.push('/GoodsInfo')
            }
        }
    }
</script>

<style scoped>

</style>
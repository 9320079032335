<template>
    <div style="width:100%;margin-top: 10px; background-color: #fff;">
        <div style="display: flex; width:100%; flex-flow: column;" v-for="(item, index) in dqrdata" :key="index">
            <van-cell style="font-size:12px" :title="'订单号：' + item.order_no" />
            <van-card
                    style="flex:1;margin-left: 10px;margin-right: 10px;"
                    v-for="(subitem, index) in item.suborder" :key="index"
                    :num="subitem.count"
                    :price="subitem.price"
                    desc="描述信息"
                    :title="subitem.name"
                    :thumb="subitem.imgurl"
            >
                <template #tags>
                    <van-tag plain type="danger">{{subitem.specinfo}}</van-tag>
                </template>
            </van-card>
            <van-cell title="">
                <template #right-icon>
                    <!--
                    <van-button v-if="item.typeid === 9" size="small" @touchstart="lookkm(item.order_no)">查看卡密</van-button>
                       -->
                    <van-button size="small" @touchstart="lookwl(item.order_no)">查看物流</van-button>
                    <van-button size="small" @touchstart="qrsh(item.order_no)" style="margin-left: 5px;">确认收货</van-button>
                </template>
            </van-cell>
            <van-dialog v-model="kmshow" title="卡密信息" show-cancel-button>
                <div style="margin: 20px;">
                    <div class="van-multi-ellipsis--l2">
                        卡号：{{tcardno}}
                    </div>
                    <div class="van-multi-ellipsis--l2" style="margin-top: 10px;">
                        密码：{{tcardpwd}}
                    </div>
                </div>
            </van-dialog>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Dialog } from 'vant';
    import { Notify } from 'vant';
    Vue.use(Dialog);
    var that
    export default {
        name: "DQR",
        data () {
            return {
                dqrdata: [],
                dqrcount: 0,
                tcardtype: 0,
                kmshow: false,
                pstimes: 0,
                tcardno: '',
                tcardpwd: ''
            }
        },
        mounted() {
            that = this
            document.title = '待确认订单'
            if (localStorage.getItem('dqrorder')) {
                this.dqrdata =  JSON.parse(localStorage.getItem('dqrorder') || '[]')
                this.dqrcount = this.dqrdata.length
            } else {
                that.$options.methods.getdqrorder()
            }
        },
        methods: {
            lookkm (orderno) {
                // 根据订单查询该订单类别
                var qstr = "orderno = '" + orderno + "'"
                window.console.log(qstr)
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/getbotc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        limit: {String: 'LIMIT 1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('获取卡的相关信息')
                                window.console.log(tmpdata)
                                that.tcardtype = tmpdata[0].tcardtype
                                that.pstimes = tmpdata[0].pstimes
                                that.tcardno = tmpdata[0].tcardno
                                that.tcardpwd = tmpdata[0].tcardpwd
                                if (that.tcardno === '') {
                                    window.console.log('没有获取绑定号码')
                                    that.$options.methods.fptcard(that.tcardtype,orderno)
                                } else {
                                    window.console.log('获取本地号码')
                                    that.kmshow = true
                                    window.console.log(that.tcardno)
                                    window.console.log(that.tcardpwd)
                                }
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            fptcard (cardtype,orderno) {
                var qstr = 'cardtype = ' + cardtype + ' and state = 0'
                window.console.log(qstr)
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/getbtc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        limit: {String: 'LIMIT 1', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('获取可用卡号')
                                window.console.log(tmpdata)
                                that.tcardno = tmpdata[0].tcardno
                                that.tcardpwd = tmpdata[0].tcardpwd
                                window.console.log(that.tcardno)
                                window.console.log(that.tcardpwd)
                                that.$options.methods.uptcardinfo(that.tcardno,orderno)
                                that.kmshow = true
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            uptcardinfo (tcardno,orderno) {
                //需要做两件事，一是更新卡的状态，二是将卡号和订单号绑定
                // 先将卡号和订单绑定
                var qstr = "orderno ='" + orderno + "'"
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/upbotc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        fieldparams: {String: " tcardno='" + tcardno + "'", Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('已绑定订单和卡号')
                            // 更新卡的状态
                            var qstr = "tcardno ='" + tcardno + "'"
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/order/upbtc',
                                method: 'post',
                                data: {
                                    whereinfo: {String: qstr, Valid: true},
                                    fieldparams: {String: ' state=1', Valid: true}
                                },
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.success) {
                                        window.console.log('卡号状态更新完毕')
                                        // 执行写入订单操作
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log('error' + error)
                                })
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            lookwl (orderno) {
                localStorage.setItem('wlorder',orderno)
                this.$router.push({path: '/Logistics'})
            },
            qrsh (orderno) {
                /// 改变订单状态为 4
                Dialog.confirm({
                    title: '提示',
                    message: '对订单 ' + orderno + '进行确认收货吗?',
                })
                    .then(() => {
                        that.$http({
                            url: 'https://ecommapi.sqnetsoft.cn/uporderstate',
                            method: 'post',
                            data: {
                                order_no: orderno,
                                state_id: '4'
                            },
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                if (res.data.result === 'success') {
                                    Notify('已确认收货')
                                }
                            })
                            .catch(function (error) {
                                window.console.log('error' + error)
                            })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            getdqrorder () {
                var qstr2 = {
                    whereinfo: " buy_cus_id='" + localStorage.getItem('openid') + "' and state_id <= 3 and state_id > 1",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('订单信息')
                            that.dfkdata = tmpdata
                            that.dfkcount = tmpdata.length
                            window.console.log(that.dfkdata)
                            var orderarr = []
                            for (var i = 0; i < tmpdata.length; i++) {
                                var objtmp = tmpdata[i]
                                orderarr.push('"' + objtmp.order_no + '"')
                            }
                            var qstr3 = {
                                whereinfo: 'order_no in (' + orderarr + ')',
                                limit: '',
                                sortinfo: ''
                            }
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                method: 'post',
                                data: qstr3,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.length > 0) {
                                        var obj1 = res.data
                                        var dfktmp = that.dfkdata
                                        for (var j = 0; j < dfktmp.length; j++) {
                                            var dfkobj = dfktmp[j]
                                            var subordertmp = []
                                            for (var k = 0; k < obj1.length; k++) {
                                                if (dfkobj.order_no === obj1[k].order_no) {
                                                    window.console.log(obj1[k].order_no)
                                                    subordertmp.push(obj1[k])
                                                }
                                            }
                                            dfktmp[j].suborder = subordertmp
                                        }
                                        window.console.log(dfktmp)
                                        localStorage.setItem('dqrdata',JSON.stringify(dfktmp))
                                        that.dqrdata = JSON.parse(localStorage.getItem('dqrdata'))
                                    } else {
                                        window.console.log('获取商品失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <van-nav-bar
                title=""
                left-text="返回"
                left-arrow
                @click-left="returnyw">
        </van-nav-bar>
        <div>
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in goodsimglist" :key="index" style="display: flex;align-items: center;justify-content: center;">
                    <img class="goodsimg" v-lazy="image.imgurl.String" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div>
            <van-cell :title="goodsname">
                <van-icon class-prefix="iconfont icon-fenxiang redfontbc" name="extra" > 分享 </van-icon>
            </van-cell>
        </div>
        <div v-if="goodspricearea === ''" class='goodsprice'>
            <van-cell :title="'￥' + goodsbaseprice">
                <div class='memberpricetxt'> 会员价格：{{hyprice}} </div>
            </van-cell>
        </div>
        <div v-else class='goodsprice'>
            <van-cell :title="goodspricearea">
            </van-cell>
        </div>
        <div>
            <van-cell title="购买数量">
                <van-stepper v-model="gcount" />
            </van-cell>
        </div>
        <van-collapse v-model="activeNames" >
            <van-collapse-item :border="ifborder" title="规格信息" name="1">
                <div>
                    <van-button @touchstart="basespecchage(specitem.id)" class="specbtn" :type="specitem.ifcheck ? 'info' : 'default'" v-for="(specitem, index) in specarr" :key="index">{{specitem.gspecname}}</van-button>
                </div>
                <van-collapse v-model="subactivenames">
                    <van-collapse-item :border="ifborder" :name="item.attrid.String" :title="item.attrname.String" v-for="(item, index) in goodstattrdata" :key="index">
                        <div>
                            <van-button @touchstart="specchange(ggitem.attrgroupindex,ggitem.id)" class="specbtn" :type="ggitem.ifcheck ? 'info' : 'default'" v-for="(ggitem, index) in item.items" :key="index">{{ggitem.attrvalue}}</van-button>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-collapse-item>
        </van-collapse>
        <div>
            <van-collapse v-model="attractiveNames">
                <van-collapse-item title="属性信息" name="1">
                    <div v-for="(item, index) in goodsattrdata" :key="index">
                        <van-cell :title="item.attrname.String" :value="item.attrvalue" >
                        </van-cell>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
        <van-tabs v-model="active" style="margin-top:20px;margin-bottom: 100px;">
            <van-tab title="商品详情">
                <div class="desinfo" v-html='goodsdes'></div>
            </van-tab>
            <van-tab title="商品评价">
                <div v-for="(item, index) in goods_evaluate" :key="index" style="margin-top:5px;">
                    <div class="pjpanel">
                        <img class="userimg"
                             :src="item.userimg.String ? item.userimg.String : inactiveIcon"
                        >
                        <span style="display: flex; flex:1; justify-content: flex-end;">评论时间： {{item.evtime.String}}</span>
                    </div>
                    <div class='pjcontext'>{{item.context.String}}</div>
                    <div v-if="item.ifupimg">
                        <div class='upyyzzimg' >
                            <div class='upimgbtn1' v-for="(imgitem, index) in item.imgs" :key="index">
                                <img class="upimginfo" :src="imgitem" />
                            </div>
                        </div>
                    </div>
                    <div v-if="item.ifupvideo">
                        <div class='upyyzzimg' >
                            <div class='upimgbtn1' v-for="(videoitem, index) in item.videos" :key="index">
                                <video  class="upimginfo" :src="videoitem"> </video>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <div>
            <van-goods-action>
                <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" />
                <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon" />
                <van-goods-action-button type="warning" text="加入购物车" @click="onClickButton" />
                <van-goods-action-button type="danger" text="立即购买" @click="onClickButton" />
            </van-goods-action>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { Toast } from 'vant'
    import wx from 'weixin-js-sdk'
    import Utils from '../util/utils'
    import { Icon } from 'vant';
    import { Stepper } from 'vant';
    import { Collapse, CollapseItem } from 'vant';
    import { Tab, Tabs } from 'vant';

    Vue.use(Tab);
    Vue.use(Tabs)
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Stepper);
    Vue.use(Icon);
    import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
    Vue.use(GoodsAction);
    Vue.use(GoodsActionButton);
    Vue.use(GoodsActionIcon);
    Vue.prototype.$http = axios
    var wxinfo = wx
    var that
    export default {
        name: "GoodsShow",
        data () {
            return {
                activeNames: [],
                attractiveNames: [],
                specarr: [],
                active: 0,
                subactivenames: [],
                goodsimglist: [],
                goodsattrdata: [],
                goodstattrdata: [],
                inactiveIcon: 'https://sqpro.oss-cn-beijing.aliyuncs.com/wk/v2-af12f7b6f7eb2f2f5f7e2f3b7880cf01_hd.jpg',
                ifborder:false,
                checked: true,
                goodsdes: '',
                //goodsid: '276',
                goodsid: '',
                goodsname: '',
                gcount: 1,
                goodsbaseprice: '',
                goodspricearea: '',
                memberpercent: 100,
                goods_evaluate: [],
                hyprice: ''
            }
        },
        mounted: function () {
            that = this
            // 获取OpenID
            document.title = '祺聚优品'
            localStorage.setItem('curgoodsid',323)
            if (getCookie('accesstoken') === null || getCookie('accesstoken') === '') {
                that.$options.methods.getaccesstoken()
            } else {
                if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                    window.console.log('openID为空')
                    that.$options.methods.getaccesstoken()
                } else {
                    if (localStorage.getItem('userinfo') === null || localStorage.getItem('userinfo') === '') {
                        window.console.log('用户信息为空')
                        that.$options.methods.getuserinfo()
                    } else {
                        window.console.log('用户信息存在')
                        var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                        that.userimg = tmpdata.headimgurl
                        that.usernick = tmpdata.nickname
                    }
                }
                that.$options.methods.configwx()
            }
            that.allscreenWidth = (document.body.clientWidth / 5) - 5
            that.screenWidth = (document.body.clientWidth / 2) - 2
            window.onresize = () => {
                return (() =>{
                    that.allscreenWidth = (document.body.clientWidth / 5) - 5
                    that.screenWidth = (document.body.clientWidth / 2) - 1
                })()
            }
            that.$options.methods.getgoodsimglist()
            that.$options.methods.getgoodsinfo()
            that.$options.methods.getattrgroup()
            that.$options.methods.getbaseattr()
            that.$options.methods.getspecgroup()
            that.$options.methods.getgoodsdes()
            that.$options.methods.getgoodspl()
            stopTouchendPropagationAfterScroll()
        },
        methods:{
            returnyw () {
                this.$router.push({path: '/Example'})
            },
            configwx: function () {
                // 获取微信签名
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getwxjsparams',
                    method: 'post',
                    data: {
                        shareurl: 'https://ecomm.sqnetsoft.cn/ecomm/',
                        accesstoken: getCookie('accesstoken')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sh1signature
                            // var timestamp = Date.parse(new Date()).toString()
                            // timestamp = timestamp.substr(0,10)
                            var noncestr1 = randomWord(false, 30)
                            window.console.log(noncestr1)
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.nonce
                            window.console.log(wxinfo)
                            wxinfo.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    "onMenuShareTimeline",//分享朋友圈接口
                                    "onMenuShareAppMessage",
                                    'chooseWXPay'//分享给朋友接口
                                ]
                            })
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getuserinfo: function () {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getuserinfo',
                    method: 'post',
                    data: {
                        accesstoken: getCookie('accesstoken'),
                        openid: localStorage.getItem('openid')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata) {
                                localStorage.setItem('userinfo',res.data.data)
                                that.userimg = tmpdata.headimgurl
                                that.usernick = tmpdata.nickname
                            } else {
                                localStorage.setItem('userinfo','')
                            }
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getkjrk: function () {
                var qstr1 = "cusid = 1"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/layout/getsci',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log(res.data)
                            that.kjrkdata = JSON.parse(res.data.data)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            reloadaccesstoken: function (refreshtoken) {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/reloadaccesstoken',
                    method: 'post',
                    data: {
                        refreshtoken: refreshtoken
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                /*
                                that.$options.methods.getbrand()
                                that.$options.methods.gettjtypes()
                                that.$options.methods.getkjrk()
                                that.$options.methods.gettjgoods()
                                 */
                                that.$options.methods.getuserinfo()
                                that.$options.methods.configwx()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getopenid: function (params) {
                window.console.log(params['code'])
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getgzhopenidex',
                    method: 'post',
                    data: {
                        code: params['code']
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                /*
                                that.$options.methods.getbrand()
                                that.$options.methods.gettjtypes()
                                that.$options.methods.getkjrk()
                                that.$options.methods.gettjgoods()
                                 */
                                that.$options.methods.getuserinfo()
                                that.$options.methods.configwx()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onClickIcon() {
                Toast('点击图标');
            },
            getaccesstoken() {
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            window.console.log(res.data)
                            that.accesstoken = res.data
                            that.$options.methods.getticket(that.accesstoken)
                        } else {
                            Toast('授权失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getticket (accesstoken) {
                let nowStr1 = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
                var ordernotmp = 'WX' + nowStr1
                var qdata = {
                    accesstoken: accesstoken,
                    appid: that.appid,
                    mchid: that.mchid,
                    iffz: 'Y',
                    price: parseInt(parseFloat(that.hyprice).toFixed(2) * 100),
                    openid: 'og6Ml5oXZz9NSTVpNwZ5chz4JMfM',
                    tradeno: ordernotmp,
                    payurl: 'http://fl.sqnetsoft.cn/#/WxPayTest'
                }
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/getpaysign',
                    method: 'post',
                    data: qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result==='success') {
                            var paytmpdata = res.data.singparams
                            window.console.log(paytmpdata)
                            window.console.log(paytmpdata.appid)
                            wx.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                                nonceStr: res.data.noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: res.data.shasign, // 必填，签名
                                jsApiList: [
                                    "onMenuShareTimeline",//分享朋友圈接口
                                    "onMenuShareAppMessage",
                                    'chooseWXPay'//分享给朋友接口
                                ]
                            })
                            wx.chooseWXPay({
                                appId: paytmpdata.appid,
                                timestamp: paytmpdata.timeStamp,
                                nonceStr: paytmpdata.nonceStr,
                                package: 'prepay_id=' + paytmpdata.prepay_id,
                                signType: 'MD5',
                                paySign: paytmpdata.sign,
                                success: function (r) {
                                    window.console.log('微信api：success')
                                    // 支付成功后的回调函数
                                    if (r.errMsg == "chooseWXPay:ok") {
                                        window.console.log('支付成功')
                                    } else {
                                        window.console.log('success支付失败')
                                    }
                                },
                                cancel: function(r) {
                                    window.console.log('取消支付',r)
                                },
                                fail:function(r){
                                    window.console.log('fail支付失败了',r)
                                    //支付失败
                                }
                            })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onClickButton() {
                this.getaccesstoken()
            },
            getgoodsimglist () {
                // var qstr = 'goods_id = ' + that.goodsid
                var qstr = 'goods_id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgi',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            that.goodsimglist = tmpdata
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getgoodsinfo () {
                // var qstr = 'id = ' + that.goodsid
                var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qginfoex',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            that.goodsname = tmpdata[0].name
                            that.goodsbaseprice = tmpdata[0].baseprice.String
                            that.goodspricearea = tmpdata[0].pricetxt.String
                            that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getgoodsdes () {
                // var qstr = 'goods_id= ' + that.goodsid
                var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgd',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('产品描述')
                                that.goodsdes = tmpdata[0].des.String
                                window.console.log(that.goodsdes)
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getgoodspl () {
                // var qstr5 = 'cusid=1 and ctype= 3 or ctype =4 and gid=' + that.goodsid
                var qstr5 = 'cusid=1 and ctype= 3 or ctype =4 and gid=' + localStorage.getItem('curgoodsid')
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgo',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr5, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                var qstr4 = 'contextstate = 1 and contextid=' + localStorage.getItem('curgoodsid')
                                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                                that.$http({
                                    url: 'https://api.sqnetsoft.cn/evaluate/getbce',
                                    method: 'post',
                                    data: {
                                        whereinfo: {String: qstr4, Valid: true},
                                        limit: { String: 'LIMIT 10', Valid: true }
                                    },
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.success) {
                                            if (res.data.data !== '[]') {
                                                var tmpdata1 = JSON.parse(res.data.data)
                                                var objtmps = []
                                                var pltmp = tmpdata
                                                for (var j = 0; j < tmpdata1.length; j++) {
                                                    var objtmp = tmpdata1[j]
                                                    var imgstmp = []
                                                    var videostmp = []
                                                    for (var i = 0; i < pltmp.length; i++) {
                                                        window.console.log(pltmp[i])
                                                        if (objtmp.id.Int64 === pltmp[i].pid) {
                                                            if (pltmp[i].ctype === 3) {
                                                                imgstmp.push(pltmp[i].curl.String)
                                                            } else if (pltmp[i].ctype === 4) {
                                                                videostmp.push(pltmp[i].curl.String)
                                                            }
                                                        }
                                                    }
                                                    if (imgstmp.length > 0) {
                                                        objtmp.ifupimg = true
                                                        objtmp.imgs = imgstmp
                                                    } else {
                                                        objtmp.ifupimg = false
                                                    }
                                                    if (videostmp.length > 0) {
                                                        objtmp.ifupvideo = true
                                                        objtmp.videos = videostmp
                                                    } else {
                                                        objtmp.ifupvideo = false
                                                    }
                                                    objtmps.push(objtmp)
                                                }
                                                that.goods_evaluate = objtmps
                                                window.console.log('商品评论')
                                                window.console.log(that.goods_evaluate)
                                            }
                                            // 把相关数据缓存到本地
                                        } else {
                                            window.console.log('无商品评论')
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getattrgroup () {
                // var qgattrgroup = 'goods_id=' + that.goodsid + ' and attrtype=2'

                var qgattrgroup = 'goods_id=' + localStorage.getItem('curgoodsid') + ' and attrtype=2'
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgav',
                    method: 'post',
                    data: {
                        whereinfo: {String: qgattrgroup, Valid: true},
                        groupinfo: { String: ' group by attrid ', Valid: true }
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var zobj1 = JSON.parse(res.data.data)
                            window.console.log('产品特殊属性组')
                            window.console.log(zobj1)
                            // var qgattrs = 'goods_id=' + that.goodsid + ' and attrtype=2'
                            var qgattrs = 'goods_id=' + localStorage.getItem('curgoodsid') + ' and attrtype=2'
                            that.$http({
                                url: 'https://api.sqnetsoft.cn/goods/qgav',
                                method: 'post',
                                data: {
                                    whereinfo: {String: qgattrs, Valid: true}
                                },
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.success) {
                                        if (res.data.data !== []) {
                                            window.console.log('产品属性信息')
                                            var obj = JSON.parse(res.data.data)
                                            window.console.log(obj)
                                            var atmp = []
                                            var selectids = []
                                            that.subactivenames = []
                                            for (var j = 0; j < zobj1.length; j++) {
                                                var zobjtmp = zobj1[j]
                                                var zotmp = []
                                                that.subactivenames.push(zobjtmp.attrid.String)
                                                for (var i = 0; i < obj.length; i++) {
                                                    var objtmp = obj[i]
                                                    if (zobjtmp.attrid.String === objtmp.attrid.String) {
                                                        var obj3 = {
                                                            attrgroupindex: j,
                                                            attrvalue: objtmp.attrvalue,
                                                            attrid: objtmp.attrid.String,
                                                            id: objtmp.id.Int64
                                                        }
                                                        zotmp.push(obj3)
                                                    }
                                                }
                                                for (var k = 0; k< zotmp.length; k++) {
                                                    if (k===0) {
                                                        zotmp[k].ifcheck = true
                                                        selectids.push(zotmp[k].id)
                                                    } else {
                                                        zotmp[k].ifcheck = false
                                                    }
                                                }
                                                zobjtmp.items = zotmp
                                                atmp.push(zobjtmp)
                                            }
                                            that.goodstattrdata = atmp
                                            window.console.log('最后数组')
                                            window.console.log(atmp)
                                            window.console.log(selectids)
                                            var qstr = "cuserid = 1 and  specgroupid='" + selectids + "'"
                                            that.$http({
                                                url: 'https://api.sqnetsoft.cn/control/querysp',
                                                method: 'post',
                                                data: {
                                                    whereinfo: {String: qstr, Valid: true}
                                                },
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                }
                                            })
                                                .then(function (res) {
                                                    if (res.data.success) {
                                                        if (res.data.data !== '[]') {
                                                            var tmpdata = JSON.parse(res.data.data)
                                                            window.console.log('规格价格')
                                                            window.console.log(tmpdata)
                                                            that.goodsbaseprice = tmpdata[0].baseprice.String
                                                            that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                                            window.console.log(res)
                                                        }
                                                        // 把相关数据缓存到本地
                                                    } else {
                                                        window.console.log('获取商品失败')
                                                    }
                                                })
                                                .catch(function (error) {
                                                    window.console.log(error)
                                                })
                                        }
                                        // 把相关数据缓存到本地
                                    } else {
                                        window.console.log('获取商品属性失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })

                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品属性失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getbaseattr () {
                var qstr = 'goods_id=' + localStorage.getItem('curgoodsid') + " and  attrtype=0 and  attrvalue !=''"
                // var qstr = 'goods_id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgav',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            that.goodsattrdata = tmpdata
                            window.console.log('基础属性')
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getspecgroup () {
                var qgattrs = 'goodsid=' + localStorage.getItem('curgoodsid')
                // var qgattrs = 'goods_id=' + wx.getStorageSync('curgoodsid') + ' and attrtype=2'
                that.$http({
                    url: 'https://api.sqnetsoft.cn/goods/qgsp',
                    method: 'post',
                    data: {
                        whereinfo: {String: qgattrs, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== []) {
                                window.console.log('规格组信息')
                                var obj = JSON.parse(res.data.data)
                                var specarrtmp = []
                                for (var i = 0; i < obj.length; i++) {
                                    var specdata
                                    if (i === 0) {
                                        specdata = {
                                            gspecname: obj[i].gspecname.String,
                                            ifcheck: true,
                                            id: obj[i].id.Int64
                                        }
                                    } else {
                                        specdata = {
                                            gspecname: obj[i].gspecname.String,
                                            ifcheck: false,
                                            id: obj[i].id.Int64
                                        }
                                    }
                                    specarrtmp.push(specdata)
                                }
                                that.specarr = specarrtmp
                                var qstr = 'cuserid = 1 and  specgroupid=' + obj[0].id.Int64
                                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                                that.$http({
                                    url: 'https://api.sqnetsoft.cn/control/querysp',
                                    method: 'post',
                                    data: {
                                        whereinfo: {String: qstr, Valid: true}
                                    },
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.success) {
                                            if (res.data.data !== '[]') {
                                                var tmpdata = JSON.parse(res.data.data)
                                                window.console.log('规格价格')
                                                window.console.log(tmpdata)
                                                that.goodsbaseprice = tmpdata[0].baseprice.String
                                                that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                                window.console.log(res)
                                            }
                                            // 把相关数据缓存到本地
                                        } else {
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品属性失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            specchange(groupindex,specid) {
                window.console.log(groupindex)
                window.console.log(specid)
                var tmpdata = that.goodstattrdata
                var attrids = []
                for (var i = 0; i < tmpdata.length; i++) {
                    if (i === groupindex) {
                        var subtmpdata = tmpdata[i].items
                        for (var j = 0; j < subtmpdata.length; j++) {
                            if (subtmpdata[j].id === specid) {
                                subtmpdata[j].ifcheck = true
                            } else {
                                subtmpdata[j].ifcheck = false
                            }
                        }
                    }
                }
                for (var k = 0; k < tmpdata.length; k++) {
                    var subtmpdata1 = tmpdata[k].items
                    for (var l = 0; l < subtmpdata1.length; l++) {
                        if (subtmpdata1[l].ifcheck) {
                            attrids.push(subtmpdata1[l].id)
                        }
                    }
                }
                that.goodstattrdata = tmpdata
                window.console.log('新的属性组')
                window.console.log(that.goodstattrdata)
                var qstr = "cuserid = 1" + " and  specgroupid='" + attrids + "'"
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/control/querysp',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('规格价格')
                                window.console.log(tmpdata)
                                that.goodsbaseprice = tmpdata[0].baseprice.String
                                that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                window.console.log(res)
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            basespecchage(specid) {
                window.console.log(specid)
                window.console.log(that.specarr)
                var tmpdata = that.specarr
                for (var i = 0; i < tmpdata.length; i++) {
                    if (tmpdata[i].id === specid) {
                        tmpdata[i].ifcheck = true
                    } else {
                        tmpdata[i].ifcheck = false
                    }
                }
                that.specarr = tmpdata
                var qstr = "cuserid = 1" + " and  specgroupid='" + specid + "'"

                window.console.log(qstr)
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/control/querysp',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('规格价格')
                                window.console.log(tmpdata)
                                that.goodsbaseprice = tmpdata[0].baseprice.String
                                that.hyprice = parseFloat(parseFloat(tmpdata[0].baseprice.String).toFixed(2) * (parseInt(that.memberpercent) / 100)).toFixed(2)
                                window.console.log(res)
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }

    function setCookie (name, value, seconds) {
        var d = new Date()
        d.setTime(d.getTime() + 1000 * seconds)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function randomWord (randomFlag, min, max) {
        var str = ''
        var range = min
        var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        // 随机产生
        if (randomFlag) {
            range = Math.round(Math.random() * (max - min)) + min
        }
        for (var i = 0; i < range; i++) {
            var pos = Math.round(Math.random() * (arr.length - 1))
            str += arr[pos]
        }
        return str
    }
    function stopTouchendPropagationAfterScroll(){
        var locked = false;
        window.addEventListener('touchmove', function(ev){
            locked || (locked = true, window.addEventListener('touchend', stopTouchendPropagation, true));
            window.console.log(ev)
        }, true);
        function stopTouchendPropagation(ev){
            ev.stopPropagation();
            window.removeEventListener('touchend', stopTouchendPropagation, true);
            locked = false;
        }
    }
</script>

<style scoped>
    .goodsinfo {
        font-size:14px;
        margin:10px;
    }

    .specbtn {
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .goodsimg {
        width: 220px;
        height: 220px;
    }
    .goodsnameview {
        align-items: center;
        margin-bottom: 8px;
        display: flex;
        flex-flow: row;
    }
    .goodsname {
        flex:1;
    }
    .goodsprice {
        font-size: 14px;
        color: rgb(180, 11, 11);
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .pltime{
        width:200px;
    }
    .userimg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
    .shareview {
        width:60px;
        border: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        flex-flow: row;
        background-color: transparent;
        justify-content: center;
        box-sizing:border-box;
        font-size:14px;
    }
    .defalutpricetxt {
        font-size: 16px;
        text-decoration:line-through;
        flex: 1;
    }

    .defalutpricetxt1 {
        font-size: 16px;
        flex: 1;
    }
    .memberpricetxt {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
    }
    .redfontbc {
        font-family: "iconfont" !important;
        margin-right: 5px;
        font-size: 14px;
        font-style: normal;
        color: #b10000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .desinfo {
        width:100%;
    }
    .desinfo >>> img {
        width:100%;
    }
    .pjpanel {
        display: flex;
        align-items: center;
        height: 32px;
        margin-left:20px;
        margin-right:20px;
        font-size: 12px;
    }
    .pjcontext {
        padding-top: 10px;
        padding-left: 20px;
        min-height: 80px;
        font-size: 12px;
        margin: 10px;
        border: 1px  solid #f2f3f5;
        color: rgb(161, 161, 161);
    }
</style>

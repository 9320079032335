<template>
    <div>
            <div>
                <van-grid :border="false" :column-num="4">
                    <van-grid-item text="公告法院" />
                    <van-grid-item text="当事人" />
                    <van-grid-item text="公告时间" />
                    <van-grid-item text="下载" />
                </van-grid>
                <div style="width:100%;background-color: #f2f3f5;height:1px;"></div>
            </div>
            <div v-for="item in gglist" :key="item" >
                <van-grid :border="false" :column-num="4">
                    <van-grid-item :text="item[1]" />
                    <van-grid-item :text="item[3]" />
                    <van-grid-item :text="item[4]" />
                    <van-grid-item :text="item[6]" />
                </van-grid>
            </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">
                获取内容
            </van-button>
        </div>
    </div>
</template>

<script>
    var that
    export default {
        name: "GetInfo",
        data () {
            return {
                urlstr : 'https://law.wkinfo.com.cn/judgment-documents/detail/MjAzMDA4ODc5Mzg%3D?searchId=43ca7ffb28384d23a3f61d7b31fdc7fc&index=1&q=&module=',
                htmlcontext: '',
                gglist: []
            }
        },
        mounted() {
            that = this
            document.title = '祺聚优品'
            that.$http({
                url: 'http://127.0.0.1:5000/getgg',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    that.gglist = res.data
                    window.console.log(that.gglist)
                })
                .catch(function (error) {
                    window.console.log('error' + error)
                })
        }
    }
</script>

<style scoped>

</style>
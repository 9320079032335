<template>
    <div>
        <div style="display: flex; border-top: 1px solid #f1f1f1; flex-direction: row; background-color: #fff;">
            <van-sidebar v-model="activeKey" @change="changetype" style="width: 80px;">
                <van-sidebar-item v-for="(subitem, index) in goodstypes[0].children" :key="index" :title="subitem.name" />
            </van-sidebar>
            <div style="flex:1; background-color: #f1f1f1; padding: 5px 0px 5px 0px; margin-bottom: 60px; min-height: 800px;">
                <van-grid column-num="2"  gutter="5">
                    <van-grid-item
                            style="border-radius: 2px;"
                            v-for="(tjgoods) in goodslist"
                            :key="tjgoods.id"
                            :text="tjgoods.name"
                            @touchstart="showgoods(tjgoods.id)" @click="showgoods(tjgoods.id)"
                    >
                        <div style="margin-top: 20px;">
                            <div style="display: flex; justify-content: center;align-items: center;border-radius: 6px 6px 0 0; height: 110px;width: 100%; ">
                                <van-image radius="10" style="width:110px;height: 110px;" :src="tjgoods.img_url" />
                            </div>
                            <div style=" width:100%; font-size:12px; height: 65px;flex-direction: column; display: flex; ">
                                <div style="margin: 10px 10px 0 10px; justify-content: center;align-items: center;display: flex;">
                                    {{tjgoods.name}}
                                </div>
                                <div style="margin: 5px 10px 0 10px;justify-content: center;align-items: center;display: flex;">
                                    <span>价格：</span>
                                    <span style="font-size:14px;color: #b10000;">{{tjgoods.baseprice}}</span>
                                </div>
                            </div>
                        </div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import { Sidebar, SidebarItem } from 'vant';
    import { TreeSelect } from 'vant';

    Vue.use(TreeSelect);
    Vue.use(Sidebar);
    Vue.use(SidebarItem);
    Vue.prototype.$http = axios
    var that
    export default {
        name: "GoodsType.vue",
        mounted () {
            that = this
            document.title = '产品分类'
            this.loadtdata()
        },
        data () {
            return {
                goodstypes: [],
                goodslist: [],
                activeKey: 0,
                activeId: 1,
                activeIndex: 0,
                treeheight: window.innerHeight - 40,
                colwidth: document.body.clientWidth / 5
            }
        },
        methods: {
            showgoods: function(goodsid) {
                localStorage.setItem('curgoodsid', goodsid)
                this.$router.push('/GoodsInfo')
            },
            changetype (val) {
                window.console.log(val)
                that.getgoods(that.goodstypes[0].children[val].id)
            },
            getgoods (typeid) {
                var qstrinfo = {
                    whereinfo: ' id > 0 and find_in_set(' + typeid + ', goods_type_id)',
                    limit: '',
                    sortinfo: ' order by id desc'
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryginfo',
                    method: 'post',
                    data: qstrinfo,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.goodslist = res.data
                        } else {
                            that.goodslist = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            returnyw() {
                this.$router.push({path: '/'})
            },
            loadtdata () {
                var qstr = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/quergtype',
                    method: 'POST',
                    data: qstr,
                    header: {
                        'content-type': 'application/json' // 默认值
                    }
                }) .then(function (res) {
                    if (res.data.length > 0) {
                        var obj = res.data
                        that.goodstypes = JSON.parse(obj[0].typetree)
                        window.console.log('所属分类',that.goodstypes)
                        that.getgoods(that.goodstypes[0].children[0].id)
                    } else {
                        that.goodstypes = []
                    }
                }).catch(function (error) {
                    window.console.log(error)
                })
            },
            showTypeCourse () {}
        }
    }
</script>

<style scoped>
    /* pages/type/typex.wxss */
    .allpanel {
        display:flex;/*设为伸缩容器*/
        flex-flow:row;/*伸缩项目单行排列*/
        width: 100%;
        margin-bottom: 5px;
        margin-top: 5px;
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;
        font-weight: 300;
    }

    .all_leftpart {
        margin-left: 5px;
        flex: 1;
        font-size: 16px;
        color:#000;
    }
    .imgicon {
        width:26px;
        height: 26px;
        margin-left:5px;
        border-radius: 5px;
        border: 2px solid #fff;
    }
    .all_rightpart {
        width:30px;
        display: flex;
        font-size: 16px;
        color: #999999;
        justify-content: center;
        align-items:center;
        margin-right: 0px;
    }

    .subpanel {
        display: flex;
        font-size: 16px;
        font-weight: 300;
        color: #999999;
        justify-content: center;
        align-items:center;
        background-color: #fff;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .contextcontainer {
        display: flex;
        height: 100%;
        flex-flow: row;
        flex-wrap: wrap;
    }

    .txtcontainer1 {
        display: flex;
        flex-flow: column;
        justify-items: center;
        align-items: center;
    }

    .txtinfo {
        font-size: 12px;
        font-weight: 300;
        line-height: 10px;
        color: #000;
    }

    .rowslip {
        height: 0px;
        background-color: #fff;
    }

    .colslip {
        width: 1px;
        background-color: #fff;
    }

    .imginfo {
        margin-top: 10px;
        width: 64px;
        height: 64px;
    }
</style>

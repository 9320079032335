<template>
    <div>
        <van-contact-card type="edit" :name="shrname" :tel="shrcontact"  @click="showDeliver"/>
        <div class="van-doc-demo-block__title">
            商品清单
        </div>
        <van-card v-for="(item, index) in cartdata" :key="index"
                :num="item.buynum"
                :price="item.goodsbaseprice"
                desc="描述信息"
                :title="item.goodsname"
                :thumb="item.goodsimg"
        >
            <template #tags>
                <van-tag plain type="danger">{{item.specinfo}}</van-tag>
            </template>
            <template #footer>
                <van-stepper min="0" @change="changestep(item.buynum,index)" v-model="item.buynum" />
            </template>
        </van-card>
        <van-cell-group>
            <van-cell  @touchend="ShowDistribution" value="免费配送" @click="ShowDistribution" title="配送方式" icon="scan"></van-cell>
            <van-field
                    v-if="pstimevis"
                    readonly
                    clickable
                    name="picker"
                    left-icon="scan"
                    :value="pstime"
                    label="配送时间"
                    placeholder="选择配送时间"
                    @click="showPicker = true"
            />
            <van-cell  @touchend="ShowPayWay" value="微信支付" @click="ShowPayWay" title="支付方式" icon="location-o"></van-cell>
            <van-coupon-cell
                    :coupons="coupons"
                    :chosen-coupon="chosenCoupon"
                    @click="showList = true"
            />
            <van-field
                    v-model="desmessage"
                    rows="6"
                    autosize
                    label="留言"
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入留言"
                    show-word-limit
            />
            <van-popup v-model="showPicker" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="pstimelist"
                        @confirm="psqr"
                        @cancel="showPicker = false"
                />
            </van-popup>
        </van-cell-group>
        <van-submit-bar :price="jscount" button-text="提交订单" @submit="onSubmit" />

        <van-popup
                v-model="showList"
                round
                position="bottom"
                style="height: 90%; padding-top: 4px;"
        >
            <van-coupon-list
                    :coupons="coupons"
                    :chosen-coupon="chosenCoupon"
                    :disabled-coupons="disabledCoupons"
                    @change="onChange"
                    @exchange="onExchange"
            />
        </van-popup>
        <!--
        <van-cell title="收货人：杨晓朔" is-link center  icon="location-o">
            <template #label>
                <div>联系方式：13911419225</div>
                <div>收货地址：北京工业大学（通州校区）</div>
            </template>
        </van-cell>
        -->
    </div>
</template>

<script>
    import Vue from 'vue';
    import { ContactCard, ContactList, ContactEdit } from 'vant';
    import { SubmitBar } from 'vant';
    import { Card } from 'vant';
    import { Stepper } from 'vant';
    import { Notify } from 'vant';
    import { Field } from 'vant';
    import { CouponCell, CouponList } from 'vant';
    import { Tag } from 'vant';
    import Utils from '../../util/utils'
    Vue.use(Tag);
    Vue.use(CouponCell);
    Vue.use(CouponList);
    Vue.use(Field);
    Vue.use(Stepper);
    Vue.use(Card);
    Vue.use(Notify);
    Vue.use(ContactCard);
    Vue.use(ContactList);
    Vue.use(ContactEdit);
    Vue.use(SubmitBar);
    var that
    export default {
        name: "CartJS",
        data() {
            return {
                chosenAddressId: '1',
                editingContact: {},
                showList: false,
                showEdit: false,
                chosenCoupon: -1,
                showPicker: false,
                pstimevis: false,
                pstime: '',
                pstimelist: ['5号','20号'],
                gcount: 1,
                jscount: 0,
                coupon: '',
                cartdata: [],
                ifallchecked: false,
                shrid: '',
                shrname: '',
                shrcontact: '',
                yhqprice: 0,
                coupons: [],
                curkqid: '',
                yhje: 0,
                disabledCoupons: [],
                desmessage: '',
                isEdit: false
            };
        },
        mounted () {
            that = this
            document.title = '订单结算'
            if (localStorage.getItem('paydata')) {
                this.cartdata =  JSON.parse(localStorage.getItem('paydata') || '[]')
            } else {
                this.cartdata = []
            }
            if (localStorage.getItem('tsorder') && localStorage.getItem('tsorder') === '1') {
                this.pstimevis = true
            }
            window.console.log('是否显示配送时间')
            window.console.log(this.pstimevis)
            var jstmp = 0
            for (var i = 0; i < this.cartdata.length; i++) {
                var datatmp = this.cartdata[i]
                if (!datatmp.goodschecked) {
                    this.ifallchecked = false
                } else {
                    jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
                }
            }
            this.jscount = jstmp - this.yhqprice
            if (this.jscount < 0) {
                this.jscount = 0
            }
            if (localStorage.getItem('shrname') && localStorage.getItem('shrcontact') && localStorage.getItem('shrid')) {
                this.shrname = localStorage.getItem('shrname')
                this.shrcontact = localStorage.getItem('shrcontact')
                this.shrid = localStorage.getItem('shrid')
            }
            window.console.log((localStorage.getItem('openid').substring(0,4)))
            that.$options.methods.getcouponuse()
        },
        methods: {
            getcouponuse () {
                var qstr = {
                    whereinfo: " userid='" + localStorage.getItem('userphone') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/querycouponuse',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        window.console.log(res.data)
                        if (res.data.length > 0) {
                            that.coupons = []
                            that.disabledCoupons = []
                            for (var i = 0; i < res.data.length; i++) {
                                var tmpdata = res.data[i]
                                window.console.log('时间戳',tmpdata.startdate)
                                if (tmpdata.is_use === 0) {
                                    var adata = {
                                        id: tmpdata.id,
                                        available: tmpdata.is_use,
                                        condition: '无门槛',
                                        reason: '',
                                        value: tmpdata.couponprice,
                                        name: tmpdata.couponname,
                                        startAt: parseInt(new Date(tmpdata.startdate).getTime() / 1000),
                                        endAt: parseInt(new Date(tmpdata.enddate).getTime() / 1000),
                                        valueDesc: tmpdata.couponprice / 100,
                                        unitDesc: '元',
                                    }
                                    that.coupons.push(adata)
                                }
                                if (tmpdata.is_use === 1) {
                                    var adata1 = {
                                        available: tmpdata.is_use,
                                        condition: '无门槛',
                                        reason: '',
                                        value: tmpdata.couponprice,
                                        name: tmpdata.couponname,
                                        startAt: new Date(tmpdata.startdate).getTime(),
                                        endAt: new Date(tmpdata.enddate).getTime(),
                                        valueDesc: tmpdata.couponprice,
                                        unitDesc: '元',
                                    }
                                    that.disabledCoupons.push(adata1)
                                }
                            }
                        } else {
                            that.coupons = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })

            },
            changeyhqstate () {
                var qstr = "id =" + that.curkqid
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/cardticket/upbmt',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        fieldparams: {String: ' kqstate=0', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('卡券已使用')
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            returnyw() {
                this.$router.replace({path: '/CartInfo'})
            },
            psqr (value,index) {
                this.pstime = value
                this.showPicker = false;
                window.console.log(index)
            },
            loadAreaList () {
                var qstr = 'userid = ' + localStorage.getItem('userid')
                window.console.log(qstr)
                // var qstr = 'id = ' + localStorage.getItem('curgoodsid')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/getbua',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log('获取收货地址')
                                window.console.log(tmpdata)
                                for (var i = 0; i < tmpdata.length; i++) {
                                    if (tmpdata[i].defaultad === 1) {
                                        that.shrname = tmpdata[i].username.String
                                        that.shrcontact = tmpdata[i].contact.String
                                        that.shrid = tmpdata[i].id.Int64
                                    }
                                }
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            changestep (value,index) {
                window.console.log(value,index)
                if (value === 0) {
                    this.cartdata.splice(index,1)
                    localStorage.setItem('paydata',JSON.stringify(this.cartdata))
                } else {
                    var jstmp = 0
                    this.cartdata[index].buynum = value
                    localStorage.setItem('paydata',JSON.stringify(this.cartdata))
                    for (var i = 0; i < this.cartdata.length; i++) {
                        var datatmp = this.cartdata[i]
                        if (!datatmp.goodschecked) {
                            this.ifallchecked = false
                        } else {
                            jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
                        }
                    }
                    this.jscount = jstmp - this.yhqprice
                    if (this.jscount < 0) {
                        this.jscount = 0
                    }
                }
            },
            pay (tmporderno,jscount) {
                var qdata = {
                    description: '硕祺订单',
                    amount: jscount.toString(),
                    openid: localStorage.getItem('openid'),
                    orderno: tmporderno,
                }
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/paysign',
                    method: 'post',
                    data: qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.code === 0) {
                            var paytmpdata = res.data.result
                            window.console.log(paytmpdata)
                            window.console.log(paytmpdata.appId)
                            window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
                                    "appId": paytmpdata.appId,
                                    "timeStamp": paytmpdata.timeStamp,
                                    "nonceStr": paytmpdata.nonceStr,
                                    "package": paytmpdata.package,
                                    "signType": "RSA",// 微信签名方式：
                                    "paySign":paytmpdata.paySign // 微信签名
                                },
                                (res) => {
                                    window.console.log(res)
                                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                                        window.console.log(res)
                                        // 更新优惠券
                                        that.uporderpaystate(tmporderno)
                                        // 自动分单
                                        // that.runautofd()
                                        that.$router.replace({ path: 'DQR' })

                                    } else if (res.err_msg == "get_brand_wcpay_request:cancel"){
                                        that.$router.replace({ path: 'DFK' })
                                    }
                                });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                        document.addEventListener('WeixinJSBridgeReady', that.pay(tmporderno,jscount), false);
                    } else if (document.attachEvent) {
                        document.attachEvent('WeixinJSBridgeReady', that.pay(tmporderno,jscount));
                        document.attachEvent('onWeixinJSBridgeReady', that.pay(tmporderno,jscount));
                    }
                } else {
                    window.console.log('支持微信支付')
                }
            },
            uporderpaystate (orderno) {
                var updata = {
                    orderno: orderno,
                    state: '2'
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/uporderstate',
                    method: 'post',
                    data: updata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.$router.replace({ path: 'OrderInfo?state=0' })
                        } else {
                            that.$router.push({ path: 'PayError' })
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                        Notify({ type: 'danger', message: '退款失败' });
                    })
            },
            addtcardinfo (tcardinfo) {
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/aaubotc',
                    method: 'post',
                    data: tcardinfo,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('下单完毕')
                        } else {
                            window.console.log('地址添加失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onSubmit() {
                if (that.shrid !== null && that.shrid !== '' && that.shrid !== undefined) {
                    var ordertmp = ''
                    var useridtmp = localStorage.getItem('openid')
                    var cusidtmp = localStorage.getItem('openid')
                    var typeidtmp = 0
                    var ownerid = ''
                    if (localStorage.getItem('tguserid')) {
                        ordertmp = 'FX'
                        ownerid = localStorage.getItem('tguserid')
                        window.console.log('分销订单')
                        typeidtmp = 3
                        if (parseInt(useridtmp) === parseInt(ownerid)) {
                            ordertmp = 'XS'
                            ownerid = cusidtmp
                            typeidtmp = 1
                        }
                    } else {
                        ordertmp = 'XS'
                        ownerid = cusidtmp
                        typeidtmp = 1
                    }
                    var zjje = 0
                    if (this.yhje > 0) {
                        zjje = that.jscount - this.yhje
                    } else {
                        zjje = that.jscount
                    }
                    let nowStr1 = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
                    let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                    if (localStorage.getItem('neworder') === '0') {
                        ordertmp = localStorage.getItem('curorderno')
                        // that.$options.methods.OnAddOrderEx(ordertmp,'')
                        that.pay(ordertmp,zjje)
                    } else {
                        ordertmp = ordertmp + nowStr1 + useridtmp.substring(0,3)
                        // 判断是否是特殊订单
                        var orderinfo
                        if (localStorage.getItem('tsorder') && localStorage.getItem('tsorder') === '1') {
                            //特殊订单
                            orderinfo = {
                                orderno: ordertmp,    /// 订单编号
                                typeid: '9',  /// 订单类别，注意这个如果是3，则是分销订单，按照分销处理
                                stateid: '1',  /// 订单状态
                                buycusid: useridtmp.toString(), /// 购买方OpenID，*有可能是分销人员购买
                                salecusid: cusidtmp.toString(),  /// 销售方OpenID
                                countprice: (parseInt(that.jscount) / 100).toString(),
                                userid: useridtmp.toString(),  /// 用户ID
                                optime: nowStr,
                                ifnew: '0',
                                cusid: ownerid.toString(),
                                ownphone: localStorage.getItem('username') /// 注意这个，这个是订单的所有者，用来记录分销所有者
                            }
                        } else {
                            //普通订单
                            orderinfo = {
                                orderno: ordertmp,    /// 订单编号
                                typeid: typeidtmp.toString(),  /// 订单类别，注意这个如果是3，则是分销订单，按照分销处理
                                stateid: '1',  /// 订单状态
                                buycusid: useridtmp.toString(), /// 购买方ID，*有可能是分销人员购买
                                salecusid: cusidtmp.toString(),  /// 销售方ID
                                countprice: (parseInt(that.jscount) / 100).toString(),
                                userid: useridtmp.toString(),  /// 用户ID
                                optime: nowStr,
                                ifnew: '0',
                                cusid: ownerid.toString(),
                                ownphone: localStorage.getItem('username') /// 注意这个，这个是订单的所有者，用来记录分销所有者
                            }
                        }
                        /*
                        var tcardinfo = {
                            orderno: ordertmp,
                            pstime: that.pstime,
                            pstimes: that.cartdata[0].cardtimes,
                            tcardtype: that.cartdata[0].cardtype.toString(),
                            tcardno: '', //暂时不分配卡号
                            jhstate: '0',
                            userid: useridtmp.toString(),
                            cusid: ownerid.toString()
                        }*/
                        var details = []
                        var paydetails = []
                        var cartdata = []
                        for (var i = 0; i < that.cartdata.length; i++) {
                            var datatmp = that.cartdata[i]
                            if (datatmp.goodschecked) {
                                var detaildata = {
                                    orderno: ordertmp,
                                    goodsid: datatmp.goodsid.toString(),
                                    specgroupid: '-1',
                                    specinfo: that.cartdata[i].specinfo,
                                    imgurl: that.cartdata[i].goodsimg,
                                    price: datatmp.goodsbaseprice.toString(),
                                    count: datatmp.buynum.toString()
                                }
                                details.push(detaildata)
                                paydetails.push(datatmp)
                            } else {
                                cartdata.push(datatmp)
                            }
                        }
                        window.console.log(cartdata)
                        that.$http({
                            url: 'https://ecommapi.sqnetsoft.cn/addorderinfo',
                            method: 'post',
                            data: orderinfo,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                if (res.data.result === 'success') {
                                    // 添加产品详情
                                    that.$http({
                                        url: 'https://ecommapi.sqnetsoft.cn/paddorderdetail',
                                        method: 'post',
                                        data: details,
                                        headers: {
                                            'Content-Type': 'application/json'
                                        }
                                    })
                                        .then(function (res) {
                                            if (res.data.result === 'success') {
                                                window.console.log('下单完毕')
                                                // that.$router.replace('DFK')
                                                // that.uporderpaystate(ordertmp)
                                                that.addorderex(ordertmp)
                                                that.pay(ordertmp,zjje)
                                            } else {
                                                window.console.log('地址添加失败')
                                            }
                                        })
                                        .catch(function (error) {
                                            window.console.log(error)
                                        })

                                } else {
                                    window.console.log('地址添加失败')
                                }
                            })
                            .catch(function (error) {
                                window.console.log(error)
                            })
                    }
                } else {
                    Notify({ type: 'warning', message: '收货地址必须填写' });
                }
            },
            addorderex (orderno) {
                var adataex = {
                    orderno: orderno,
                    tradeno: '',
                    useraddressid: that.shrid,
                    paywayid: '1',
                    deliverywayid: '1',
                    ifneedinvoice: '0',
                    invoiceid: '',
                    uword: that.desmessage,
                    cusid: '',
                    ownphone: localStorage.getItem('username')
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/addorderinfoex',
                    method: 'post',
                    data: adataex,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            window.console.log('下单完毕')
                        } else {
                            window.console.log('地址添加失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showDeliver () {
                this.$router.push({path: '/DList'})
            },
            onChange(index) {
                window.console.log(that.coupons[index])
                this.showList = false;
                this.chosenCoupon = index;
                this.yhqprice = that.coupons[index].value
                that.curkqid = that.coupons[index].kqid
                window.console.log('优惠券价格',that.coupons[index].value)
                var jstmp = 0
                for (var i = 0; i < this.cartdata.length; i++) {
                    var datatmp = this.cartdata[i]
                    if (!datatmp.goodschecked) {
                        this.ifallchecked = false
                    } else {
                        jstmp = jstmp + parseFloat(datatmp.goodsbaseprice).toFixed(2) * parseInt(datatmp.buynum)* 100
                    }
                }
                this.jscount = jstmp - this.yhqprice
                if (this.jscount < 0) {
                    this.jscount = 0
                }
            },
            onExchange(code) {
                window.console.log('切换')
                window.console.log(code);
                //this.coupons.push(this.coupon);
            },
            ShowDistribution () {},
            ShowPayWay () {}
        }
    }
</script>

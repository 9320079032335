<template>
    <div>
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <van-row style="margin: 60px;">
            <van-col span="24"><van-image
                    width="8rem"
                    height="8rem"
                    fit="contain"
                    src="
https://sqpro.oss-cn-beijing.aliyuncs.com/wk/%E7%A1%95%E7%A5%BALogo.png"
            /></van-col>
        </van-row>
        <van-row style="display: flex; align-items: flex-start; margin: 60px; font-size:14px;">祺聚优品是一个新业态的互联网公司，涉及不同领域的互联网解决方案，需求定制开发，同时注入新的人工智能，区块链等新的技术，给企业带来全面的升级!</van-row>
        <van-row style="display: flex; align-items: flex-start; margin: 30px; text-indent:2em; font-size:14px;">联系我们：13911419225</van-row>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Image } from 'vant';
    import { Col, Row } from 'vant';

    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Image);
    export default {
        name: "ContactUs",
        methods: {
            returnyw() {
                this.$router.push({path: '/My'})
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        label="手机号"
                        placeholder="手机号"
                        @blur="checkuser()"
                        :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
                <van-field
                        v-model="password"
                        type="password"
                        name="密码"
                        label="密码"
                        placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
                <van-field
                        v-model="repassword"
                        type="password"
                        name="重复密码"
                        label="重复密码"
                        placeholder="重复密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
            </van-form>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="ntowstep">
                下一步
            </van-button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(CountDown);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "RegCus",
        data () {
            return {
                phone: '',
                sms: '',
                password: '',
                repassword: '',
                ifreg: '',
                btntxt: '发送验证码',
                yzmabled: false
            }
        },
        mounted() {
            document.title = '企业注册'
            that = this
            window.console.log(that)
            if (localStorage.getItem('accountid') !== 0 && localStorage.getItem('accountid') !== null) {
                that.$router.push({ path: 'RegTwoStep' })
            }
        },
        methods: {
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            checkuser () {
                var qstr = 'username = ' + this.phone
                that.$http({
                    url: 'https://api.sqnetsoft.cn/account/getaccountlist',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log(res)
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.id.Int64 === 0) {
                                window.console.log('获取用户失败')
                                Notify({ type: 'success', message: '用户名可注册' });
                                localStorage.setItem('accountid',0)
                                localStorage.setItem('username','')
                            } else {
                                Notify({ type: 'warning', message: '用户名已存在，请重新填写' });
                                that.ifreg = true
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取用户失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
                this.ifreg = false
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        vphone: this.phone,
                        issend: parseInt(0)
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/getsms',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.data
                                that.sms = res.data.data
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            ntowstep () {
                // 获取注册信息，进行第一步注册，保存对应的账号ID，如果有的情况
                if (!this.ifreg) {
                    var adata = {
                        username: this.phone,
                        password: this.password,
                        cususerid: '0'
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/aaubai',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                localStorage.setItem('accountid',res.data.data)
                                localStorage.setItem('username',that.phone)
                                that.$router.push({ path: 'RegTwoStep' })
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                }
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
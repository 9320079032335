<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <van-form>
                <nav class="van-doc-demo-block__title" style="font-size: 14px;">
                    <ul>
                        <li v-for="(item, index) in dataList" :key="index">
                            {{ item }}
                        </li>
                    </ul>
                </nav>
                <van-field
                        v-model="username"
                        rows="1"
                        autosize
                        label="用户名"
                        type="textarea"
                        maxlength="30"
                        placeholder="请输入用户名"
                        show-word-limit
                />
                <van-field
                        v-model="msginfo"
                        rows="1"
                        autosize
                        label="留言"
                        type="textarea"
                        maxlength="30"
                        placeholder="请输入留言"
                        show-word-limit
                />
            </van-form>
            <div style="margin: 10px;">
                <van-button block type="primary" size="large" @click="login">授权登录</van-button>

                <van-button block type="primary" size="large" @click="sendmsg">发送消息</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Notify } from 'vant';
    import { RadioGroup, Radio } from 'vant';
    Vue.use(Notify);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "TxClient",
        data () {
            return {
                msginfo: '',
                username: '',
                dataList: []
            }
        },
        mounted () {
            that = this
            window.console.log(that)
            this.initWebSocket()
        },
        destroyed() {
            this.websock.close();
        },
        methods: {
            initWebSocket() {
                const wsuri = 'ws://192.168.1.4:9001/conn/' + this.username
                window.console.log(wsuri)
                this.websock = new WebSocket(wsuri);
                this.websock.onmessage = this.websockonmessage;
                this.websock.onopen = this.websockonopen;
                this.websock.onerror = this.websockonerror;
                this.websock.onclose = this.websockclose;
            },
            websockonopen() {
                var userinfo = {
                    "username": this.username,
                    "usertype": 'user',
                    "type": 'adduser'
                }
                this.websocketsend(JSON.stringify(userinfo));
            },
            websockonerror() {
                this.initWebSocket();
            },
            websockonmessage(e) {
                window.console.log(e);
                var recv_msg = JSON.parse(e.data);
                var tmpstr = ''
                tmpstr = recv_msg.user + ":" + recv_msg.msg;
                this.dataList.push(tmpstr);
            },
            websockclose() {
                window.console.log('连接中断');
            },
            websocketsend(Data){//数据发送
                this.websock.send(Data);
            },
            sendmsg() {
                var msginfo = {
                    "username": that.username,
                    "msgtype": 'from',
                    "type": 'frommsg',
                    "msg": this.msginfo
                }
                this.websocketsend(JSON.stringify(msginfo));
            },
            login() {
                this.initWebSocket()
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .barcodeshow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }
</style>

<template>
    <div style="background-color: #fff;">
        <div style="min-height: 500px; font-size: 14px; padding: 20px; background-color: #fff">
            <p v-html="resultobj.output"></p>
        </div>
        <div style="background-color:#f1f1f1; padding: 1px; width:100%;position: fixed; bottom:0;">
            <van-field
                    v-model="saywords"
                    @keyup.enter.native = "sendmsg"
                    placeholder="请在此输入问题"
            >
                <template #button>
                    <van-button size="small" @click="sendmsg" type="primary">发送</van-button>
                    <van-button size="small" style="margin-left: 5px;" @click="copyinfo" type="primary">复制</van-button>
                </template>
            </van-field>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Chat from 'vue-beautiful-chat'
    import axios from 'axios'
    import EasyTyper from 'easy-typer-js'
    import { v4 as uuidv4 } from 'uuid';
    import { Overlay } from 'vant';
    import { Notify } from 'vant';
    Vue.use(Notify);
    Vue.use(Overlay);
    Vue.use(Chat)
    Vue.prototype.$http = axios
    var that
    export default {
        name: "ChatGPT",
        data() {
            return {
                resultobj: {
                    output: '',
                    isEnd: false,
                    speed: 80,
                    singleBack: false,
                    sleep: 0,
                    type: 'normal',
                    backSpeed: 40,
                    sentencePause: false
                },
                saywords: '',
                loadshow: false,
                returntxt: ''
            }
        },
        mounted () {
            that = this
            document.title = '硕祺网络科技'
            that.initTyped('您好呀，认识您很高兴！')
            window.console.log(Get('sid'))
            if (Get('sid') !== null) {
                window.console.log(Get('sid'))
            } else {
                window.console.log('sid 空')
                var sid= uuidv4()
                Set('sid',sid,1000*30*30)
            }
        },
        methods: {
            copyText (text) {
                let inputDom = document.createElement('input');  // 创建一个input元素
                inputDom.setAttribute('readonly', 'readonly'); // 防止手机上弹出软键盘
                inputDom.value = text; // 给input元素赋值
                document.body.appendChild(inputDom); // 添加到body
                inputDom.select(); //选中input元素的内容
                document.execCommand('Copy'); // 执行浏览器复制命令
                inputDom.style.display = 'none';
                inputDom.remove(); // 移除input元素
                Notify({ type: 'success', message: '已复制完成' });

            },
            copyinfo () {
                that.copyText(that.returntxt)
            },
            initTyped(input, fn, hooks) {
                const obj = this.resultobj
                new EasyTyper(obj, input, fn, hooks)
            },
            sendmsg () {
                that.initTyped('请稍等，我需要思考一下')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/testgpt',
                    method: 'post',
                    data: {
                        uuid: Get('sid'),
                        saywords: that.saywords
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.choices.length > 0) {
                            that.loadshow = false
                            that.returntxt = res.data.choices[0].text
                            that.initTyped(that.returntxt)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            }
        }
    }
    // 存储
    function Set(key,value,sTime){
        let obj = {
            data: value,
            time: Date.now(),
            storageTime: sTime
        }
        localStorage.setItem(key, JSON.stringify(obj))
    }

    // 取值
    function Get(key){
        let obj = localStorage.getItem(key)
        window.console.log(obj)
        if (obj !== null && obj !== undefined && obj !== '') {
            obj = JSON.parse(obj)
            window.console.log(obj)
            window.console.log(Date.now()-obj.time)
            if(Date.now()-obj.time>obj.storageTime){
                localStorage.removeItem(key);
                return null
            }
        } else {
            return  null
        }
        return obj.data
    }
</script>

<style>
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        color: #fff;
    }
    .typed-cursor {
        margin-left: 10px;
        opacity: 1;
        -webkit-animation: blink 0.7s infinite;
        -moz-animation: blink 0.7s infinite;
        animation: blink 0.7s infinite;
    }
</style>


<style>

</style>
<template>
    <div>
        <div style="background-color: #fff;">
            <div style="min-height: 500px; font-size: 12px; background-color: #fff">
                <div style="padding: 20px;" v-if="loadshow">数据加载中,请稍等...</div>
                <div v-else>
                    <prism-editor
                            class="my-editor"
                            :style="{height: divHeight}"
                            v-model="resulttxt"
                            :highlight="highlighter"
                            line-numbers
                            :readonly="false"
                            :tabSize="4"
                    ></prism-editor>
                </div>
            </div>
            <div style="background-color:#f1f1f1; padding: 1px; width:100%;position:absolute; bottom:0;">
                <van-field
                        v-model="saywords"
                        @keyup.enter.native = "sendmsg"
                        placeholder="请在此输入需要代码的相关信息"
                >
                    <template #button>
                        <van-button size="small" @click="sendmsg" type="primary">发送</van-button>
                        <van-button style="margin-left: 5px;" size="small" @click="copycode" type="primary">复制代码</van-button>
                    </template>
                </van-field>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Chat from 'vue-beautiful-chat'
    import axios from 'axios'
    import { Overlay } from 'vant';
    import { PrismEditor } from "vue-prism-editor";
    import "vue-prism-editor/dist/prismeditor.min.css";
    import { highlight, languages } from "prismjs/components/prism-core";
    import "prismjs/components/prism-clike";
    import "prismjs/components/prism-javascript";
    import "prismjs/themes/prism-tomorrow.css";
    Vue.use(Overlay);
    Vue.use(Chat)
    Vue.prototype.$http = axios
    var that
    export default {
        name: "ChatGPTCode",
        components: {
            PrismEditor
        },
        data() {
            return {
                resulttxt: '',
                saywords: '',
                divHeight: '',
                loadshow: false
            }
        },
        mounted () {
            that = this
            document.title = '硕祺网络科技'
            that.divHeight = document.body.clientHeight+"px";
        },
        methods: {
            copycode () {
                try {
                    navigator.clipboard.writeText(that.resulttxt);
                    window.console.log('Content copied to clipboard');
                } catch (err) {
                    window.console.error('Failed to copy: ', err);
                }
            },
            highlighter(code) {
                return highlight(code, languages.js); //returns html
            },
            sendmsg () {
                that.loadshow = true
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/makegptcode',
                    method: 'post',
                    data: {
                        saywords: that.saywords
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res.status === 200)
                        if (res.data.choices.length > 0) {
                            that.loadshow = false
                            var tmpdata = res.data.choices[0]
                            that.resulttxt = tmpdata.text
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style>
    .my-editor {
        background: #fff;
        color: #c8c8c8;
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        padding: 5px;
    }

    .prism-editor__textarea:focus {
        outline: none;
    }

    /* 非必须 */
    .height-300 {
        min-height: 500px;
    }
</style>

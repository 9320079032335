<template>
    <div style="width:100%;margin-top: 10px; background-color: #fff;">
        <div style="display: flex; width:100%; flex-flow: column; " v-for="(item, index) in dfkdata" :key="index">
            <van-cell style="font-size:12px" :title="'订单号：' + item.order_no" />
            <van-card
                    style="flex:1;margin-left: 10px;margin-right: 10px;"
                    v-for="(subitem, index) in item.suborder" :key="index"
                    :num="subitem.count"
                    :price="subitem.price"
                    desc="描述信息"
                    :title="subitem.name"
                    :thumb="subitem.imgurl"
            >
                <template #tags>
                    <van-tag plain type="danger">{{subitem.specinfo}}</van-tag>
                </template>
            </van-card>
            <van-cell style="font-size:12px" :title="'合计：' + item.count_price + '元'" >
                <template #right-icon>
                    <van-button size="small" @touchstart="cancelorder(item.order_no)" @click="cancelorder(item.order_no)">取消订单</van-button>
                    <van-button size="small" style="margin-left: 5px;" @touchstart="continuepay(item.order_no,index)">继续付款</van-button>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
    var that
    export default {
        name: "DFK",
        data () {
            return {
                dfkdata: [],
                dfkcount: 0
            }
        },
        mounted() {
            that = this
            document.title = '待付款订单'
            that.$options.methods.getdfkorder()
        },
        methods: {
            cancelorder (orderno) {
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/delorderinfo',
                    method: 'post',
                    data: {
                        orderno: orderno
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            that.$options.methods.getdfkorder()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            continuepay (orderno,index) {
                window.console.log(this.dfkdata[index].suborder)
                var paydata = []
                for (var i = 0;i < this.dfkdata[index].suborder.length; i++) {
                    var tmpdata = this.dfkdata[index].suborder[i]
                    var paydatatmp = {
                        buynum: tmpdata.count.toString(),
                        goodsbarcode: tmpdata.barcode,
                        goodsbaseprice: tmpdata.price,
                        goodschecked: true,
                        goodsid: tmpdata.goods_id.toString(),
                        goodsimg: tmpdata.imgurl,
                        goodsname: tmpdata.name,
                        goodstype: tmpdata.specgroupid.toString(),
                        specinfo: tmpdata.specinfo
                    }
                    paydata.push(paydatatmp)
                }
                localStorage.setItem('neworder',0)
                localStorage.setItem('curorderno',orderno)
                localStorage.setItem('paydata',JSON.stringify(paydata))
                this.$router.push({path: '/CartJS'})
            },
            getdfkorder () {
                var qstr2 = {
                    whereinfo: " buy_cus_id='" + localStorage.getItem('openid') + "' and state_id = 1",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('订单信息')
                            that.dfkdata = tmpdata
                            that.dfkcount = tmpdata.length
                            window.console.log(that.dfkdata)
                            var orderarr = []
                            for (var i = 0; i < tmpdata.length; i++) {
                                var objtmp = tmpdata[i]
                                orderarr.push('"' + objtmp.order_no + '"')
                            }
                            var qstr3 = {
                                whereinfo: 'order_no in (' + orderarr + ')',
                                limit: '',
                                sortinfo: ''
                            }
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                method: 'post',
                                data: qstr3,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.length > 0) {
                                        var obj1 = res.data
                                        var dfktmp = that.dfkdata
                                        for (var j = 0; j < dfktmp.length; j++) {
                                            var dfkobj = dfktmp[j]
                                            var subordertmp = []
                                            for (var k = 0; k < obj1.length; k++) {
                                                if (dfkobj.order_no === obj1[k].order_no) {
                                                    window.console.log(obj1[k].order_no)
                                                    subordertmp.push(obj1[k])
                                                }
                                            }
                                            dfktmp[j].suborder = subordertmp
                                        }
                                        window.console.log(dfktmp)
                                        localStorage.setItem('dfkorder',JSON.stringify(dfktmp))
                                        that.dfkdata = JSON.parse(localStorage.getItem('dfkorder'))
                                    } else {
                                        window.console.log('获取商品失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                            // 把相关数据缓存到本地
                        } else {
                            that.dfkdata = []
                            localStorage.setItem('dfkorder',JSON.stringify('[]'))
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

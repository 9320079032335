<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                商品清单
            </div>
            <van-card v-for="(item, index) in pjorder" :key="index"
                      :num="item.buynum"
                      :price="item.goodsbaseprice"
                      desc="描述信息"
                      :title="item.goodsname"
                      :thumb="item.goodsimg"
            >
                <template #tags>
                    <van-tag plain type="danger">{{item.specinfo}}</van-tag>
                </template>
            </van-card>
            <van-form>
                <van-field name="rate" label="产品评分">
                    <template #input>
                        <van-rate v-model="cprate" />
                    </template>
                </van-field>
                <van-field name="rate" label="物流评分">
                    <template #input>
                        <van-rate v-model="wlrate" />
                    </template>
                </van-field>
                <van-field name="rate" label="服务评分">
                    <template #input>
                        <van-rate v-model="fwrate" />
                    </template>
                </van-field>
                <van-field name="uploader" label="上传">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                    </template>
                </van-field>
                <van-field
                        v-model="pjdes"
                        rows="3"
                        autosize
                        label="评价"
                        type="textarea"
                        show-word-limit
                        placeholder="请填写产品评价"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" @touchstart="tjpj"  native-type="submit">
                        提交评价
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Rate } from 'vant';
    import Utils from '../../util/utils'
    Vue.use(Rate);
    var that
    var OSS = require('ali-oss')
    export default {
        name: "GComment",
        data () {
            return {
                pjorder: [],
                selectpath: 'ecomm/des',
                cprate: 5,
                wlrate: 5,
                fwrate: 5,
                pjdes: '',
                postData: [],
                fileIds: [],
                hyimgs: '',
                fileList: [],
                percentageType: false,
                percentage: 0,
                fileurls: [],
                upfileList: []
            }
        },
        mounted() {
            that = this
            if (localStorage.getItem('pjorder')) {
                this.pjorder =  JSON.parse(localStorage.getItem('pjorder') || '[]')
            } else {
                this.pjorder = []
            }
        },
        methods: {
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5t9oHyNgxdDwTw4mnHLj',
                        accessKeySecret: 'lFA3kYzgnv7BiAmMVk7NNus7RBcnox',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let fileName = that.selectpath + '/' + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let fileName = that.selectpath + '/' + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            },
            delImg (index) {
                // 删除指定下标的图片对象
                if (isNaN(index) || index >= this.postData.length) {
                    return false
                }
                let tmp = []
                for (let i = 0, len = this.postData.length; i < len; i++) {
                    if (this.postData[i] !== this.postData[index]) {
                        tmp.push(this.postData[i])
                    }
                }
                this.postData = tmp
                this.fileIds.splice(index,1)
            },
            onRead (file) {
                this.postData.push(file)  // postData是一个数组
                // this.fileList.push(file)
                window.console.log(file.file)
                window.console.log(file.file.name)
                let formdata1 = new FormData();
                // 通过append向form对象添加数据,可以通过append继续添加数据
                //或formdata1.append('file',file);
                formdata1.append('file',file.file,file.file.name)
                formdata1.append('bucketname','sqpro')
                formdata1.append('bucketpath','comment/others')
                let config = {
                    headers: { //添加请求头
                        'Content-Type': 'multipart/form-data'
                    }
                }
                window.console.log(config)
                this.$http.post('http://api.sqnetsoft.cn/ossupload/uploadoss', formdata1,config).then(res => {
                    this.fileIds.push(res) //这里上传到指定的图片服务器，成功后会返回图片的url
                }).catch(err => {
                    window.console.log(err)
                })
            },
            tjpj () {
                window.console.log('下载文件列表',that.fileurls)
                let nowStr = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                var adddata = {
                    id: '',
                    contexttype: '1',
                    contextstate: '1',
                    orderno: localStorage.getItem('plorderno'),
                    contextid: localStorage.getItem('plgid').toString(),
                    contextlabel: this.pjdes,
                    context: this.pjdes,
                    userid: localStorage.getItem('cusid').toString(),
                    usernick: localStorage.getItem('nickname'),
                    userimg: localStorage.getItem('userimg'),
                    evtime: nowStr,
                    plcount: '0',
                    zancount: '0',
                    cprate: this.cprate.toString(),
                    wlrate: this.wlrate.toString(),
                    fwrate: this.fwrate.toString(),
                    cusid: localStorage.getItem('cusid').toString(),
                    ownphone: localStorage.getItem('username')
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/addcevaluate',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            window.console.log('下单完毕')
                            var adatas = []
                            for (var i = 0; i < that.fileurls.length; i++) {
                                var subtdata = that.fileurls[i]
                                var adata = {
                                    gid: localStorage.getItem('plgid').toString(),
                                    pid: res.data.data.toString(),
                                    ctype: '3',
                                    curl: subtdata,
                                    cusid: localStorage.getItem('cusid').toString(),
                                    ownphone: localStorage.getItem('username')
                                }
                                adatas.push(adata)
                            }
                            window.console.log(adatas)
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/paddgoss',
                                method: 'post',
                                data: adatas,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.result === 'success') {
                                        window.console.log('评论完毕')
                                        that.$router.push({path: '/My'})
                                    } else {
                                        window.console.log('地址添加失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/uporderstate',
                                method: 'post',
                                data: {
                                    order_no: localStorage.getItem('plorderno'),
                                    state_id: '5'
                                },
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.result === 'success') {
                                        window.console.log('状态变更完毕')
                                        that.$router.push({path: '/My'})
                                        // 把相关数据缓存到本地
                                    } else {
                                        window.console.log('获取商品失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                        } else {
                            window.console.log('地址添加失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

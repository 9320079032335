<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                音频录制
            </div>
            <van-cell-group>
                <div style="height: 48px; display: flex;align-items: center; padding-left:10px;">
                    <van-button  @click="srecvoice" size="small" type="primary" icon="photo-o">开始录制</van-button>
                    <van-button style="margin-left: 5px;"  @click="erecvoice" size="small" type="primary" icon="photo-o">暂停录制</van-button>
                </div>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block__title">
            已有音频列表
        </div>
        <div>
            <van-grid :border="false" :column-num="1">
                <van-grid-item text="本地音频目录(播放前请先选择录制文件)" />
            </van-grid>
            <div style="width:100%;background-color: #f2f3f5;height:1px;"></div>
        </div>
        <div style="font-size: 14px;">
            <van-cell :title="subitem" icon="shop-o" v-for="(subitem,index) in localIds" :key="index" @click="selectvoice(subitem)">
                <template #right-icon>
                    <van-icon  name="upgrade" style="line-height: inherit;" />
                </template>
            </van-cell>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                音频控制
            </div>
            <van-cell-group>
                <div style="height: 48px; display: flex;align-items: center; padding-left:10px;">
                    <van-button  @click="startvoice" size="small" type="primary" icon="photo-o">开始播放</van-button>
                    <van-button style="margin-left: 5px;"  @click="pausevoice" size="small" type="primary" icon="photo-o">暂停播放</van-button>
                    <van-button style="margin-left: 5px;"  @click="stopvoice" size="small" type="primary" icon="photo-o">停止播放</van-button>
                </div>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                智能语音识别
            </div>
            <van-cell-group>
                <div style="height: 48px; display: flex;align-items: center; padding-left:10px;">
                    <van-button  @click="transvoice" size="small" type="primary" icon="photo-o">识别语音</van-button>
                </div>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import wx from 'weixin-js-sdk'
    import { Image as VanImage } from 'vant';
    import { Notify } from 'vant';
    import { List } from 'vant';
    Vue.use(Notify);
    Vue.use(List);
    Vue.use(VanImage);
    var that
    export default {
        name: "VoiceRead",
        data () {
            return {
                localIds: [],
                sellocalId: ''
            }
        },
        mounted () {
            that = this
            window.console.log(that)
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/AIList'})
            },
            srecvoice () {
                Notify({ type: 'success', message: '已开始录制'});
                wx.startRecord({
                    cancel: function() {
                        Notify({ type: 'warning', message: '已取消'});
                    }
                });
            },
            erecvoice () {
                wx.stopRecord({
                    success: function(res) {
                        Notify({ type: 'success', message: '结束完毕'});
                        that.localIds.push(res.localId);
                    },
                    fail: function(res) {
                        window.console.log(res)
                    }
                });
            },
            selectvoice (id) {
              that.sellocalId = id
              Notify({ type: 'success', message: '已选择音频'});
            },
            startvoice () {
                wx.playVoice({
                    localId: that.sellocalId
                });
            },
            pausevoice () {
                wx.pauseVoice({
                    localId: that.sellocalId
                });
            },
            stopvoice () {
                wx.stopVoice({
                    localId: that.sellocalId
                });
            },
            transvoice () {
                wx.translateVoice({
                    localId: that.sellocalId,
                    complete: function(res) {
                        if (res.hasOwnProperty('translateResult')) {
                            Notify({ type: 'success', message: res.translateResult});
                        } else {
                            Notify({ type: 'warning', message: '识别失败'});
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                上传到微信缓存
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            center
                            clearable
                            label="上传"
                            placeholder="选择缓存文件（图片）"
                    >
                        <template #button>
                            <van-button @touchstart="imgselect" @click="imgselect" size="small" type="primary" icon="photo-o"></van-button>
                        </template>
                    </van-field>
                </van-form>
            </van-cell-group>
            <div class="van-doc-demo-block__title">
                已有缓存列表
            </div>
            <div>
                <van-grid :border="false" :column-num="1">
                    <van-grid-item text="本地缓存目录(点选本地缓存文件可查看)" />
                </van-grid>
                <div style="width:100%;background-color: #f2f3f5;height:1px;"></div>
            </div>
            <div style="font-size: 14px;">
                <van-cell :title="subitem" icon="shop-o" v-for="(subitem,index) in images.localId" :key="index" @click="downloadfile(subitem)">
                    <template #right-icon>
                        <van-icon  name="upgrade" style="line-height: inherit;" />
                    </template>
                </van-cell>
            </div>
            <div style="background-color: #fff; display: flex;justify-content: center;justify-items: center;">
                <van-image style="margin-top: 20px;" width="180" :src="imgurl" />
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import wx from 'weixin-js-sdk'
    import { Image as VanImage } from 'vant';
    import { Notify } from 'vant';
    import { List } from 'vant';
    Vue.use(Notify);
    Vue.use(List);
    Vue.use(VanImage);
    var that
    export default {
        name: "FileDownload",
        data () {
            return {
                imgurl: '',
                linkcontext: '',
                loading: true,
                images: {
                    localId: [],
                    serverId: []
                }
            }
        },
        mounted () {
            that = this
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/Example'})
            },
            imgselect () {
                wx.chooseImage({
                    count: 9, // 最多可以选择的图片张数，默认9
                    sizeType: ['original', 'compressed'], // original 原图，compressed 压缩图，默认二者都有
                    sourceType: ['album', 'camera'], // album 从相册选图，camera 使用相机，默认二者都有
                    success: function (res) {
                        that.images.localId = res.localIds;
                        window.console.log(that.images)
                        window.console.log('已选择 ' + res.localIds.length + ' 张图片');
                    },
                    fail: function(e) {
                        window.console.log(e)
                        Notify({ type: 'warning', message: '' + e.errMsg});
                    },
                    complete: function() {
                        // complete
                    }
                })
            },
            wxuploadImage(e) {
                wx.uploadImage({
                    localId: e, // 需要上传的图片的本地ID，由chooseImage接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: function (res) {
                        that.images.serverId.push(res.serverId);
                    },
                    fail: function (error) {
                        Notify({ type: 'warning', message: '' + error.errMsg});
                    }
                })
            },
            defaultval () {
                that.linkcontext = 'https://sqpro.oss-cn-beijing.aliyuncs.com/demo/file/hlogo.png'
            },
            downloadfile (e) {
                wx.getLocalImgData({
                    localId: e, // 图片的localID
                    success: function (res) {
                        var localData = res.localData;
                        if (localData.indexOf('data:image') != 0) {
                            //判断是否有这样的头部
                            localData = 'data:image/jpeg;base64,' +  localData
                        }
                        localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg')
                        that.imgurl = localData
                    },fail:function(res){
                        Notify({ type: 'warning', message: '' + res.errMsg});
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
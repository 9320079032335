<template>
    <div class="full">
        <van-nav-bar
                left-text="返回"
                left-arrow
                @click-left="returnyw"
        />
        <div class="van-doc-demo-block" style="padding-bottom: 5px; background-color: #fff;">
            <van-cell-group>
                <van-field name="uploader" label="选取"  placeholder="拍照或上传图片">
                    <template #input>
                        <van-uploader v-model="fileList" accept="image/*"  :after-read="fnUploadRequest"/>
                    </template>
                </van-field>
                <van-progress v-if="percentageType == true" :percentage="percentage" color="#3fb776" style="margin-left: 90px; margin-right:20px; margin-top: 5px; margin-bottom: 5px;"/>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block__title" >
            识别结果
        </div>
        <div style="font-size: 14px; background-color: #fff;padding: 20px;">
            {{serverInfo}}
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Progress } from 'vant'
    import { Notify } from 'vant';
    import Utils from '../../util/utils'
    var OSS = require('ali-oss')
    Vue.use(Progress);
    Vue.use(Notify);
    var that
    export default {
        name: "YYZZ",
        data () {
            return {
                fileList: [],
                upfileList: [],
                selectpath: 'demo/scan',
                percentage: 0,
                ifupload: false,
                serverInfo: '',
                percentageType: false
            }
        },
        mounted () {
            that = this
        },
        methods: {
            returnyw() {
                this.$router.push({path: '/AIList'})
            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAIaf73dyK72dHv',
                        accessKeySecret: 'GRv2JMOvMC02F402r2ZHBElebVUB9v',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                let file = option.file
                that.fileList[0].status = 'uploading'
                that.fileList[0].message = '上传中'
                this.percentageType = true;
                that.serverInfo = '请稍等...'
                return new Promise((resolve, reject) => {
                    let dateTime = Utils.dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
                    let randomStr = that.randomString(4)//  4位随机字符串
                    that.curfilename = file.name
                    let extensionName = file.name.substr(file.name.indexOf('.')) // 文件扩展名
                    that.curfiletype = extensionName
                    that.finfilename = dateTime + randomStr + extensionName.replace('jpeg','jpg')
                    let fileName = that.selectpath + '/' + that.finfilename // 文件名字（相对于根目录的路径 + 文件名）
                    // 执行上传
                    that.createOssClient().then(client => {
                        // 异步上传,返回数据
                        resolve({
                            fileName: file.name,
                            fileUrl: fileName
                        })
                        that.ifupload = true
                        // 上传处理
                        // 分片上传文件
                        client.multipartUpload(fileName, file, {
                            progress: function (p) {
                                let e = {}
                                e.percent = Math.floor(p * 100)
                                // console.log('Progress: ' + p)
                                window.console.log(that.fileList)
                                that.percentage = e.percent
                                window.console.log(e.percent)
                            }
                        }).then((val) => {
                            if (val.res.statusCode === 200) {
                                that.upfileList.push(val)
                                window.console.log(that.upfileList)
                                window.console.log(val)
                                that.fileList[0].status = 'done'
                                that.fileList[0].message = '上传完成'
                                that.serverInfo = '识别中...'
                                var urltmp = 'https://sqpro.oss-cn-beijing.aliyuncs.com/' + val.name
                                window.console.log(urltmp)
                                that.wxuploadImage(urltmp)
                                return val
                            } else {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                Notify({ type: 'failed', message: '上传失败'});
                            }
                        }, err => {
                            that.fileList[0].status = 'failed'
                            that.fileList[0].message = '上传失败'
                            Notify({ type: 'failed', message: '上传失败'});
                            window.console.log(err)
                            window.console.log(reject)
                        })
                    }).catch(errinfo => {
                        window.console.log(errinfo)
                    })
                })
            },
            randomString (num) {
                let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
                let res = ''
                for (let i = 0; i < num; i++) {
                    var id = Math.ceil(Math.random() * 35)
                    res += chars[id]
                }
                return res
            },
            wxuploadImage (url) {
                var tdata = {
                    accesstoken: getCookie('accesstoken'),
                    imgurl: encodeURI(url)
                }
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/scanyyzz',
                    method: 'post',
                    data: tdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            window.console.log(res)
                            that.serverInfo = tmpdata
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
</script>

<style scoped>

</style>
<template>
    <div style="background-color: #fff;">
        <div>
            <div>
                <van-field
                        v-model="saywords"
                        @keyup.enter.native = "sendmsg"
                        placeholder="请在此输入图片描述"
                >
                    <template #button>
                        <van-button size="small" @click="sendmsg" type="primary">生成图片</van-button>
                    </template>
                </van-field>
                <van-field name="radio" label="图片尺寸">
                    <template #input>
                        <van-radio-group v-model="sizeinfo">
                            <van-radio name="256x256">256x256</van-radio>
                            <van-radio style="margin-top: 8px;" name="512x512">512x512</van-radio>
                            <van-radio style="margin-top: 8px;" name="1024x1024">1024x1024</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
            </div>
            <div style="padding: 5px 0 0px 20px;font-size: 12px;" v-if="loadshow">数据加载中,请稍等...</div>
            <div style="margin-top: 30px; display: flex; width: 100%; align-items: center; justify-content: center;">
                <img style="width:256px;height:256px;" :src="defaultimg" />
            </div>
            <div style="width: 100%; margin-top: 20px; display: flex;  align-items: center; justify-content: center;">
                <div style="width:300px; height: 50px; font-size: 12px; white-space: normal; word-break: break-all; overflow: auto;">
                    {{defaultimg}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Chat from 'vue-beautiful-chat'
    import axios from 'axios'
    import { Overlay } from 'vant';
    import { RadioGroup, Radio } from 'vant';

    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(Overlay);
    Vue.use(Chat)
    Vue.prototype.$http = axios
    var that
    export default {
        name: "ChatGPTImg",
        data() {
            return {
                resulttxt: '',
                saywords: '',
                sizeinfo: '256x256',
                defaultimg: 'https://img2.baidu.com/it/u=2829299963,2110257490&fm=253&fmt=auto&app=138&f=JPEG?w=256&h=256',
                loadshow: false
            }
        },
        mounted () {
            that = this
            document.title = '硕祺网络科技'
        },
        methods: {
            sendmsg () {
                that.loadshow = true
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/makegptimg',
                    method: 'post',
                    data: {
                        saywords: that.saywords,
                        size: that.sizeinfo
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.loadshow = false
                        that.defaultimg = res.data.data[0].url
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

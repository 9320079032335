<template>
    <div class="full">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                源内容
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            v-model="sourcetxt"
                            rows="2"
                            autosize
                            label="源内容"
                            type="textarea"
                            maxlength="500"
                            @blur="trans_txt"
                            placeholder="请输入需要翻译的内容"
                            show-word-limit
                    />
                </van-form>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block" style="margin-top: 10px;">
            <van-cell-group>
                <div style="display: flex;align-items: center; padding:12px;">
                    <van-radio-group v-model="transmodel" direction="horizontal">
                        <van-radio name="1">中->英</van-radio>
                        <van-radio name="2">英->中</van-radio>
                    </van-radio-group>
                </div>
            </van-cell-group>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                目标内容
            </div>
            <van-cell-group>
                <van-form>
                    <van-field
                            v-model="contexttxt"
                            rows="2"
                            autosize
                            label="目标内容"
                            type="textarea"
                            maxlength="500"
                            placeholder="显示翻译后的内容"
                            show-word-limit
                    />
                </van-form>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Notify } from 'vant';
    import { RadioGroup, Radio } from 'vant';
    Vue.use(Notify);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "TransTxt",
        data () {
            return {
                sourcetxt: '',
                contexttxt: '',
                transmodel: '1'
            }
        },
        mounted () {
            that = this
            that.transmodel = '1'
            window.console.log(that)
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/AIList'})
            },
            trans_txt () {
                var ifrom,ito
                if (that.transmodel === '1') {
                    ifrom = 'zh_CN'
                    ito = 'en_US'
                } else if (that.transmodel === '2') {
                    ifrom = 'en_US'
                    ito = 'zh_CN'
                }
                var tdata = {
                    accesstoken: localStorage.getItem('accesstoken'),
                    ifrom: ifrom,
                    ito: ito,
                    bodystr: that.sourcetxt
                }

                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/transtxt',
                    method: 'post',
                    data: tdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            var tmpdata = JSON.parse(res.data.data)
                            window.console.log(tmpdata)
                            that.contexttxt = tmpdata.to_content
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="full">
            <div class="van-doc-demo-block">
                <div class="van-doc-demo-block__title">
                    区块链
                </div>
                <van-cell-group>
                    <van-cell is-link @click="getccxt" icon="volume-o">CCXT</van-cell>
                </van-cell-group>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BCList",
        data () {
            return {
                imgurl: '',
                bartitle: '演示二维码'
            }},
        mounted () {
            document.title = '区块链'
        },
        methods: {
            getccxt () {
                var ccxt = require ('ccxt')
                window.console.log (ccxt.exchanges) // print all available exchanges
            },
            returnyw() {
                this.$router.push({path: '/'})
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div style="margin-bottom: 60px;">
        <van-nav-bar
                title="所有产品"
                left-text="返回"
                left-arrow
                @click-left="returnyw">
        </van-nav-bar>
        <van-grid column-num="2">
            <van-grid-item
                    v-for="(curgoods) in allgoodsdata"
                    :key="curgoods.id"
                    :text="curgoods.name"
                    @touchstart="showgoods(curgoods.id)" @click="showgoods(curgoods.id)"
            >
                <van-image style="margin:10px; width: 128px;height: 128px;" :src="curgoods.img_url" />
                <div style="font-size:14px; margin:10px;">{{curgoods.name}}</div>
                <div style="font-size:14px; margin:10px;">{{curgoods.baseprice}}</div>
            </van-grid-item>
        </van-grid>
    </div>
</template>

<script>
    var that
    export default {
        name: "AllGoods",
        data () {
            return {
                allgoodsdata: []
            }
        },
        mounted() {
            document.title = "祺聚优品"
            that = this
            that.$options.methods.showallgoods()
        },
        methods: {
            returnyw () {
                this.$router.push({path: '/'})
            },
            showallgoods() {
                var qstrinfo = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "'",
                    limit: '',
                    sortinfo: ' order by id desc'
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryginfo',
                    method: 'post',
                    data: qstrinfo,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.allgoodsdata = res.data
                            window.console.log(that.allgoodsdata)
                        } else {
                            that.allgoodsdata = []
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            showgoods: function(goodsid) {
                localStorage.setItem('curgoodsid', goodsid)
                this.$router.push('/GoodsInfo')
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <van-nav-bar
                left-text="返回"
                left-arrow
                title="次卡管理"
                @click-left="returnyw">
                <template #right>
                    <van-icon name="setting-o" @click="ckmana" size="18" />
                </template>
        </van-nav-bar>
        <van-grid :column-num="2">
            <van-grid-item>
                <van-icon size="1.8rem" name="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%85%A8%E9%83%A8.png" />
                <div style="font-size: 12px; color:#8a8a8a;  display: flex; flex-flow: column; align-items: center; justify-content: center;">
                    <div style="margin-top: 5px;">总次数</div>
                    <div style="margin-top: 5px;">{{tcardtimes}}</div>
                </div>
            </van-grid-item>
            <van-grid-item>
                <van-icon size="1.8rem" name="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%89%A9%E4%BD%99%E6%8C%87%E6%A0%87.png" />
                <div style="font-size: 12px; color:#8a8a8a;  display: flex; flex-flow: column; align-items: center; justify-content: center;">
                    <div style="margin-top: 5px;">剩余次数</div>
                    <div style="margin-top: 5px;">{{sytimes}}</div>
                </div>
            </van-grid-item>
        </van-grid>
        <div class="van-doc-demo-block__title" style="background-color: #f7f8fa;">
            配送记录
        </div>
        <van-cell-group>
            <van-cell :title="item.lname.String" :value="'单号:' + item.wlno.String" v-for="(item, index) in psdata" :key="index">
                <template #label>
                    <div>配送时间：{{item.optime}}</div>
                    <div>联系人：{{item.wlman.String}}</div>
                    <div>联系电话：{{item.wlcontact.String}}</div>
                    <div>状态：已配送</div>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
    var that
    export default {
        name: "TCardMana",
        data () {
            return {
                tcardtimes: '',
                sytimes: '',
                cardno: '',
                psdata: []
            }
        },
        mounted () {
            window.console.log('TCARD')
            that = this
            var obj = JSON.parse(localStorage.getItem('jhtcard'))
            window.console.log(obj)
            this.tcardtimes = obj[0].pstimes
            this.cardno = obj[0].cardno
            that.$options.methods.getpsrec()
        },
        methods: {
            getpsrec () {
                var qstr = "cardno = '" + that.cardno + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/order/getbtcps',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                window.console.log(tmpdata)
                                that.psdata = tmpdata
                                that.sytimes = parseInt(that.tcardtimes) - tmpdata.length
                            } else {
                                that.sytimes = that.tcardtimes
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            returnyw() {
                this.$router.push({path: '/My'})
            },
            ckmana () {
                this.$router.push({path: '/TCardInfo',query:{ way:'1'}})
            }
        }
    }
</script>

<style scoped>

</style>